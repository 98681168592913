/* eslint-disable */
import React from 'react'
import {Card} from "react-bootstrap";
import socket from "../../../Socket";
import {__, decode} from "../../../Helper";
import C from "../../../Constant";

let gameCanvas = null;

class Canvas extends React.Component {
    _isMounted = false;
    constructor (props){
        super(props);
        this.state = {
            engine: this.props.engine,
            connecting: true,
            hash: null
        };
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount(){
        let { engine } = this.state;
        this._isMounted = true;
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
        engine.on(C.KENO_AMOUNT, (data) => this.amount(data));
        engine.on(C.STATUS_KENO, (data) => this.status(data));
        engine.on(C.WAITING_KENO, (data) => this.waiting(data));
        engine.on(C.STARTED_KENO, (data) => this.start(data));
        engine.on(C.BUSTED_KENO, (data) => this.busted(data));
        //Start Canvas
        gameCanvas = new GameCanvas;
        gameCanvas.init();
    }

    componentWillUnmount(){
        this._isMounted = false;
        this.setState({ connecting: true });
        window.removeEventListener('resize', this.handleResize);
    }

    amount(data){
        if(this._isMounted){
            let amount = data.amount;
            let round = data.round;
            if(gameCanvas !== null){
                gameCanvas.setAmount(amount, round);
            }
        }
    }

    handleResize(){
        this.setState({ width: window.innerWidth });
    }

    waiting(data){
        if(this._isMounted){
            if(gameCanvas !== null){
                gameCanvas.setWaiting(data.time);
            }
            this.setState({ status: 'waiting' });
        }
    }

    start(data){
        if(this._isMounted){
            this.setState({ status: 'started' });
            if(gameCanvas !== null){
                gameCanvas.setPlaying(data.time, data.md5);
            }
        }
    }

    busted(data){
        if(this._isMounted){
            var rate = (data.amount / 100).toFixed(2);
            if(gameCanvas !== null){
                gameCanvas.setBusted(rate, data.time);
            }
            this.setState({ status: 'busted', timer: data.time, rate: rate });
        }
    }

    status(data){
        if(this._isMounted){
            this.setState({ status: data.status, timer: data.time, connecting: false });
            if(data.status === 'started_keno' && gameCanvas !== null){
                gameCanvas.setPlaying(data.time, data.md5);
            }
            else if(data.status === 'waiting_keno' && gameCanvas !== null){
                gameCanvas.setWaiting(data.time);
            }
            else if(data.status === 'busted_keno' && gameCanvas !== null){
                var rate = (120 / 100).toFixed(2);
                gameCanvas.setBusted(rate, data.time);
            }
        }
    }

    render () {
        let check = {
            fontSize: 15,
            display: "unset"
        };

        if(this.state.connecting){
            check = {
                display: "none"
            };
        }

        return(
            <Card className={"no-shadow mb-0 bg-dice-options"}>
                <Card.Body className="bg-body text-center p-1 no-shadow">
                    <div className="game-holder h-100 text-upper">
                        { this.state.connecting &&
                        <>
                            <b className={"text-white mt-2 d-block py-1"}>
                                CONNECTING
                                <div className="text-center">
                                    <div className="spinner-grow text-white" role="status"/>
                                </div>
                            </b>
                        </>
                        }
                        <div id="graph" style={ check } />
                        <div id="progress" />
                    </div>
                </Card.Body>
            </Card>
        );
    }
}

function GameCanvas(){
    let self = this;

    self.nextround_text = 'Next round in {time}s';
    self.game_status = null;
    self.start_time = 0;

    self.setAmount = function(amount, round) {
        self['x' + round] = amount;
    };

    self.setWaiting = function(time) {
        self.game_status  = 1;
        self.start_time   = (new Date)['getTime']() + time;
    };

    self.setPlaying = function() {
        self.game_status  = 2;
    };

    self.setBusted = function() {
        self.game_status  = 3;
    };

    self.init = function() {
        self.div = document.getElementById('graph');
        self.div.innerText = 'Please Wait...';
        self.progress = document.getElementById('progress');
        self.rtimer = true;
        window.requestAnimationFrame(gameCanvas.render)
    };

    self.render = function() {
        if (!gameCanvas.rtimer) {
            return false;
        }
        gameCanvas.draw();
        window.requestAnimationFrame(gameCanvas.render);
    };

    self.draw = function() {
        if(self.game_status === 1)
            self.drawWaiting();
        else if (self.game_status === 3)
            self.drawBusted();
    };

    self.drawWaiting = function() {
        let time = (self.start_time - (new Date)['getTime']()) / 1e3;
        let ttm = time <= 0 ? '0.0' : time.toFixed(1);
        self.div.innerText = self.nextround_text.replace('{time}', ttm);
        let timer = (time * 10 * 2 - 12).toFixed(2);
        self.progress.innerHTML = "<div class='progress mt-1' style='height:3px;'><div class=\"progress-bar bg-purple\" " +
            "role=\"progressbar\" style=\"width: "+timer+"%;\" aria-valuenow=\""+timer+"\" aria-valuemin=\"0\" aria-valuemax=\"100\"></div></div>";
    };

    self.drawBusted = function() {
        self.div.innerHTML = "BUSTED";
    };
}

export default Canvas;