import React, { useEffect, useState, useRef } from 'react';
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import {Row, Col} from "react-bootstrap";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';

import useStyles from "../../../../Style";
import storage from "../../../../Storage";
import {sendNotfication, wait} from "../../../../Helper";
import General from "./General";
import Security from "./Security";
import Sessions from "./Sessions";
import Avatar from "./Avatar";

class Setting extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      isLogged: storage.getKey('logged'),
      tab: 0
    }
  }

  componentDidMount(){
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    if(this.state.isLogged !== 'true')
      this.props.history.push('../')
  }

  change = (tab) => {
    this.setState({ tab })
  }

  render(){
    const classes = useStyles({
      backgroundColor: '#121725',
      position: 'relative',
      borderRadius: 10
    });
    return  <>
              <ReactCSSTransitionGroup
                  transitionAppear={true}
                  transitionAppearTimeout={250}
                  transitionEnterTimeout={200}
                  transitionLeaveTimeout={200}
                  transitionName={'SlideIn'}
              >
                    <div className={"container mt-2 p-3 " + classes }>
                        <Row>
                            <Col sm={5} md={3}>
                              <div className="setting-tab">
                                <List component="nav" aria-label="main setting">
                                  <ListItem className={ this.state.tab === 0 ? 'active' : ''} button onClick={ e => this.change(0)}>
                                    <ListItemIcon>
                                      <i className="mdi mdi-shield-home" />
                                    </ListItemIcon>
                                    <ListItemText primary="General" />
                                  </ListItem>
                                   <ListItem className={ this.state.tab === 3 ? 'active' : ''} button onClick={ e => this.change(3)}>
                                    <ListItemIcon>
                                      <i className="mdi mdi-emoticon-excited" />
                                    </ListItemIcon>
                                    <ListItemText primary="Avatar" />
                                  </ListItem>
                                  <ListItem className={ this.state.tab === 1 ? 'active' : ''} button onClick={ e => this.change(1)}>
                                    <ListItemIcon>
                                      <i className="mdi mdi-security" />
                                    </ListItemIcon>
                                    <ListItemText primary="Security" />
                                  </ListItem>
                                  <ListItem className={ this.state.tab === 2 ? 'active' : ''} button onClick={ e => this.change(2)}>
                                    <ListItemIcon>
                                      <i className="mdi mdi-console-network" />
                                    </ListItemIcon>
                                    <ListItemText primary="Sessions" />
                                  </ListItem>
                                </List>
                              </div>
                            </Col>
                            <Col sm={7} md={9}>
                              <div className="mt-4">
                                {
                                  this.state.tab === 0 &&
                                  <General />
                                }
                                {
                                  this.state.tab === 1 &&
                                  <Security/>
                                }
                                {
                                  this.state.tab === 2 &&
                                  <Sessions/>
                                }
                                  
                                {this.state.tab === 3 &&
                                  <Avatar/>
                                }

                              </div>
                            </Col>
                        </Row>
                    </div>
                </ReactCSSTransitionGroup>
            </>
  }
}

export default Setting;