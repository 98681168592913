import React from "react";
import {Card, Col, Dropdown, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import games from "../../../games";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {__, isMobile, wait, Event, addNewStyle} from "../../../../Helper";

class MobileMenu extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            show: false
        }
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        wait(500).then(() => {
            this.handleResize();
        });
        window.addEventListener('resize', this.handleResize);
    }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize(){
        if(isMobile()){
            this.setState({ show: true });
        }
        else {
            this.setState({ show: false });
        }
    }

    render() {
        return (
            <>
                { this.state.show &&
                    <ul className="list-unstyled topbar-nav mb-0 menu-items">
                        <MobileDrawer t={this.props.t}/>
                    </ul>
                }
            </>
        );
    }
}

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto'
    },
    drawer: {
        color: '#FFF'
    },
    logo: {
        margin: 0,
        padding: '10px 12px 0',
        background: '#1c1f2d',
        borderBottom: '1px solid #1c1f2d'
    },
    logoImage: {
        verticalAlign: "top"
    },
    links: {
        marginTop: 5,
        padding: '10px 12px',
        display: 'block'
    },
    icon: {
        width: 30
    }
});

function MobileDrawer(props) {
    const classes = useStyles();
    const [state, setState] = React.useState({
        left: false
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const fixer = game => {
        if(game === 'heads-or-tails') return 'heads';
        if(game === 'hash-dice') return 'hash dice';
        if(game === 'classic-dice') return 'classic dice';
        if(game === 'rps') return 'plinko';
        return __.replace(game, '_', ' ')
    };

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.drawer]: 'left',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                {games.map((item, index) => (
                    !item.disabled && <Link key={__.toString(index)} to={'/' + item.prefix}>
                        <ListItem button key={item}>
                            <ListItemIcon>
                                    <img className={clsx(classes.icon) + ' mr-1'} src={'/assets/images/icons/' + fixer(item.prefix) + '.png'} alt={item.prefix} />
                            </ListItemIcon>
                            <ListItemText primary={item.name} />
                        </ListItem>
                    </Link>
                ))}
            </List>
        </div>
    );

    const anchor = 'left';
    const { t } = props;

    let loaded = false;

    const support = () => {
        var d, s;
        window.$crisp=[];
        window.CRISP_WEBSITE_ID="b926e446-5094-4424-88e2-4f91e419546d";

        if(!loaded){
            loaded = true;
            (function(){
                d = document;
                s = d.createElement("script");
                s.src="https://client.crisp.chat/l.js";
                s.id= "chtt";
                s.async=1;
                d.getElementsByTagName("head")[0].appendChild(s);
            })();
        }

        addNewStyle('#crisp-chatbox {display:block !important;}');
        addNewStyle('.crisp-client .cc-kv6t .cc-1xry .cc-ge4v .cc-qqzz .cc-5b24 .cc-19cg {display:none !important;}');

        wait(2000).then(() => {
            var close = document.querySelector('.cc-wdhl');
            if(close === null) return
            close.addEventListener('click',function(event){
                addNewStyle('#crisp-chatbox {display:none !important;}');
                document.getElementById('chtt').src = "";
            });
        })
    }
    
    return (
        <>
            <React.Fragment key={anchor}>
                <li className={'nav-link ml-0'} onClick={toggleDrawer(anchor, true)}>
                    <i className={'mdi mdi-menu text-white font-20'} />
                </li>
                <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                    <ul className={clsx(classes.logo)}>
                        <li>
                            <Link to="/" className="logo">
                                <span>
                                    <img src="/assets/images/bg.png" alt="logo-small" className={classes.logoImage} style={{width: 150}} />
                                </span>
                            </Link>
                        </li>
                        <li className="d-inline-block">
                            <Link to="/slots" className={clsx(classes.links)} onClick={toggleDrawer(anchor, false)}>
                                <span>
                                  {t('slots')}
                                </span>
                            </Link>
                        </li>
                        <li className="d-inline-block">
                            <Link to="/affiliate" className={clsx(classes.links)} onClick={toggleDrawer(anchor, false)}>
                                <span>
                                  {t('affiliate')}
                                </span>
                            </Link>
                        </li>
                        <li className="d-inline-block">
                            <Link to="/bonus" className={clsx(classes.links)} onClick={toggleDrawer(anchor, false)}>
                                <span>
                                  {t('bonus')}
                                </span>
                            </Link>
                        </li>
                        <li className="d-inline-block">
                            <Link onClick={ () => support() } to="#" className={clsx(classes.links)} onClick={toggleDrawer(anchor, false)}>
                                <span>
                                   {t('support')}
                                </span>
                            </Link>
                        </li>
                    </ul>
                    {list(anchor)}
                </Drawer>
            </React.Fragment>
        </>
    );
}

export default MobileMenu;