import React, {Component, createRef} from "react";
import {Modal} from "react-bootstrap";
import PerfectScrollbar from 'perfect-scrollbar';
import socket from "../../../../Socket";
import storage from "../../../../Storage";
import UserModal from "../Stat/Modal";
import {__, forceSatoshiFormat, decode, wait, Event, isMobile} from "../../../../Helper";
import C from "../../../../Constant";
import Game from "./Game";

class History extends Component {
    constructor(props){
        super(props);
        this.state = {
            show: false,
            paddingHeader: ''
        }
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {
        if(isMobile()){
            this.setState({ padding: 'p-1', paddingHeader: 'px-2' })
        }
        Event.on('single_game_modal_close', this.handleClose)
    }


    handleClose(){
        this.setState({ show: false })
    }

    render() {
        return (
            <>
            <button onClick={ e => this.setState({ show: !this.state.show }) } className={"dropdown-item mb-1 font-14"}>
                    <i className="mdi mdi-reload text-muted mr-2 text-drop"/>
                    History
            </button>
                <Modal
                    size={'lg'}
                    backdrop={'static'}
                    centered={true}
                    scrollable={false}
                    show={this.state.show}
                    onHide={this.handleClose}
                    aria-labelledby="wallet-md-modal"
                >
                    <Modal.Header className={this.state.paddingHeader}>
                      History
                        <button type="button" className="close p-2" onClick={this.handleClose}>
                            <i className={'mdi mdi-close'}/>
                        </button>
                    </Modal.Header>
                    <Modal.Body className={ this.state.padding + ' history-modal'}>
                       <Child />
                    </Modal.Body>
                </Modal>
                </>
        );
    }
}

class Child extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            showDetails: false,
            next: false,
            history: []
        };
    }

    componentDidMount(){
        this._isMounted = true;
        wait(1000).then(() => {
            const ps = new PerfectScrollbar('#list', {
                wheelSpeed: 1,
                suppressScrollX: true,
                wheelPropagation: true,
                minScrollbarLength: 2
            });
            ps.update();
        })
        wait(500).then(() => {
            socket.emit(C.MY_HISTORY);
        })
        socket.on(C.MY_HISTORY, data => this.makeHistory(decode(data)));
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    makeHistory = (arr) => {
        if(this._isMounted)
        {
            if(typeof arr.history === "undefined") return;
            this.setState({ loading: false });
            
            var result = arr.history;

            result.forEach((player, i) => {
                let row = <HistoryTable clicked={ e => this.clicked(player) } key={__.toString(i)} player={player} self={true} />;
                this.setState(state => ({ history: [row, ...state.history] }));
            });
        }
    };

    clicked = (player) => {
        if(this._isMounted)
        {
            this.setState({ showDetails: true, gameDetails: player });
        }
    }

    nextPage = () => {
        if(this._isMounted)
        {
            this.setState({ next: true });
        }
    };

    back = () => {
        if(this._isMounted)
        {
            this.setState({ showDetails: false });
        }
    };

    render() {
        let { loading, history, next } = this.state;

        let counts = history.length;
        let reduce = counts / 2;

        if(!next)
            history = history.slice(reduce);

        if(history.length > 1)
            history.length = 9

        return (
            <>
                <>
                    { loading ?
                        <div className="text-center my-3">
                            <div className="spinner-grow text-white my-3" role="status" />
                        </div>
                    :
                    <>
                        {
                            this.state.showDetails ? 
                            <>
                                <Game t={this.props.t} game={this.state.gameDetails} />
                                <div className="text-center">
                                    <button onClick={this.back} className={'my-2 btn bg-cs btn-xs'}>
                                        <i className={'mdi mdi-arrow-right-bold-circle'} /> Back
                                    </button>
                                </div>
                            </>
                            :
                            <>
                            <div className="user-history" style={{ height: 500 }}>
                                <div className="table-responsive mt-2" id={'list'} >
                                    <table className="table table-hover font-13">
                                        <thead>
                                        <tr>
                                            <th scope="col">Game</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Profit</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            {history.length > 0 &&
                                                history
                                            }
                                        </tbody>
                                    </table>

                                    {history.length === 0 &&
                                        <div className="alert bg-soft-dark mt-2 w-100 font-13 text-center text-upper">
                                            [ You Have Not Played Yet ]
                                        </div>
                                    }
                                    { history.length > 0 &&
                                        <div className={'text-center'}>
                                            <button onClick={this.nextPage} className={'mb-2 btn bg-cs btn-xs'}>
                                                <i className={'mdi mdi-arrow-right-bold-circle'} /> Next Page
                                            </button>
                                        </div>
                                    }
                                </div>
                            </div>
                            </>
                        }
                    </>
                    }
                </>
            </>
        );
    }
}

class HistoryTable extends React.Component{
    constructor(props){
        super(props);
        this.myRef = createRef();
    }
    
    render() {
        let {username, amount, profit, coin, self, game} = this.props.player;
        let isWinner = false;

        amount = forceSatoshiFormat(amount, coin)
        profit = forceSatoshiFormat(profit, coin)

        if(__.toNumber(profit) !== 0.00000000)
            isWinner = true;

        return(
            <tr className="cpt">
                <td onClick={this.props.clicked}>
                    {__.replace(__.upperFirst(game), '_', ' ')}
                </td>
                <td onClick={this.props.clicked} className={(isWinner===true) ? 'text-success-2 num-style': 'num-style'}>
                    <img src={'/assets/images/' + __.upperCase(coin) + '.png'} className={'mini-coin-2 hidden-sm'} alt={coin}/>
                    {amount} {__.upperCase(coin)}
                </td>
                <td onClick={this.props.clicked} className={(isWinner===true) ? 'text-success-2 num-style': 'num-style'}>
                    <img src={'/assets/images/' + __.upperCase(coin) + '.png'} className={'mini-coin-2 hidden-sm'} alt={coin}/>
                    {profit} {__.upperCase(coin)}
                </td>
            </tr>
        );
    }
}

export default History;