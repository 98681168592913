import React, { Component } from 'react'
import ReactTooltip from "react-tooltip";
import Sounds from "./Sounds"
import Seed from "./Seed";
import Help from "./Help";

class TopBar extends Component{
    render() {
        return(
            <>
                <ReactTooltip />
                <div className="btn-group btn-group-toggle">
                    <Sounds/>
                    <Seed/>
                    <Help content={this.props.help} image={this.props.image} />
                </div>
            </>
        );
    }
}

export default TopBar;