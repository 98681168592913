import React from 'react';
import {Helmet} from "react-helmet";
import {Row, Col, Container, Card} from "react-bootstrap";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import Bet from "./Bet";
import Queue from "../../Components/Game/Queue";
import {wait, isMobile, Event} from "../../../Helper";
import BankRoll from "../../Components/Game/BankRoll";
import TopBarGames from "../../Components/Game/Addons/TopBar";
import Game from "./Game";
import Engine from "./Engine";

class Index extends React.Component {
    _Mounted = false;
    constructor (props){
        super(props);
        this.state = {
            engine: new Engine(),
            margin: 'mt-3',
            padding: 'p-1'
        };
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        this._Mounted = true;
        let { engine } = this.state;
        
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        // Start Engine
        engine.started = true;

        wait(600).then(() => {
            this.handleResize();
        });
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        let { engine } = this.state;
        window.removeEventListener('resize', this.handleResize);
        this._Mounted = false;

        // Stop Engine
        engine.started = false;
        engine.trigger = null;
        engine.off()
    }

    handleResize(){
        let clientWidth = document.documentElement.clientWidth || document.body.clientWidth;

        if(clientWidth < 1540)
        {
            this.setState({ col: 'col-xl-12'});
            Event.emit('hide_games');
        }
        else {
            this.setState({ col: 'col-xl-9'});
            Event.emit('show_min_games');
        }

        if(isMobile()){
            this.setState({ padding: 'p-0', margin: 'mt-1', ovh: 'ovh' });
        }
    }

    render() {
        let { col, padding, margin, ovh } = this.state;

        const help = 'Crypto Classic Dice is a probability game established by blockchain hash value calculation and algorithm, provides more fun with bet and prediction, in which the closer the number rolled by players to the random number, the higher the probability winning.';

        return (
            <>
                <Helmet>
                    <title>Classic Dice - Crypto Casino Games</title>
                    <meta name="keywords" content="Crypto Classic Dice Game, Online Classic Dice Game, Bitcoin Classic Dice Game, Blockchain Classic Dice Game, Best Crypto Classic Dice Game, coingator.io Classic Dice"/>
                    <meta name="description" content={help} />
                    <meta name="og:title" content="Play Online Classic Dice - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <meta name="og:description" content="Play Online Classic Dice - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <link rel="canonical" href="https://coingator.io/classic-dice" />
                </Helmet>
                <ReactCSSTransitionGroup
                    transitionAppear={true}
                    transitionAppearTimeout={250}
                    transitionEnterTimeout={200}
                    transitionLeaveTimeout={200}
                    transitionName={'SlideIn'}
                >
                    {this._Mounted ?
                    <Row className={ "animated fadeIn " + ovh}>
                        <div className="d-none">Crypto Classic Dice Description: {help}</div>
                        <Col sm={12} className={'m-auto ' + col + ' ' + padding}>

                            {/* BANKROLL */}
                            <Card className="mb-0">
                                <Card.Body className="px-2 py-3">
                                    <Row>
                                        <Col md={6} className={'col-6 text-left'}>
                                            <BankRoll game={'classic_dice'}/>
                                        </Col>
                                        <Col md={6} className={'col-6 text-right'}>
                                            <TopBarGames image={"/assets/images/two.dice.png"} help={help} />
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                            {/* GAME */}
                            <Card className="mb-0">
                                <Card.Body className={'bg-dice-options p-1 animated fadeIn'} id="roll-panel">
                                    <Game engine={this.state.engine} />
                                </Card.Body>
                            </Card>

                            {/* BET */}
                            <Card className="mb-0">
                                <Card.Body className={padding}>
                                    <Row>
                                        <Col sm={12} md={10} className={'m-auto'}>
                                           <Bet engine={this.state.engine} />
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                            {/* QUEUE */}
                            <Card className="mb-5">
                                <Card.Body className="p-1">
                                    <Row>
                                        <Col sm={12} md={12} className={'m-auto px-1'}>
                                            <Queue t={this.props.t} game={'classic_dice'}/>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                        </Col>
                    </Row>
                    :
                    <div className="game-loader text-center">
                        <div className="spinner-grow text-info my-3" role="status"/>
                    </div>
                    }
                </ReactCSSTransitionGroup>
            </>
        );
    }
}

export default Index;