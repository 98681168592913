export const rand = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min

export const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms))

export const lerp = (a1, a2, t) => a1 * (1 - t) + a2 * t

export const backout = (amount) => {
	return function(t) {
		return (--t*t*((amount+1)*t + amount) + 1);
	};
};

export function isMobile() {
    let clientWidth = document.documentElement.clientWidth || document.body.clientWidth;
    return clientWidth < 992
}