import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Row, Col, Button, Dropdown} from "react-bootstrap";
import RangeCredit from "../../Components/Game/Addons";
import {gameCoin} from "../../../actions/gameCoin";
import {setRouletteNumber} from "../../../actions/gameRoulette";
import {setWallet} from "../../../actions/gameWallet";
import storage from "../../../Storage";
import C from "../../../Constant";
import {Event, __, isValidNumber, wait, forceSatoshiFormat, sendNotfication} from "../../../Helper";

class Bet extends React.Component {
    _isMounted = false;
    constructor (props){
        super(props);
        this.state = {
            engine: this.props.engine,
            bet: 'manual',
            started: false,
            token: storage.getKey('token')!== null ? storage.getKey('token'): false,
            amount: forceSatoshiFormat(storage.getKey('lam') ? storage.getKey('lam') : .00000010),
            selected: 0,
            inputDisabled: false,
            buttonText: 'BET',
            betNumber: 10,
            firstBetNumber: 10,
            onWin: 0,
            onLost: 0,
            stopOnWin: 0
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleBet = this.handleBet.bind(this);
    }

    componentWillUnmount() {
        let { engine, started} = this.state;
        engine.init = false;
        this._isMounted = false;

        try {
            if(started)
                document.getElementById('bet').click()
        }
        catch (e) {}
    }

    componentDidMount() {
        this._isMounted = true;
        let { engine } = this.state;
        this.props.gameCoin();
        engine.on(C.BUSTED_ROULETTE, (data) => this.busted());
        engine.on(C.ERROR_ROULETTE, (data) => this.error(data));

        this.props.setRouletteNumber(this.state.selected);
    }

    busted(){
        this.setState({ inputDisabled: false });
    }

    placeAutoBet(){
        if(this._isMounted){
            let { engine, token, amount, selected, betNumber, firstBetNumber, onWin, onLost, stopOnWin } = this.state;
            engine.init = true;
            engine.token = token;
            engine.amount = amount;
            engine.selected = selected;
            engine.coin = this.props.coin;
            engine.betNumber = firstBetNumber;
            engine.stopOnWin = parseInt(stopOnWin);
            engine.onWin = parseInt(onWin);
            engine.onLost = parseInt(onLost);
            engine.autoStart();
            this.setState({ betNumber: betNumber - 1 });
        }
    }

    placeBet(){
        if(this._isMounted){
            let { token, selected, amount, engine } = this.state;

            wait(100)
                .then(() => {
                    engine.init = true;
                    engine.token  = token;
                    engine.amount = amount;
                    engine.selected = selected;
                    engine.start();
                })
        }
    }

    error(data) {
        if(this._isMounted){
            sendNotfication(data.message, 'info', 'top-center');

            Event.emit('reset_table')

            if(data.code === 'credit')
                this.props.setWallet(true, data.uid);

            this.setState({ inputDisabled: false });

            if(this.state.started){
                this.setState({ started: false, buttonText: 'Start Auto Mode' });
            }
        }
    }

    handleBet(e){
        e.preventDefault();
        let { token, amount, bet, started } = this.state;

       // Check User
        if(!token){
           return Event.emit('showAuthModal', true);
        }

        if(parseFloat(amount) <= 0){
            this.setState({ amount: forceSatoshiFormat(0.00000010) });
        }
        else {
            amount = Math.min(parseFloat(storage.getKey('credit')), parseFloat(amount));
            this.setState({ amount: forceSatoshiFormat(amount) });
        }

        if(bet === 'auto')
        {
            if(started)
            {
                this.setState({ started: false, buttonText: 'Start Auto Mode' });
                return false;
            }
            else {
                this.setState({ started: true, buttonText: 'Stop Auto Mode' });
                this.placeAutoBet();
            }
        }
        else
        {
            this.setState({ inputDisabled: true });
            this.placeBet();
        }
    }

    handleNumber = (num) => {
        this.props.setRouletteNumber(num);
        this.setState({ selected: parseFloat(num) });
    }

    handleInputChange(event){
        let target = event.target;
        let value = target.value;

        if(target.name === 'amount' || target.name === 'stopOnWin')
        {
            if(!isValidNumber(value)) return;
        }

        if(target.name === 'amount'){
            storage.setKey('lam', value);
        }

        this.setState({ [target.name]: value });
    }

    betMode = (type) => {
        let text;

        if(type === 'auto'){
            text = 'Start Auto Mode';
        }
        else {
            text = 'BET';
        }
        this.setState({ bet: type, buttonText: text });
    };
    
    setAmount = (val) => {
        var am = forceSatoshiFormat(val);
        this.setState({ amount: am });
        storage.setKey('lam', am );
    }

    setNumber = (e, num) => {
        e.preventDefault();
        this.setState({ betNumber: num, firstBetNumber: num });
    };

    render() {
        let { bet, started, inputDisabled } = this.state;

        let numbers = [];

        for(var i = 0; i < 22; i++){
            numbers.push(i);
        }

        return (
            <>
                <form className="w-100 mt-1" onSubmit={(e) => {this.handleBet(e)}}>
                    <Row>
                        <Col md={5} sm={12} className="m-auto">
                            {bet === 'manual' &&
                            <Button variant={'btn btn-md btn-block btn-bet mt-2'}
                                    disabled={inputDisabled}
                                    type="submit">
                                {this.state.buttonText}
                            </Button>
                            }
                        </Col>
                    </Row>
                </form>
            </>
        );
    }
}

Bet.propTypes = {
    coin: PropTypes.string.isRequired,
    credit: PropTypes.string
};

const mapStateToProps = state => ({
    coin: state.items.coin,
    credit: state.items.credit
});

export default connect(mapStateToProps, { gameCoin, setRouletteNumber, setWallet })(Bet);