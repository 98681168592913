/* eslint-disable */
import MyEmitter from "events";
import Sprite from "../Sprite/index.js";
import Graphic from "../Graphic/index.js";
import Text from "../Text/index.js";
import Message from "../Text/Message.js";
import Animate from "../Animate/index.js";
import {rand, wait, backout} from "../Helper/index.js";
import {playAudio, forceSatoshiFormat, __, isMobile} from "../../../../../Helper";

function Game(engine, isLogged) {
    let self = this;

    self.engine = engine;
    self.pixi = PIXI;
    self.app = null;
    self.container = null;
	self.stage = false;
	self.running = false
	self.showMessage = false;
	self.active = true;
	self.isWinner = false;
	self.playerStarted = false;
	self.count = 0;
    self.isLogged = isLogged;
	self.coin = "BTC";
	self.tableAmounts = [];
	self.tweening = []
	self.reels = [];
	self.chips = [];
	self.table = [];
	self.current_chip = []
	self.temp_chip = []
	self.amounts = []
	self.amount = null
	self.winItem = null
	self.winText = null
	self.amount_place = null;
	self.tables = [];
	self.table_amount = 0;
	self.anim = new Animate();
	self.userContainer = [];
	self.event = new MyEmitter();
	self.selectedDone = false;
	self.allChips = [];
	self.tem_chip = []
	self.numbers = [31, 33, 10, 6, 36, 11, 5, 15, 24, 28, 23, 19, 1, 9, 17, 26, 22, 35, 21, 18, 3, 27, 14, 8, 16, 0, 20, 2, 4, 13, 7, 34, 29, 30, 32, 25, 12]
	
    self.init = function () {
        self.app = new PIXI.Application({
            view: document.getElementById("game"),
            width: 800,
            height: 500,
			transparent: true,
			antialias: false
        });

		if(isMobile())
        {
            self.app.renderer.autoDensity = true;
            self.app.renderer.resize(320, 350);
        }
		
		self.app.sortableChildren = true
		
		self.stage = self.app.stage
        self.container = new self.pixi.Container();
		self.container.sortableChildren = true;
        self.stage.addChild(self.container);
		self.width = self.app.screen.width;
		self.height = self.app.screen.height;
		
		// Event Data
		self.eventData()

		// Background
		// let background = new Sprite(1, 'bg.jpg', 0, 0, self.width * 2, self.height * 2).item;
		let background = new Graphic(1, 0, 0, self.width,  self.height, 0x1d5e27, 0).item;
		self.container.addChild(background);
		
		// Marker
		let marker = new Sprite(3, 'marker.png', self.width /2, 0, 60, 60).item;
			marker.zIndex = 99999
		self.container.addChild(marker);
		
		if(!isMobile()){
			// Line 1
			let line = new Sprite(3, 'line.png', 0, 35, self.width * 2, 30).item;
			self.container.addChild(line);
			// Line 2
			let line2 = new Sprite(3, 'line.png', 0, 90, self.width * 2, 30).item;
			self.container.addChild(line2);
		}
		
		// Message
		wait(500).then(() => {
			let message = new Message('Drag Your Chips !', self.width / 1.6, self.height / 1.25).item;
			let b1 = new Graphic(15, 300, self.height - 5, 400,  2, 0xFFFFFF, 0).item;
			let b2 = new Graphic(15, 300, self.height - 65, 400,  2, 0xFFFFFF, 0).item;
			
			self.message = new self.pixi.Container();
			self.message.addChild(message, b1, b2);
			
			self.app.stage.addChild(self.message);
			self.showMessage = true;
		})
		
		// Tables
		for(var i = 0; i < 3; i++){
			var x = (i * 210) + 190;
			var y = self.height / 2
			
			if(isMobile()){
				x = (i * 100) + 60;
			}
			self.addTables(i, x, y);
		}
		
		// Numbers
		self.addNumbers();
		
		// Player Table
		let playerTable = new Graphic(6, 0, self.height - 70, self.width,  80, 0x000000, 0, .55).item;
		self.container.addChild(playerTable);
		
		self.userContainer = new self.pixi.Container();
		self.userContainer.sortableChildren = true;
		
		if(!isMobile()){
			// Player Avatar
			let avatar = new Sprite(1, 'avatar.png',  40 , self.height - 35).item;
			playerTable.addChild(avatar);
		}	
		
		let xx = 90;
		
		if(isMobile()){
			xx = 50
		}
		
		self.amount_place = new Text(3, self.table_amount, '#FFF', 15, xx, self.height - 35).item;
		self.userContainer.addChild(self.amount_place);
		playerTable.addChild(self.userContainer);
		
		//Animation
		let ticker = new PIXI.Ticker();
			ticker.add(self.animates)
			ticker.start();
    }
	
	self.animates = function(delta){
		self.anim.render(delta, self);
	}
	
	self.addTables = function(i, x, y){
		if(!isMobile()){
			self.table[i] = new Sprite(1, 'table' + i + '.png', x, y).item;
		}
		else {
			self.table[i] = new Sprite(1, 'table' + i + '.png', x, y, 100, 128).item;
		}
		self.table[i].interactive = true;
		self.table[i].id = i
		self.container.addChild(self.table[i]);
	}
	
	self.addChip = function(id, x, y){
		let chip = new Sprite(7, 'BlackChip.png', x, y, 50, 50, .72).item;
		
		if(isMobile()){
			chip = new Sprite(7, 'BlackChip.png', x, y, 30, 30, .72).item;
		}

		chip.interactive = true;
		chip.buttonMode = true;

		var a = id * 10
		
		chip.id = id
		chip.amount = a;
		chip.coin = self.coin;
		chip.anchor.set(0.5);
		
		if(isMobile()){
			chip.scale.x = .40
			chip.scale.y = .40
		}
		else {
			chip.scale.x = .72
			chip.scale.y = .72
		}

		if(self.isLogged){
			chip
				.on('pointerdown', self.onDragStart)
				.on('pointerup', self.onDragEnd)
				.on('pointerupoutside', self.onDragEnd)
				.on('pointermove', self.onDragMove);
		}
			
		const amount = new Text(8, a, '#000', 14, 0, -10).item;
		const coin = new Text(8, 'm' + self.coin, '#000', 14, 0, 8).item;
		chip.addChild(amount, coin);
		
		self.chips[id] = chip
		self.chip_id = id;
		self.container.addChild(chip);
    }
	
	self.onDragStart = function(e){
		if(self.running) return;

		// store a reference to the data
		// the reason for this is because of multitouch
		// we want to track the movement of this particular touch
		
		if(this.placed === true) return;
		
		this.data = e.data;
		this.alpha = 0.5;
		this.dragging = true;
		self.currentChip = this;
		self.ready_chip = this
	}
	
	self.onDragEnd = function(e){
		this.alpha = 1;
		this.dragging = false;
		
		if(this.placed === true) return;
		
		self.table[0].alpha = 1
		self.table[1].alpha = 1
		self.table[2].alpha = 1
		
		var currentTable = null;
		
		this.placed = false;
		
		if(!isMobile())
		{
			if(this.x > 105 && this.x < 277 && this.y > 130 && this.y < 360){
				currentTable = 0
				this.placed = true;
				
			}
			else if(this.x > 300 && this.x < 500 && this.y > 130 && this.y < 360){
				currentTable = 1
				this.placed = true;
			}
			else if(this.x > 530 && this.x < 700 && this.y > 130 && this.y < 360){
				currentTable = 2
				this.placed = true;
			}
		}
		
		else {
			if(this.x > 20 && this.x < 99 && this.y > 120 && this.y < 244){
				currentTable = 0
				this.placed = true;
				
			}
			else if(this.x > 125 && this.x < 200 && this.y > 120 && this.y < 244){
				currentTable = 1
				this.placed = true;
			}
			else if(this.x > 220 && this.x < 310 && this.y > 120 && this.y < 244){
				currentTable = 2
				this.placed = true;
			}
		}
		
		var x;
		if(this.id === 1)
			x = 320;
		else if(this.id === 2)
			x = 440;
		else if(this.id === 3)
			x = 560;
		else if(this.id === 4)
			x = 680;

		if(isMobile())
			x /= 2.44

		// When chip is not at in any tables
		if(currentTable === null){
			self.addChip(this.id, x, self.height - 35);
			self.container.removeChild(this);
			return
		}
		
		self.allChips.push({ table: currentTable, amount: this.amount, coin: this.coin });
		
		self.event.emit('play', {table: currentTable, player: 0, amount: this.amount });
		
		// add a new chip in default place
		self.addChip(this.id, x, self.height - 35);
		
		self.tem_chip.push(this);
		
		// set the interaction data to null
		this.data = null;

		playAudio('dice.mp3')

		self.playerStarted = true;
	}
	
	self.onDragMove = function(e){
		if (this.dragging) {
			
			if(this.placed === true) return;
			
			const newPosition = this.data.getLocalPosition(this.parent);
			this.x = newPosition.x;
			this.y = newPosition.y;
			
			if(!isMobile()){
				
				// Table 1
				if(newPosition.x > 105 && newPosition.x < 277 && newPosition.y > 130 && newPosition.y < 360){
					self.table[0].alpha = 0.5
				}
				else {
					self.table[0].alpha = 1
				}
				
				// Table 2
				if(newPosition.x > 300 && newPosition.x < 500 && newPosition.y > 130 && newPosition.y < 360){
					self.table[1].alpha = 0.5
				}
				else {
					self.table[1].alpha = 1
				}
				
				// Table 3
				if(newPosition.x > 530 && newPosition.x < 700 && newPosition.y > 130 && newPosition.y < 360){
					self.table[2].alpha = 0.5
				}
				else {
					self.table[2].alpha = 1
				}
			
			}
			else {
				// Table 1
				if(newPosition.x > 20 && newPosition.x < 99 && newPosition.y > 120 && newPosition.y < 244){
					self.table[0].alpha = 0.5
				}
				else {
					self.table[0].alpha = 1
				}
				
				// Table 2
				if(newPosition.x > 125 && newPosition.x < 200 && newPosition.y > 120 && newPosition.y < 244){
					self.table[1].alpha = 0.5
				}
				else {
					self.table[1].alpha = 1
				}
				
				// Table 3
				if(newPosition.x > 220 && newPosition.x < 310 && newPosition.y > 120 && newPosition.y < 244){
					self.table[2].alpha = 0.5
				}
				else {
					self.table[2].alpha = 1
				}
			}
		}
	}
	
	self.addChipPlay = function(id, player){
		let current = self.ready_chip;
		let am = parseFloat(current.amount) / 100000

		const chip = new Sprite(10, 'BlackChip.png', current.x, current.y, 50, 50, .72).item;
		const amount = new Text(10, current.amount, '#000', 14, 0, -10).item;
		const coin = new Text(10, current.coin, '#000', 14, 0, 8).item;
		
		chip.addChild(amount, coin);
		
		chip.visible = false
		self.current_chip.push(chip);
		self.table[id].addChild(chip);
		self.temp_chip[id] = chip
		
		//Add Amount
		self.table_amount += am
		
		self.tableAmounts[id] = self.table_amount

		self.engine.data.push({
			table: id,
			amount: am,
			coin: self.coin
		})

		self.amount_place.text =  forceSatoshiFormat(self.table_amount)
	}
	
	self.addNumbers = function(){
		var rc = new self.pixi.Container();
        rc.zIndex = 11

        if (isMobile())
            rc.x = -36
        else
            rc.x = 10
                    
		var reel = {
			symbols: [],
			backend: [],
			position: 0,
			previousPosition: 0,
			blur: new PIXI.filters.BlurFilter()
		};
		reel.blur.blurX = 0;
		reel.blur.blurY = 0;
		rc.filters = [reel.blur];
					
		let color = 0x222222;

		self.numbers.forEach((j, i) => {
			var size = 95

			if(isMobile())
				size = 55
		
			var symbol = new Text(4, j, '#FFF', size, 0, 0).item;
				symbol[i] = j
			reel.symbols.push(symbol);
			
			if(color === 0x222222)
				color = 0xbd0f0f
			else
				color = 0x222222
			
			if(j === 0)
				color = 0x8bc34a
						
			let graphics = new Graphic(5, 1, 35, 50, 50, color, 0).item;
			reel.backend.push(graphics);
			
			rc.addChild(graphics, symbol);
		})

		self.reels.push(reel);
		
		self.container.addChild(rc);
    }
	
    // Function to start playing.
   self.play = function() {
		if(self.running) return;
		self.running = true;

		playAudio('wheel2.mp3')

		for(var i = 0; i < self.reels.length; i++) {
			var r = self.reels[i];
			var extra = Math.floor(Math.random()*22);
			var target = r.position + 50 + i * 9 + extra - 9;
			var time =5000;
			self.tweenTo(r, "position", target, time, backout(0.6), null, i == self.reels.length-1 ? self.reelsComplete : null);
		}
    }
   
	// Very simple tweening utility function. This should be replaced with a proper tweening library in a real product.
   self.tweenTo = function(object, property, target, time, easing, onchange, oncomplete) {
		const tween = {
			object,
			property,
			propertyBeginValue: object[property],
			target,
			easing,
			time,
			change: onchange,
			complete: oncomplete,
			start: Date.now(),
		};
		self.tweening.push(tween);
		return tween;
	}
	
    // Reels done handler.
   self.reelsComplete = function(result) {
   		wait(2300).then(() => {
        	self.running = false;
   		})
        console.log(result)
   }
   
    // Player is winner.
   self.addWin = function(amount) {
   		if(parseFloat(amount) !== 0){
			self.isWinner = true;
			self.winItem = new Sprite(5, 'win.gif', self.width /2, self.height / 2).item;
			self.winItem.visible = false
			self.winText = new Text(5, 'You Won ' + forceSatoshiFormat(amount), 'orange', 20, self.width /2, self.height / 1.5).item;
			self.winText.visible = false
			self.winItem.scale.x = 0
			self.winItem.scale.y = 0
			self.stage.addChild(self.winItem, self.winText);
   		}
   }
	
    // Event Response.
   self.eventData = function() {
	   
	//Play Game
	 self.event.on('play', (data) => {
		if(self.running) return;

		 let { table, player, amount } = data;

		 self.addChipPlay(table, player);
	 })
	 
	//Play Win
	 self.event.on('win', (data) => {
	 	const { playerWin, result } = data;
	 	 self.engine.busted(result, playerWin);
		 self.addWin(playerWin);
	 })
   }

    // chnageChip
   self.changeChip = function(coin) {

   	if(!self.playerStarted){
		self.coin = coin;
		engine.coin = coin;
	
		// Chips
		for(var i = 1; i < 5; i++){
			var x = (self.width / 4) + (i * 120)
			var y = self.height - 35
			
			if(isMobile()){
				x = (self.width / 4) + (i * 50)
			}
			
			self.addChip(i, x, y);
		}
   	}
   }

    // Reset Table after error
   self.reset = function() {
   	self.playerStarted = false;
	for(var i = 0; i < self.tem_chip.length; i++){
		self.container.removeChild(self.tem_chip[i]);
	}
   }
}

export default Game;