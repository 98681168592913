import React from "react";
import { Link } from "react-router-dom";
import { Carousel, Row, Card, Col } from "react-bootstrap";
import Contest from "./Contest";

class Slider extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        return (
            <Row>
                <Col className="col-4">
                    <AddCarousel a={'placement'} b={'placement1'} c={'placement3'} title={"Join Today !"} />
                </Col>
                <Col className="col-4">
                    <AddCarousel a={'placement4'} b={'placement3'} c={'placement'} title={"Play Games !"} />
                </Col>
                <Col className="col-4">
                    <AddCarousel a={'placement3'} b={'placement'} c={'placement1'} title={"Earn Crypto !"} />
                </Col>
            </Row>
        );
    }
}


function AddCarousel(props) {
    const { a, b, c, title } = props;

    return <Carousel>
        <Carousel.Item interval={3000} className="text-left">
            <Card className="bg-transparent">
                <Card.Body className={a}>
                </Card.Body>
            </Card>
            <Carousel.Caption>
                <h3>{title}</h3>
                <p>Want 0.00000030 BTC Free ?</p>
            </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={3300} className="text-left">
            <Card className="bg-transparent">
                <Card.Body className={b}>
                </Card.Body>
            </Card>
            <Carousel.Caption>
                <h3>{title}</h3>
                <p>Play on 15 uniqu games !</p>
            </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={3600} className="text-left">
            <Card className="bg-transparent">
                <Card.Body className={c}>
                </Card.Body>
            </Card>
            <Carousel.Caption>
                <h3>{title}</h3>
                <p>Make crypto by playing games...</p>
            </Carousel.Caption>
        </Carousel.Item>
    </Carousel>
}

export default Slider;