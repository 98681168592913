import React from "react";
import {Modal} from "react-bootstrap";
import PerfectScrollbar from 'perfect-scrollbar';
import { wait, isMobile, isTablet, Event, __, hasClass, encode } from "../../../../Helper";
import C from "../../../../Constant";
import socket from "../../../../Socket";
import Messages from "./Messages";
import Submit from "./Submit";
import Country from "./Country";
import Rain from "./Rain";
import Rules from "./Rules";

const chatSideBarWidth = 330;

class Chat extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            show: true
        };
    }

    componentDidMount() {
        if(isMobile() || isTablet()){
            this.setState({ show: false });
        }
        window.addEventListener('resize', this.handleResize);

        wait(500)
            .then( () => {
                this.handleResize();
        });

        Event.on('toggle_sidebar', () => {
            wait(100)
                .then( () => {
                    this.handleResize();
                });
        })
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        const pageContent = document.querySelector('#page-content');
        const clientWidth = document.documentElement.clientWidth || document.body.clientWidth;
        const clientHeight = document.documentElement.clientHeight || document.body.clientHeight;

        if(pageContent !== null)
        {
            let space = 250;

            const leftSidebar = document.querySelector('.left-sidenav');
            const games = document.querySelectorAll('.game-image');

            if(hasClass(leftSidebar, 'min') || !this.state.show)
            {
                space -= 170;

                if(!isMobile()){
                        games.forEach((g, i) => {
                            if(g !== null)
                                g.style.height = 160 + 'px';
                        })
                    }
            }
            else {
                games.forEach((g, i) => {
                    if(g !== null)
                        g.style.height = 120 + 'px';
                })
            }

            let pWidth = this.state.show === true ? clientWidth - (chatSideBarWidth + space): clientWidth;

            if(pWidth < 960){
                Event.emit('hide_games');
            }
        }

        if(!isMobile()){
            this.setState({height: clientHeight - 230});
        }
        else {
            this.setState({height: clientHeight - 210});
        }
    };

    setSide = () => {
        let show = !this.state.show;

        wait(1).then( () => {
            this.handleResize();
        });

        Event.emit('toggle_chat', show);

        wait(200).then( () => {
            this.setState({ show: show });
        });
    };

    render(){
        const { t } = this.props;
        return(
            <>
                <button onClick={this.setSide} className={"btn rounded notif-btn tbtn btn-ocol px-1 py-1"}>
                    <svg className="chat-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                           <path d="M391.553 64H57.607C53.131 64 48 67.745 48 72.159v214.217c0 4.413 5.131 8.624 9.607 8.624H115v88.894L205.128 295h186.425c4.477 0 7.447-4.211 7.447-8.624V72.159c0-4.414-2.971-8.159-7.447-8.159z"></path>
                           <path d="M456.396 127H424v166.57c0 15.987-6.915 26.43-25.152 26.43H218.096l-38.905 39h129.688L399 448v-89h57.396c4.478 0 7.604-4.262 7.604-8.682V136.103c0-4.414-3.126-9.103-7.604-9.103z"></path>
                    </svg>
                </button>

                 { this.state.show && <Content t={t} height={this.state.height} /> }
            </>
        );
    }
}

class Content extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            width: chatSideBarWidth + 'px',
            effect: "slideInRight"
        }
    }

    componentDidMount() {
        loadScroll();
        this.handleResize();
        window.addEventListener('resize', this.handleResize);

        Event.on('toggle_chat', (show) => {
            if(!show)
                this.setState({ effect: "slideOutRight"})
            else
                this.setState({ effect: "slideInRight"})
        })
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        if(isMobile()){
            this.setState({ width: '100%' })
        }
        else {
            this.setState({ width : chatSideBarWidth + 'px'})
        }
    };

    render() {
        const { t } = this.props;
        return (
            <>
                <div className={'main-menu-inner animated ' + this.state.effect} style={{ width: this.state.width }}>
                    <div className="menu-body slimscroll">
                        <div className="main-icon-menu-pane active">
                            <div className="title-box">
                                <h6 className="menu-title">
                                    {/*<Country t={t}/>*/}
                                    <Rules t={t} />
                                    <Rain t={t}/>
                                </h6>
                            </div>
                            <div id="messages" style={{height: this.props.height}}>
                                <Messages t={t}/>
                            </div>
                            <Submit t={t}/>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function loadScroll(){
    if(document.getElementById('messages') !== null){
        let ps = new PerfectScrollbar('#messages', {
            wheelSpeed: 1,
            suppressScrollX: true,
            wheelPropagation: true,
            minScrollbarLength: 2
        });
        ps.update();
    }
}

export default Chat;