import React from 'react'
import { Link } from 'react-router-dom'
import ReactTooltip from "react-tooltip";
import PerfectScrollbar from 'perfect-scrollbar';
import Games from "./Games";
import Social from "./Social";
import Bitcoin from "./Bitcoin";
import {Event, wait, addNewStyle} from "../../../../Helper";
import Verify from "./Verify";
// import $ from "jquery";

//Icons
import AffiliateIcon from "./Icons/Affiliate";
import SlotsIcon from "./Icons/Slots";
import GamesIcon from "./Icons/Games";
import HomeIcon from "./Icons/Home";
import RulesIcon from "./Icons/Rules";
import BonusIcon from "./Icons/Bonus";
import LiveSupportIcon from "./Icons/LiveSupport";
// import VipIcon from "./Icons/Vip";

class Menu extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            show: false,
            home: false,
            activeHome: true,
            loaded: false,
            loading: false
        };
    }

    componentDidMount() {
        Event.on('show_support', () => {
            this.support();
        })
        Event.on('active_games', () => {
            this.active('activeGames')
        })
        Event.on('active_link', (link) => {
            this.activeLink(link)
        })
        Event.on('show_games', () => {
            this.setState( { show: true });
        })
        Event.on('hide_games', () => {
            this.setState( { home: true });
        })
        Event.on('show_min_games', () => {
            // this.setState( { home: false });
        })
        const ps = new PerfectScrollbar('#lss', {
            wheelSpeed: 1,
            suppressScrollX: true,
            wheelPropagation: true,
            minScrollbarLength: 2
        });
        ps.update();
    }

    activeLink = (link) => {
        let name = link.replace("/", "");

        switch(name)
        {
            case "slots":
                return this.active("activeSlots")
            case "bonus":
                return this.active("activeBonus")
            case "affiliate":
                return this.active("activeAff")
            case "support":
                return this.active("activeSupport")
            case "games":
                return this.active("activeGames");
            case "rules":
                return this.active("activeRules");
            case "vip":
                return this.active("activeVip");

            default: return this.active('activeHome')
        }
    }

    active = (link) => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        this.setState({
            activeHome: false,
            activeVip: false,
            activeLive: false,
            activeSlots: false,
            activeAff: false,
            activeBonus: false,
            activeSupport: false,
            activeGames: false,
            activeRules: false
        });
        this.setState({
            [link]: true
        });
    }

    support = () => {
        this.setState({ loading: true })

        var d, s;
        window.$crisp=[];
        window.CRISP_WEBSITE_ID="b926e446-5094-4424-88e2";

        if(!this.state.loaded){
            this.setState({ loaded: true });
            (function(){
                d = document;
                s = d.createElement("script");
                s.src="https://client.crisp.chat/l.js";
                s.id= "chtt";
                s.async=1;
                d.getElementsByTagName("head")[0].appendChild(s);
            })();
        }

        addNewStyle('#crisp-chatbox {display:block !important;}');
        addNewStyle('.crisp-client .cc-kv6t .cc-1xry .cc-ge4v .cc-qqzz .cc-5b24 .cc-19cg {display:none !important;}');

        wait(2000).then(() => {
            var close = document.querySelector('.cc-wdhl');
            if(close === null) return
            close.addEventListener('click',function(event){
                addNewStyle('#crisp-chatbox {display:none !important;}');
                document.getElementById('chtt').src = "";
            });
        })

        wait(4000).then(() => {
            this.setState({ loading: false })
        })
    }

    render(){
        const {t, type} = this.props;
        const { activeSupport, activeAff, activeBonus, activeSlots, activeVip, activeLive, activeHome, activeGames, activeRules } = this.state;
        return(
                <>
                    {type === 'min' && <ReactTooltip /> }
                    <div id="lss" className="menu-body" onMouseLeave={ () => this.setState({ show: false }) }>
                        <div className="menu-pane">
                            <ul className="nav">
                                <li className={ activeHome ? 'active nav-item' : 'nav-item' } data-tip={ type === 'min' ? t('home') : "" }>
                                    <Link onClick={ () => this.active('activeHome') } className="nav-link-x" to={'/'}>
                                        <div className="avatar-box thumb-xs align-self-center mr-2">
                                            <HomeIcon />
                                        </div>
                                        <span className="menu-name">{t('home')}</span>
                                     </Link>
                                </li>
                                <li className={ activeGames ? 'active nav-item' : 'nav-item' } 
                                    data-tip={ type === 'min' ? t('games') : "" } 
                                    onMouseEnter={ () => this.setState({ show: true }) }>
                                    <Link onClick={ () => this.active('activeGames') } className="nav-link-x" to={'/games'}>
                                        <GamesIcon />
                                        <span className="menu-name">{t('games')}</span>
                                        <i className="mdi mdi-pan-right side-icon" />
                                     </Link>
                                </li>
                                <div id="prg" className={ this.state.show ? "animated games-parent" : 'd-none' }>
                                    <Games clicked={ () => this.active('activeGames') } />
                                </div>
                                <li className={ activeSlots ? 'active nav-item' : 'nav-item' } data-tip={ type === 'min' ? t('slots') : "" }>
                                    <Link onClick={ () => this.active('activeSlots') } className="nav-link-x" to={'/slots'}>
                                        <SlotsIcon />
                                        <span className="menu-name">{t('slots')}</span>
                                     </Link>
                                </li>
                                {/*<li className={ activeVip ? 'active nav-item' : 'nav-item' } data-tip={ type === 'min' ? 'VIP' : "" }>
                                    <Link onClick={ () => this.active('activeVip') } className="nav-link-x" to={'/vip'}>
                                       <VipIcon />
                                        <span className="menu-name">VIP</span>
                                     </Link>
                                </li>*/}
                                <li className={ activeBonus ? 'active nav-item' : 'nav-item' } data-tip={ type === 'min' ? t('bonus') : "" }>
                                    <Link onClick={ () => this.active('activeBonus') } className="nav-link-x" to={'/bonus'}>
                                        <BonusIcon />
                                        <span className="menu-name">{t('bonus')}</span>
                                     </Link>
                                </li>
                                <li className={ activeAff ? 'active nav-item' : 'nav-item' } data-tip={ type === 'min' ? t('affiliate') : "" }>
                                    <Link onClick={ () => this.active('activeAff') } className="nav-link-x" to={'/affiliate'}>
                                       <AffiliateIcon />
                                        <span className="menu-name">{t('affiliate')}</span>
                                     </Link>
                                </li>
                                {/*<li className={ activeRules ? 'active nav-item' : 'nav-item' } data-tip={ type === 'min' ? 'Rules' : "" }>
                                   <Link  onClick={ () => this.active('activeRules') }  className="nav-link-x" to={'/rules'}>
                                    <RulesIcon />
                                    <span className={'cpt menu-name'}>
                                       Rules
                                    </span>
                                </Link>
                                </li>*/}
                                <li className="nav-item" data-tip={ type === 'min' ? t('verify_result') : "" }>
                                  <Verify t={t} />
                                </li>
                                <li className={ activeSupport ? 'active nav-item' : 'nav-item' } data-tip={ type === 'min' ? t('support') : "" }>
                                    <Link onClick={ () => this.support() } className="nav-link-x" to={'#'}>
                                    <LiveSupportIcon />
                                        {
                                            this.state.loading ?
                                                <span style={{ color: "#3ce062" }} className="menu-name">Please Wait...</span>
                                            :
                                                <span style={{ color: "#3ce062" }} className="menu-name">Live {t('support')}</span>
                                        }
                                     </Link>
                                </li>
                                {type !== 'min' &&
                                    <>
                                        <hr className="side-border mx-0" />
                                        <li className="nav-item">
                                            <Social t={t}/>
                                        </li>
                                        <hr className="menu-name side-border mx-0" />
                                        <Bitcoin />
                                    </>
                                }
                            </ul>
                        </div>
                    </div>
            </>
        );
    }
}

export default Menu;