import React from 'react';
import ImagePreloader from 'image-preloader';
import C from "../../../Constant";
import {__, crypter, getRandomInt, randomString, playAudio} from "../../../Helper";

class Game extends React.Component {
    _isMounted = false
    constructor(props){
        super(props);
        this.state = {
            engine: this.props.engine,
            cardList: [],
            cards: [],
            rotate: 0,
            nextCard: null,
            currentCard: null,
            results: [],
            loading: true,
            progress: 0
        }
    }

    componentDidMount() {
        let { engine } = this.state;
        this._isMounted = true;
        this.setupCards();
        engine.on(C.PLAY_HILO, () => this.next());
        engine.on(C.BUSTED_HILO, () => this.busted());
        engine.on("next", () => this.next());
        engine.on("higher", () => this.higher());
        engine.on("lower", () => this.lower());
        engine.on("lost", () => this.busted());

        this.preload()
    }

    componentWillUnmount() {
        let { engine} = this.state;
        this._isMounted = false;
    }

    preload = () => {
        var preloader = new ImagePreloader();

        let images = [];

        for(let i = 1; i < 55; i++){
            images.push('/assets/images/video-poker/cards/' + i + '.png')
        }

        let self = this, total = images.length, loaded = 0;

        preloader.onProgress = function() {
            self.setState({ progress: parseInt(100 / total * ++loaded) })
        };

        preloader
            .preload(images)
            .then(function(status) {
                self.setState({ loading: false });
            });
    }

    next = () => {
        if(this._isMounted){
            let { engine } = this.state;

            if(engine.gameStatus === 'started')
            {
                let rand = this.state.cards[getRandomInt(0, this.state.cards.length)];
                if(rand === undefined)
                    rand = 2;

                let currentCard = this.state.nextCard !== null ? this.state.nextCard: rand;

                this.addOldCard( this.state.nextCard !== null ? this.state.nextCard : rand );
                this.setState({ currentCard: currentCard, nextCard: rand, rotate: this.state.rotate === 540 ? 180: 540 });
                playAudio('card3.mp3');
            }
        }
    }

    setupCards = () => {
        if(this._isMounted){
            for(let i = 1; i < 55; i++){
                this.setState(state => ({ cards: [i, ...state.cards] }));
            }
        }
    }

    addOldCard = (card) => {
        if(this._isMounted){
            this.setState(state => ({ results: [parseFloat(card), ...state.results] }))
            let add = this.state.cardList.concat([
                <CardBlock key={randomString(4)} card={card} />
            ]);
            this.setState({ cardList: add });
        }
    };

    higher = () => {
        if(this._isMounted){
            let { engine, results, nextCard } = this.state;

            let lastCard = __.last(results);

            engine.selected = 'high';
            engine.addCards(results, nextCard)

            if(lastCard >= nextCard){
                engine.win();
                this.next();
            }
            else {
                this.setState({ cardList: [], results: [] });
                engine.lost();
            }
        }
    };

    lower = () => {
        if(this._isMounted){
            let { engine, results, nextCard } = this.state;

            let lastCard = __.last(results);
            
            engine.selected = 'low';
            engine.addCards(results, nextCard)

            if(lastCard <= nextCard){
                engine.win();
                this.next();
            }
            else {
                this.setState({ cardList: [], results: [] });
                engine.lost();
            }
        }
    };

    busted = () => {
        this.setState({ cardList: [], results: [], currentCard: null, rotate: 0  });
    };

    render() {
        let { cardList, currentCard, nextCard, rotate } = this.state;

        return(
            <>

                {
                    this.state.loading ?
                    <>
                        <div className="game-loading-hilo">
                            Loading {this.state.progress}%
                        </div>
                    </>
                    :
                    <>
                        <div className="hilo-main">
                        <div className="hilo-deck">
                            <div className="hilo-card-region">
                                <div className="hilo-deck-card">
                                    <div className="hilo-flipper" style={{ transform: 'rotateY(' + rotate + 'deg) scale(1)'}}>
                                        <div className="card-back" />
                                        <div className="card-front hilo-card hilo-card_last hilo-card_bordered">
                                            <div className="hilo-label hilo-label_top">
                                                <div className="hilo-sign">
                                                    { currentCard === null ?
                                                        <img src={'/assets/images/video-poker/cards/back2.svg'} className={'img-fluid'} alt="Hilo Card" />
                                                    :
                                                        <img src={'/assets/images/video-poker/cards/' + currentCard + '.png'} className={'img-fluid'} alt="Hilo Card" />
                                                    }
                                                </div>
                                            </div>
                                            <div className="hilo-countdown-wrapper">
                                                <div className="hilo-countdown">
                                                    <svg style={{ display: 'block', width: '100%'}} viewBox="0 0 100 100">
                                                        <path id="timer_back"
                                                              d="M 50,50 m 0,-48.5 a 48.5,48.5 0 1 1 0,97 a 48.5,48.5 0 1 1 0,-97"
                                                              fillOpacity="0"
                                                              stroke="rgba(0, 0, 0, 0.4)') }}"
                                                              strokeWidth="3" />
                                                        <path id="timer"
                                                              d="M 50,50 m 0,-48.5 a 48.5,48.5 0 1 1 0,97 a 48.5,48.5 0 1 1 0,-97"
                                                              fillOpacity="0"
                                                              stroke="#000000"
                                                              strokeWidth="3"
                                                              style={{ strokeDasharray: '304.844, 304.844', strokeDashoffset: '304.844' }} />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="hilo-footer">
                        <div className="hilo-statistics__cards">
                            { cardList.length === 0 ?
                                <div className="empt">E M P T Y</div>
                                :
                                cardList
                            }
                        </div>
                    </div>
                </>
                }
            </>
        );
    }
}

function CardBlock(props) {
    return(
        <>
            <div className="card_stat_2 animated slideInRight">
                <div className="hilo-card hilo-card_sm hilo-card_spades">
                    <div className="hilo-card__sign">
                        <img src={'/assets/images/video-poker/cards/' + props.card + '.png'} className={'img-fluid'} />
                    </div>
                    <div className="hilo-statistics__card-frequency-progress"></div>
                </div>
                <div className="hilo-statistics__card-label">
                    <span>0.99</span>x
                </div>
            </div>
        </>
    );
}

export default Game;