import React from 'react';
import { Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Event } from "../../../../Helper";

export default class Footer extends React.Component {
    render() {
        return (
            <>
                <div className="bgame-footer font-light bg-footer dtw">
                    <Row className="align-items-start no-gutters">
                        <Col md={4} className="col-6 pl-2">
                            <img src="/assets/images/logo-white.png" alt="logo" className="mb-4" style={{ width: 170, verticalAlign: 'text-bottom' }} />
                            <p>Fair Gambling / Earn Crypto !</p>
                            <p>We believe that we have real fair rules, which is just enough to try it once.</p>
                            <p>There is no limit ! Earn on Crypto Casino ...</p>
                        </Col>
                        <Col md={4} className="col-6">
                            <ul className="p-0x">
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <Link to={'/rules?tab=0'}>
                                        Privacy Policy
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/rules?tab=1'}>
                                        User Agreement
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/policies?tab=0'}>
                                        Responsible Gaming
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/policies?tab=1'}>
                                        KYC Policy
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/policies?tab=2'}>
                                        AML Policy
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/rules?tab=1'}>
                                        User Agreement
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/games">Original Games</Link>
                                </li>
                                <li>
                                    <Link to="/affiliate">Affiliate</Link>
                                </li>
                            </ul>
                        </Col>

                        <Col md={4} className="ycenter col-12 text-left">

                            <div className="footer-brands">
                                <a href="https://licensing.newwavelicensing.ph/validator/fdbjs/5b61adc2-a9a0-40c4-9de8-a6e8cdc41023" target="_blank">
                                    <img src="https://licensing.newwavelicensing.ph/badge/fdbjs/5b1b51ad-fea2-40ee-b34d-878fb95da2cb/generate" style={{ width: '45px', marginRight: '10px' }} />
                                </a>

                                <a href="https://cryptogambling.org/" target="_blank">
                                    <img className="img-v" src="/assets/images/verfy.svg" alt="Coin" />
                                </a>

                                <img className="img-18" src="/assets/images/18.svg" alt="Coin" />
                            </div>

                            {/*<div className="d-flex">
                                <Coins />
                            </div>*/}
                            <p className="mt-3">Accepted Currency</p>
                            <div className="footer-coin">
                                <img className="img-fluid" src="/assets/images/btc.png" alt="Coin" />
                                <img className="img-fluid" src="/assets/images/eth.png" alt="Coin" />
                                <img className="img-fluid" src="/assets/images/ltc.png" alt="Coin" />
                                <img className="img-fluid" src="/assets/images/bch.png" alt="Coin" />
                                <img className="img-fluid" src="/assets/images/tron.png" alt="Coin" />
                                <img className="img-fluid" src="/assets/images/doge.png" alt="Coin" />
                                <img className="img-fluid" src="/assets/images/usdt.png" alt="Coin" />
                                <img className="img-fluid" src="/assets/images/VSYS.png" alt="Coin" />
                                <img className="img-fluid" src="/assets/images/VNDC.png" alt="Coin" />
                                <img className="img-fluid" src="/assets/images/nexo.png" alt="Coin" />
                                <img className="img-fluid" src="/assets/images/link.png" alt="Coin" />
                                <img className="img-fluid" src="/assets/images/mkr.png" alt="Coin" />
                                <img className="img-fluid" src="/assets/images/busd.png" alt="Coin" />
                                <img className="img-fluid" src="/assets/images/usdc.png" alt="Coin" />
                                <img className="img-fluid" src="/assets/images/tusd.png" alt="Coin" />
                                <img className="img-fluid" src="/assets/images/usdp.png" alt="Coin" />
                                <br></br>
                                <img className="img-fluid" src="/assets/images/cc-methods.png" alt="Credit Cards" style={{width: '200px', height: 'auto'}} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={12} className="text-center">
                            <span className="my-3 d-block text-upper">@2023 Coingator.io - All rights reserved</span>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}