import React from 'react';
import {Row, Col, Card} from "react-bootstrap";
import {__, wait, getSingleRandomInt, randomColor, playAudio} from "../../../Helper";
import C from "../../../Constant";
import Canvas from "./Canvas";

class Game extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state ={
            engine: this.props.engine,
            canvas: null,
            interval: []
        }
    }

    componentDidMount(){
        this._isMounted = true;
        let { engine } = this.state;
		
        engine.on(C.PLAY_HASH_DICE, (data) => this.setTimer(data));
		
        var canvas = new Canvas();
            canvas.init();
            
        this.setState({ canvas: canvas })
    }

    componentWillUnmount() {
        this._isMounted = false;

        if(this.state.canvas !== null)
            this.state.canvas.destroy();
    }

    showAnimation(id, result){
        if(this._isMounted)
        {
            playAudio('dice3.mp3');
            let self = this;
            var counter = 150;
            result = result[id] !== undefined ? result[id] : 0;

            clearInterval(this.state.interval[id]);
            
            this.state.interval[id] = setInterval(function() {
                counter--;
                
                let anim = getSingleRandomInt(9)
                self.state.canvas.set(id, anim);

                self.state.canvas.setAnim(randomColor());

                if (counter == 0){
                    self.state.canvas.set(id, result);
                    clearInterval(self.state.interval[id]);
                }
            }, 1);
        }
    }

    setTimer(data){
        if(this._isMounted)
        {
            let target = __.toString(data.target)

            wait(170).then(() => {
                this.showAnimation(0, target)
            })

            wait(270).then(() => {
                this.showAnimation(1, target)
            })

            wait(370).then(() => {
                this.showAnimation(2, target)
            })

            wait(470).then(() => {
                this.showAnimation(3, target)
            })

            wait(570).then(() => {
                this.showAnimation(4, target)
            })
        }
    }

    render() {
        return <>
              <div className="text-center animated fadeIn">
                 <canvas id="hashdicegame" />
              </div>
        </>;
    }
}

export default Game;