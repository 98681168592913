import React, {Component} from "react";
import {Button, Row, Col, Dropdown} from "react-bootstrap";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import storage from "../../../../Storage";
import C from "../../../../Constant";
import {__, wait, isValidNumber, forceSatoshiFormat, User, sendNotfication, Event, playAudio} from "../../../../Helper";
import {gameCoin} from "../../../../actions/gameCoin";
import {setWallet} from "../../../../actions/gameWallet";

class ManualBet extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            engine: this.props.engine,
            buttonText: 'BET',
            inputDisabled: false,
            buttonProgress: false,
            profit: 10,
            risk: 'low',
            chance: 0,
            numbers: [],
            bitError: false,
            outError: false,
            isToggleOn: false,
            lock: false,
            holding: false,
            amount: forceSatoshiFormat(storage.getKey('lam') ? storage.getKey('lam') : .00000010),
            isLogged: storage.getKey("logged") !== null ? true : false,
            coin: storage.getKey('coin')
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleRisk = this.handleRisk.bind(this);
        this.handleBet = this.handleBet.bind(this);
        this.clearTable = this.clearTable.bind(this);
        this.autoPick = this.autoPick.bind(this);
    }

    componentDidMount(){
        let { engine } = this.state;
        this._isMounted = true;

        //Set Coin from Redux
        this.props.gameCoin();

        engine.on(C.PLAY_SINGLE_KENO, () => this.play());
        engine.on(C.BUSTED_SINGLE_KENO, (data) => this.busted(data));
        engine.on(C.ERROR_SINGLE_KENO, (data) => this.error(data));
    }

    error(data) {
        if(this._isMounted){
            if(data.message)
                sendNotfication(data.message, 'info', 'top-center');

            if(data.code === 'credit')
                this.props.setWallet(true, data.uid);

            this.setState({ inputDisabled: false });
        }
    }

    play(){
        if(this._isMounted){
            this.setState({inputDisabled: true});
        }
    }

    busted(data){
        if(this._isMounted){
            let { numbers } = data;
            this.setState({inputDisabled: false});

            if(numbers === undefined) return;

            numbers.forEach((number, i) => {
                if( document.getElementById('c_' + number.num) !== null )
                {
                    wait(i * 100).then(() => {
                        document.getElementById('c_' + number.num).classList.remove('active');
                    });

                    if(this.state.numbers.includes(number.num))
                    {
                        wait(i * 100).then(() => {
                            document.getElementById('c_' + number.num).classList.add('bg-danger');
                            document.getElementById('c_' + number.num).classList.add('text-white');
                            playAudio('dice3.mp3')
                        });
                    }
                    else {
                        wait(i * 100).then(() => {
                            document.getElementById('c_' + number.num).classList.add('bg-success');
                            document.getElementById('c_' + number.num).classList.add('text-white');
                        });
                    }
                }
            });

            wait(2000).then(() => {
                this.clearTable();
            })
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleRisk(value){
        this.setState({ risk: value });
    };

    handleInputChange(event){
        var target = event.target;
        var value = target.type === 'checkbox' ? userAutoPlay(target.checked) : target.value;
        var name = target.name;

        if(__.isEmpty(value)){
            if(name === 'amount')
                this.setState({ bitError: 'red-border' });

            if(name === 'risk')
                this.setState({ outError: 'red-border' });
        }
        else {
            if(name === 'amount')
                this.setState({ bitError: false });

            if(name === 'risk')
                this.setState({ outError: false });
        }

        if(name === 'amount'){
            if(value >= 999999999) return;
        }

        if(!isValidNumber(value)) return;

        if(name === 'amount'){
            storage.setKey('lam', value);
        }

        this.setState({ [name]: value });
    }

    isCorrectBet(amount){
        if(amount === 0)
            return false;

        if(!isValidNumber(amount))
            return false;
        
        if(parseFloat(amount) <= 0){
            this.setState({ amount: forceSatoshiFormat(0.00000010) });
        }
        else
        this.setState({ amount: forceSatoshiFormat(amount) });

        var credit = storage.getKey('credit');

        if(!storage.getKey('playing') === false){
            if (amount > credit || credit === 0) {
                return false;
            }
        }
        return true;
    }

    placeBet(){
        if(this._isMounted){
            let { engine, amount, numbers } = this.state;
            wait(100).then(() => {
                engine.init    = true;
                engine.coin    = this.props.coin;
                engine.numbers = numbers;
                engine.amount  = forceSatoshiFormat(amount) * numbers.length;
                engine.play();
            })
        }
    }

    handleBet(e){
        e.preventDefault();

        let { amount } = this.state;

       // Check User
        if(!this.state.isLogged){
           return Event.emit('showAuthModal', true);
        }

        if(this.state.numbers.length < 3) {
            return sendNotfication('Please select at least 3 number', 'info','top-center');
        }

        // Check is Correct Bet
        if(!this.isCorrectBet(this.state.amount)){
            return sendNotfication('Your Balance is not enough', 'warning','top-center');
        }

        
        if(parseFloat(amount) <= 0){
            this.setState({ amount: forceSatoshiFormat(0.00000010) });
        }
        else {
            amount = Math.min(parseFloat(storage.getKey('credit')), parseFloat(amount));
            this.setState({ amount: forceSatoshiFormat(amount) });
        }

        this.setState({ inputDisabled: true });
        this.placeBet();
    }

    selectNum(num){
        if(this._isMounted){
            if(!this.state.numbers.includes(num)){
                if(this.state.numbers.length < 10)
                    this.setState(state => ({numbers:[num, ...state.numbers]}));
            }
            else {
                const remove = (num) => {
                    return __.without(this.state.numbers, num);
                };
                this.setState(() => ({numbers: remove(num)}));
            }
        }
    }

    clearTable(){
        if(this._isMounted){
            this.setState({ numbers: []});

            for ( var i = 0; i < 41; i++ ) {
                if(document.getElementById('c_' + i) !== null){
                    document.getElementById('c_' + i).classList.remove('bg-success');
                    document.getElementById('c_' + i).classList.remove('text-white');
                    document.getElementById('c_' + i).classList.remove('bg-danger');
                }
            }
        }
    }

    autoPick(){
        if(this._isMounted){
            this.clearTable();
            setTimeout(
                function() {
                    for (var i = 0; i < 19; i++) {
                        let num = getRandomInt(1, 40);
                        if(!this.state.numbers.includes(num)){
                            if(this.state.numbers.length !== 10){
                                this.setState(state => ({numbers:[num, ...state.numbers]}));
                            }
                        }
                    }
                }
                    .bind(this),
                getRandomInt(1, 150)
            );
        }
    }

    render(){
        const makeNumber = (num) => {
            let disabled = false;

            let active = false;

            this.state.numbers.forEach((item, i) => {
                if(item === num){
                    active = "active";
                }
            });

            let button = React.createElement('button',
                {
                    type: "button",
                    id: "c_" + num,
                    disabled: disabled,
                    className: "btn btn-soft-secondary btn-xl btn-block " + active,
                    onClick: () => this.selectNum(num)
                },
                num);
            return React.createElement('div', { className: "keno-numbers"}, button);
        };

        const generateNumber = () => {
            let make = [];
            for ( var i = 1; i < 41; i++ ) {
                make[i] = makeNumber(i);
            }
            return make;
        };

        let {inputDisabled, risk, numbers, bitError, buttonText, amount} = this.state;

        return(
            <>
                <form className="w-100" onSubmit={(e) => {this.handleBet(e)}}>
                    <div className="grid-buttons">
                        {generateNumber()}
                    </div>
                    <Row className="mt-2">
                        <Col md={6} className={'col-6 px-2'}>
                            <button type={"button"} className={"btn btn-outline-light btn-block btn-xs"} disabled={inputDisabled} onClick={this.autoPick}>Auto Pick</button>
                        </Col>
                        <Col md={6} className={'col-6 px-2'}>
                            <button type={"button"} className={"btn btn-outline-light btn-block btn-xs"} disabled={inputDisabled} onClick={this.clearTable}>Clear Table</button>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4} lg={4} className={"m-auto"}>
                            <div className={ "form-group rev mb-1"}>
                                <Button variant={'btn btn-sm btn-block mt-2 btn-bet'}
                                        disabled={inputDisabled}
                                        type="submit">
                                    {buttonText}
                                </Button>
                            </div>
                        </Col>
                        <Col lg={5} sm={12} className={'mt-3'}>
                            <div className={ "form-group bet-input mb-2 " + bitError}>
                                <div className="input-group">
                                    <div className="input-group-append">
                                        <span className="input-group-text">Bet</span>
                                    </div>
                                    <input disabled={inputDisabled} type="text" className="form-control text-left" id="amount" name="amount"
                                           max="900000000" placeholder=".." value={amount} autoComplete={"off"} required={true} onKeyUp={this.handleInputChange}
                                           onChange={this.handleInputChange} />
                                    <div className="input-group-append">
                                        <span className="input-group-text">{this.props.coin}</span>
                                    </div>
                                    <div className="input-group-append">
                                        <span className="input-group-text text-lowercase">x</span>
                                    </div>
                                    <div className="input-group-append">
                                        <span className="input-group-text text-lowercase">{numbers.length}</span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={3} sm={12}  className={'mt-3'}>
                            <div className="input-group fw">
                                <div className="input-group-append">
                                    <span className="input-group-text">Risk</span>
                                </div>
                                <Dropdown className={"drop-select btn-block"}>
                                    <Dropdown.Toggle split variant="" className={'py-0 mt-0'} >
                                                    <span className="text-muted text-center">
                                                        <i className={'mdi mdi-chevron-down mr-1'} />
                                                        {risk}
                                                     </span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu disabled={inputDisabled} className={"dopdown-menu-bottom " + inputDisabled }>
                                        <Dropdown.Item onClick={e => this.handleRisk('low')} className={'animated fadeIn'}>Low</Dropdown.Item>
                                        <Dropdown.Item onClick={e => this.handleRisk('medium')} className={'animated fadeIn'}>Medium</Dropdown.Item>
                                        <Dropdown.Item onClick={e => this.handleRisk('high')} className={'animated fadeIn'}>High</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Col>
                    </Row>
                </form>
            </>
        );
    }
}

function userAutoPlay(select){
    User['autoplay'] = select;
}

function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

ManualBet.propTypes = {
    coin: PropTypes.string
};

const mapStateToProps = state => ({
    coin: state.items.coin
});

export default connect(mapStateToProps, { gameCoin, setWallet })(ManualBet);