import React from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import {Dropdown, Row, Col} from "react-bootstrap";
import {Helmet} from "react-helmet";
import Slots from "./Slots";

class Index extends React.Component {
    constructor(props){
        super(props);
        this.state = {
        	search: ''
        }
    }

    componentDidMount(){
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
    
    change = (e) => {
    	let value = e.target.value;
    	this.setState({ search: value });
    }

    render() {
        return <>
            <Helmet>
                    <title>Slot Games - Crypto Casino Games</title>
                    <meta name="keywords" content="Crypto Slot Games, Online Slot Games, Bitcoin Slot Games, Blockchain Slot Games, Best Crypto Slot Games, Coingator.io Slot Games"/>
                    <meta name="description" content="Play Online Slot Games - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <meta name="og:title" content="Play Online Slot Games - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <meta name="og:description" content="Play Online Slot Games - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <link rel="canonical" href="https://Coingator.io/slots" />
            </Helmet>
             <ReactCSSTransitionGroup
             	transitionAppear={true}
             	transitionAppearTimeout={250}
             	transitionEnterTimeout={200}
             	transitionLeaveTimeout={200}
             	transitionName={'FadeIn'}
             >
        	<Row className="mb-2">
        		<Col className="col-12" md="3">
        			<div className="text-left mt-2">
        				<input type="search" className="form-control bge py-0" placeholder="Search Game ..." value={this.state.search} onChange={this.change} />
                        <div className="search2">
                            <i className="fas fa-search" />
                        </div>
        			</div>
        		</Col>
        	</Row>
        	<hr className="side-border w-100" />
        		<div id="st">
                    <h4 className="mt-2 mb-2 section-title"> Recommend Games</h4>
                    <Slots req={true} search={null} />
                    <h4 className="mt-2 mb-2 section-title"> All Games</h4>
                    <Slots search={this.state.search} />
        		</div>
			</ReactCSSTransitionGroup>
        </>
    }
}

export default Index;