import React from 'react';
import {Row, Col, Card} from "react-bootstrap";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import BankRoll from "../../Components/Game/BankRoll";
import Queue from "../../Components/Game/Queue";
import TopBarGames from "../../Components/Game/Addons/TopBar";
import Engine from "./Engine";
import Game from "./Game";
import Bet from "./Bet";
import {isMobile, wait, Event} from "../../../Helper";
import {Helmet} from "react-helmet";

class Index extends React.Component {
    _Mounted = false;
    constructor (props){
        super(props);
        this.state = {
            engine: new Engine(),
            padding: "p-1"
        };
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        this._Mounted = true;
        let { engine } = this.state;
        
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        // Start Engine
        engine.started = true;

        wait(600).then(() => {
            this.handleResize();
        });
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
        this._Mounted = false;

        let { engine } = this.state;

        // Stop Engine
        engine.started = false;
        engine.trigger = null;
        engine.off()
    }

    handleResize(){
        let clientWidth = document.documentElement.clientWidth || document.body.clientWidth;

        if(clientWidth < 1540)
        {
            this.setState({ col: 'col-xl-12'});
            Event.emit('hide_games');
        }
        else {
            this.setState({ col: 'col-xl-9'});
            Event.emit('show_min_games');
        }

        if(isMobile()){
            this.setState({ padding: 'p-0', ovh: 'ovh' });
        }
    }

    render() {
        let { col, padding, ovh } = this.state;
        const help = 'The objective of the Crypto Limbo Game is pretty simple. You will set your payout amount and click bet. If the rocket x multiplier goes above your payout amount you win, if the rocker x multiplier goes below your payout amount you lose the bet. Example: If you choose 2.00 as your payout, and the rocket finishes at 2.35 you win. If the rocket finishes at 1.98 you lose.';
        return (
            <>
                <Helmet>
                    <title>Limbo - Crypto Casino Games</title>
                    <meta name="keywords" content="Crypto Limbo Game, Online Limbo Game, Bitcoin Limbo Game, Blockchain Limbo Game, Best Crypto Limbo Game, Coingator.io Limbo"/>
                    <meta name="description" content={help} />
                    <meta name="og:title" content="Play Online Limbo - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <meta name="og:description" content="Play Online Limbo - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <link rel="canonical" href="https://Coingator.io/limbo" />
                </Helmet>
                <ReactCSSTransitionGroup
                    transitionAppear={true}
                    transitionAppearTimeout={250}
                    transitionEnterTimeout={200}
                    transitionLeaveTimeout={200}
                    transitionName={'FadeIn'}
                >
                    {this._Mounted ?
                    <Row className={ "animated fadeIn " + ovh}>
                        <div className="d-none">Crypto Limbo Game Description: {help}</div>
                        <Col sm={12} className={'m-auto ' + col + ' ' + padding}>

                            {/* TOP MENUS */}
                            <Card className="mb-0">
                                <Card.Body className="px-2 py-3">
                                    <Row>
                                        <Col md={6} className={'col-6 text-left'}>
                                            <BankRoll game={'limbo'}/>
                                        </Col>
                                        <Col md={6} className={'col-6 text-right'}>
                                            <div className="btn-group btn-group-toggle">
                                                <TopBarGames help={help} image="/assets/images/covers_new/__limbo.png" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                            {/* GAME */}
                            <Card className="mb-0">
                                <Card.Body className={'bg-dice-options p-1'} id="roll-panel">
                                    <Row>
                                        <Col sm={12} md={12} className={'m-auto'}>
                                             <Game engine={this.state.engine} />
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                            {/* BET */}
                            <Card className="mb-0">
                                <Card.Body className={padding}>
                                    <Row>
                                        <Col sm={12} md={12} className={'m-auto'}>
                                           <Bet engine={this.state.engine} />
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                            {/* QUEUE */}
                            <Card className="mb-5">
                                <Card.Body className="p-1">
                                    <Row>
                                        <Col sm={12} md={12} className={'m-auto px-1'}>
                                            <Queue t={this.props.t} game={'limbo'}/>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                        </Col>
                    </Row>
                    :
                    <div className="game-loader text-center">
                        <div className="spinner-grow text-info my-3" role="status"/>
                    </div>
                    }
                </ReactCSSTransitionGroup>
            </>
        );
    }
}

export default Index;