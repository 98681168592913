import React, { Component } from 'react'
import { Modal, Row, Col } from "react-bootstrap";
import { wait, sendNotfication, randomString } from "../../../../../Helper";
import Auth from "../../../../../Auth";

class Seed extends Component{
    constructor(props) {
        super(props);
        this.state = {
            seed: randomString(64),
            newSeed: randomString(64),
            clientSeed: randomString(16),
            newClientSeed: randomString(16),
            nonce: 0,
            newNonce: 0,
            show: false,
            loading: false
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose(){
        this.setState({ show: false, effect: 'zoomOut' });
    }

    handleShow(){
        if(!Auth()){
            return sendNotfication("You must be logged !", "warning", "top-center");
        }
        this.setState({ show: true, effect: 'zoomIn' });
    }

    submit = (e) => {
        e.preventDefault();
        if(!this.state.seed) return;
        if(this.state.seed.length < 9) return;
        this.setState({ loading: true });

        wait(2500)
            .then(() => {
                sendNotfication('New Seed Saved.', 'success', 'top-right');
                this.setState({ loading: false, show: false, effect: 'zoomOut' });
            })
    };

    generate = (e) => {
        e.preventDefault();
        this.setState({ newClientSeed: randomString(16) })
    };

    render() {
        return(
            <>
                <button onClick={this.handleShow} className={"btn btn-sm bg-cs2 cpt mr-1 animated fadeInDown font-15"} data-tip="Seed">
                    <i className={'mdi mdi-package-down font-15'} />
                </button>
                <Modal
                    size="md"
                    centered={true}
                    backdrop="static"
                    show={this.state.show}
                    onHide={this.handleClose}
                    aria-labelledby="wallet-lg-modal"
                    className={"animated " + this.state.effect}
                >
                    <Modal.Header>
                        Seed
                        <button type="button" className="close p-2" onClick={this.handleClose}>
                            <i className={'mdi mdi-close'}/>
                        </button>
                    </Modal.Header>
                    <Modal.Body closeButton>
                        <p className={'text-warning'}>
                            Using this function can set up a new server client seed + a new client seed, <br/>
                            which can be randomly generated or customized (at least for 10 characters), and bets number will reset to zero.
                        </p>

                        <form onSubmit={e=>this.submit(e)}>
                            <h5>Current Seed</h5>

                            <div className="form-group">
                                <label htmlFor="">Server Seed / HASH</label>
                                <input readOnly={true} type="text" name={'seed'} className={'form-control'} placeholder={'...'} value={this.state.seed}/>
                            </div>

                            <Row>
                                <Col md={7} sm={12}>
                                    <div className="form-group">
                                        <label htmlFor="">CLIENT SEED</label>
                                        <input readOnly={true} type="text" name={'seed'} className={'form-control'} placeholder={'...'} value={this.state.clientSeed}/>
                                    </div>
                                </Col>
                                <Col md={5} sm={12}>
                                    <div className="form-group">
                                        <label htmlFor="">NONCE</label>
                                        <input readOnly={true} type="number" name={'seed'} className={'form-control'} placeholder={'0'} value={this.state.nonce}/>
                                    </div>
                                </Col>
                            </Row>

                            <hr/>

                            <h5>New Seeds</h5>

                            <div className="form-group">
                                <label htmlFor="">Server Seed / HASH</label>
                                <input type="text" name={'seed'} className={'form-control'} placeholder={'...'}  value={this.state.newSeed} onChange={ e => this.setState({ newSeed: e.target.value }) }/>
                            </div>

                            <Row>
                                <Col md={7} sm={12}>
                                    <div className="form-group">
                                        <label htmlFor="">CLIENT SEED</label>
                                        <div className="input-group">
                                            <input type="text" name={'seed'} className={'form-control'} placeholder={'...'}  value={this.state.newClientSeed} onChange={ e => this.setState({ newClientSeed: e.target.value }) }/>
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    <button className={'btn btn-tr'} onClick={this.generate}>
                                                        <i className={'mdi mdi-image-filter-tilt-shift'} />
                                                    </button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={5} sm={12}>
                                    <div className="form-group">
                                        <label htmlFor="">NONCE</label>
                                        <input readOnly={true} type="number" name={'seed'} className={'form-control'} placeholder={'0'}  value={this.state.newNonce}/>
                                    </div>
                                </Col>
                            </Row>
                            <div className="form-group text-center">
                                <button className={'mt-2 btn btn-s-2 btn-block'}>
                                    {!this.state.loading &&
                                    <>Submit</>
                                    }
                                    {this.state.loading &&
                                    <><div className="spinner-border spinner-border-sm" role="status" /></>
                                    }
                                </button>
                            </div>
                        </form>

                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

export default Seed;