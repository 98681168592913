import React, { Component } from "react";
import { Link } from "react-router-dom";
import {Helmet} from 'react-helmet'
import Games from "./Games";
import Bets from "./Bets";
import Slider from "./Slider";
import Winners from "../../../Winners";
import Slots from "../../../Slots/Slots";

class Home extends Component {
    _isMounted = false;

    componentWillMount() {
        this._isMounted = true;
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render(){
        let { content, t } = this.props;
        return (
            <>
                { this._isMounted &&
                    <>
                    <Helmet>
                        <title>Coingator - Crypto Casino Games, Slot Games and Crypto Gambling</title>
                        <meta name="keywords" content="Crypto Casino Games, Online Casino Games, Slot Games, Bitcoin Crash Game, Dice Games, Live Casino Games, Crypto Casino Games, Blockchain Games, Bitcoin Casino Games, Best Crypto Online Game, Crypto Gambling Games, coingator.io Crypto Games"/>
                        <meta name="description" 
                        content="coingator.io is the crypto casino games and gambling games. Supports More than 17 Crypto Coins. Fast Withdrawl, provably fair, and Hot wallet." />
                        <meta name="og:title" content="coingator.io is the crypto casino games and gambling games. Supports More than 17 Crypto Coins. Fast Withdrawl, provably fair, and Hot wallet." />
                        <meta name="og:description" content="coingator.io is the crypto casino games and gambling games. Supports More than 17 Crypto Coins. Fast Withdrawl, provably fair, and Hot wallet." />
                        <link rel="canonical" href="https://coingator.io" />
                    </Helmet>
                    {content}
                    <div className="home-slider hidden-sm">
                        <Slider t={t} />
                    </div>
                    <div className="mt-1">
                        <h4 className="mt-2 mb-2 section-title"> Coingator {t('originals')}</h4>
                        <Games t={t} />
                        <h4 className="mt-2 mb-2 section-title"> Slots</h4>
                        <Slots home={true} />
                        <div className="text-center">
                            <Link className="btn bg-cs btn-sm px-4 mt-2" to="/slots">
                                <i className="mdi mdi-arrow-right-bold-box align-middle" /> Show More
                            </Link>
                        </div>
                    </div>
                    <div className="mt-0 mb-3">
                        <Bets t={t} />
                    </div>
                    <div className="mt-4">
                        <h4 className={'mb-3 mt-0 section-title'}>
                            Top Winners
                        </h4>
                        <Winners t={t} />
                    </div>
                    </>
                }
            </>
        );
    }
}

export default Home;