/* eslint-disable */
import Card from "./Card/Card";
import CardBack from "./Card/Card/Back";
import CardHold from "./Card/Card/Hold";
import CardHover from "./Card/Card/Hover";
import {isMobile, wait, getRandomBetween, playAudio, Event, __} from "../../../../Helper";

let loaded = false;

function Canvas(engine) {
    let self = this;

    self.engine = engine;

    let CARDS_BACK = ['./assets/images/video-poker/cards/back2.svg', './assets/images/video-poker/cards/back3.svg'];
	
    self.ASSETS = [CARDS_BACK];
    
    self.pixi = PIXI;
    self.app = null;
    self.container = null;
    self.bet = false;
	self.dealer = false;
	self.busted = false;
	self.anim = false;
    self.card_back = [];
	self.cards = [];
	self.holds = [];
	self.deal_cards = [];
	self.holding_cards = [];
	self.result = [];
	self.hand = [];
	self.hovers = [];
	self.notClick = false;
	self.last_card = 4;

    self.destroy = function(){
        self.app = null;
    }
	
    self.init = function () {
        self.app = new self.pixi.Application({
			label: "VIDEO_POKER",
            destroy: true,
            autoStart: false,
            renderer: "canvas",
            width: 700,
            height: 250,
            antialias: true,
            transparent: true,
            resolution: window.devicePixelRatio || 1,
            view: document.getElementById("game")
        });
		
		self.app.stage.sortableChildren = true;

        if(isMobile())
        {
            self.app.renderer.autoDensity = true;
            self.app.renderer.resize(320, 200);
        }

        if(!loaded){
            loaded = true;

            let images = [];
            for (var i = 0; i < 54; i++) {
                images.push('/assets/images/video-poker/cards/' + i + '.png')
            }

            let assets = self.ASSETS.concat(images)

            self.pixi.loader.onProgress.add(self.loadProgressHandler)
            self.pixi.loader.add(assets).load(self.start);
        }
        else {

        	//fix for return to game
    		Event.emit('loading_videopoker', 99)
        }
    };

    self.loadProgressHandler = function(loader, resource){
    	Event.emit('loading_videopoker', parseFloat(loader.progress).toFixed(0))
    }

    self.start = function(){
        const container = new self.pixi.Container();
        self.container = container;
		container.sortableChildren = true;
        self.app.stage.addChild(container);
		self.busted = false;
		self.notClick = false;

		for(var i = 0; i < 5; i++){
			self.addCardBack(i);
		}

        //Start Render Canvas
        self.app.start();
		
		// Start Animations
        self.app.ticker.add((delta) => {
			if(self.bet)
			{
				for(var i = 0; i < 5; i++){
					let lastCard = self.card_back[self.last_card]
						
					wait(1 * self.last_card).then(() => {
						lastCard.scale.x -= 0.105 * delta;
						
						if(lastCard.scale.x < -1)
						{
							lastCard.scale.x += 0.105 * delta;
							if(!self.cards[self.last_card])
								self.addCard(self.last_card);
							
							--self.last_card;
							
							if(self.last_card < 0){
								self.last_card = 4
								self.bet = false;
							}
						}
					})
				}
			}
			if(self.dealer)
			{
                for (var i = 0; i < 5; i++) {
                    if (!_.includes(self.holding_cards, i)) {
                        let lastCard = self.cards[i]
                        if (lastCard) {
                            lastCard.scale.x -= 0.105 * delta;

                            if (lastCard.scale.x < -1) {
                                lastCard.scale.x += 0.105 * delta;

                                if (!self.deal_cards[self.last_card])
                                    self.addDealCard(self.last_card);

                                --self.last_card;

                                if (self.last_card < 0) {
                                    self.last_card = 4
                                    self.dealer = false;
                                }
                            }
                        }
                    }
                }
			}
		})
    }
	
	self.addCardBack = function(i){
		let space = isMobile() ? 35 : 60;
		let x = (self.app.screen.width / 5 * i + space) 
		let y = self.app.screen.height / 2;
		let c = CARDS_BACK[getRandomBetween(0, CARDS_BACK.length) % CARDS_BACK.length];
		let cb = new CardBack(i, c, x, y)
		cb.add();
		self.card_back[i] = cb.cards[i]
		self.container.addChild(self.card_back[i]);

		if(isMobile()){
			self.card_back[i].width = 55
			self.card_back[i].height = 80
		}

		if(!isMobile()){
			var d = 0;
			var anim = setInterval(function () {
				d += 0.1
				self.card_back[i].scale.x = d;
				
				if(d >= 1){
					self.card_back[i].scale.x = 1;
					clearInterval(anim);
				}
			}, 10);
		}
    }
	
	self.addCard = function(i){
		let space = isMobile() ? 30 : 60;
		let x = (self.app.screen.width / 5 * i + space)
		let y = self.app.screen.height / 2;
		let c = self.hand[i];
		let card = new Card(c, x, y)
		card.add();
		self.cards[i] = card.card;
		if(!self.notClick){
			self.cards[i].on("click", (e) => self.setHold(i));
			self.cards[i].on("touchstart", (e) => self.setHold(i));
			self.cards[i].on("pointerover", (e) => self.onHover(i));
			self.cards[i].on("pointerout", (e) => self.onOut(i));
		}
		self.container.removeChild(self.card_back[i]);
		self.container.addChild(self.cards[i]);

		if(isMobile()){
			self.cards[i].width = 55
			self.cards[i].height = 80
		}
    }
	
	self.addDealCard = function(i){
		if(!_.includes(self.holding_cards, i)) {
			playAudio('dice3.mp3')
			let space = isMobile() ? 30 : 60;
			let x = (self.app.screen.width / 5 * i + space)
			let y = self.app.screen.height / 2;
			let c = self.hand[i];
			let card = new Card(c, x, y)
			card.add();
			self.deal_cards[i] = card.card;
			self.container.removeChild(self.cards[i]);
			self.container.addChild(self.deal_cards[i]);

			if(isMobile()){
				self.deal_cards[i].width = 55
				self.deal_cards[i].height = 80
			}
		}
    }
	
	self.setHold = function(i){
		playAudio('dice.mp3')
		if(self.holds[i])
		{
			_.remove(self.holding_cards, function(n) {
			  return n === i;
			});

			self.cards[i].removeChild(self.holds[i]);
			self.holds[i] = null;
			
			if(!isMobile()){
				var c = 2;
				var anim = setInterval(function () {
					c -= 0.1
					var s = 0.01 * c / 2;
					self.cards[i].scale.set(self.cards[i].scale.x - Math.sin(s) );
					if(c < 0)
						clearInterval(anim);
				}, 10);
			}
		}
		else {
			self.holding_cards.push(i);
			
			let x = -self.cards[i].width / 2
			let y = -self.cards[i].height / 2
			let ch;

			if(isMobile()){
				ch = new CardHold(x - 23, y - 35, self.cards[i].width * 2 - 10, self.cards[i].height * 2 - 10)
			}
			else {
				ch = new CardHold(x, y, self.cards[i].width, self.cards[i].height)
			}

			ch.add();

			self.holds[i] = ch.hold;

			self.cards[i].addChild(self.holds[i]);

			if(!isMobile()){
				var c = 0;
				var anim = setInterval(function () {
					c += 0.1
					var s = 1 + Math.sin(c) * 0.1
					self.cards[i].scale.set(s);
					
					if(c > 1.0)
						clearInterval(anim);
					
				}, 10);
			}
		}

		let card = self.hand[i]

    	self.engine.emit('toggle_card_hold', { card: card, pos: i, hold: self.holds[i] ? true : false })
    }
	
	self.onOut = function(i){
		self.cards[i].removeChild(self.hovers[i]);
		self.hovers[i] = null
	}
	
	self.onHover = function(i){
			self.cards[i].removeChild(self.hovers[i]);
			let x = -self.cards[i].width / 2
			let y = -self.cards[i].height / 2
			let ch = new CardHover(x, y, self.cards[i].width, self.cards[i].height)
			ch.add();
			self.hovers[i] = ch.hover;
			self.cards[i].addChild(self.hovers[i]);
	}
	
    self.play = function(hand){
		playAudio('dice2.mp3')
    	self.hand = hand;
		self.bet = true;
    }
	
    self.deal = function(hand){
    	self.hand = hand;
		self.dealer = true;
		self.notClick = true;
		wait(3000).then(() => {
			self.busted = true;
			self.card_back = [];
			self.last_card = 4;
			for(var i = 0; i < 5; i++){
				if(self.cards[i]){
					self.cards[i].removeChild(self.holds[i]);
					self.container.removeChild(self.deal_cards[i]);
					self.container.removeChild(self.cards[i]);
				}
			}
			self.cards = [];
			self.holds = [];
			self.deal_cards = [];
			self.holding_cards = [];
			self.bet = false;
			clearInterval(self.anim);
			self.app.ticker.stop();
			self.app.stage.removeChild(self.container);
			self.destroy();
			self.init();
			self.start();
		})
    }
}

export default Canvas;