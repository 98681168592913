import React from 'react';
import {Row, Col, Card} from "react-bootstrap";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import {Helmet} from "react-helmet";
import TopBarGames from "../../Components/Game/Addons/TopBar";
import BankRoll from "../../Components/Game/BankRoll";
import Queue from "../../Components/Game/Queue";
import {wait, isMobile, Event} from "../../../Helper";
import Game from "./Game";
import Bet from "./Bet";
import Engine from "./Engine";

class Main extends React.Component {
    _Mounted = false;
    constructor (props){
        super(props);
        this.state = {
            engine: new Engine(),
            padding: "p-1"
        };
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        let { engine } = this.state;
        
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        // intialize Engine
        engine.intialize();

        wait(600).then(() => {
            this.handleResize();
        });
        window.addEventListener('resize', this.handleResize);
    }

    componentWillMount() {
        wait(500).then(() => {
            this._Mounted = true;
        })
    }

    componentWillUnmount() {
        let { engine } = this.state;
        window.removeEventListener('resize', this.handleResize);
        this._Mounted = false;

        // Stop Engine
        engine.stop();
    }

    handleResize(){
        let clientWidth = document.documentElement.clientWidth || document.body.clientWidth;

        if(clientWidth < 1540)
        {
            this.setState({ col: 'col-xl-12'});
            Event.emit('hide_games');
        }
        else {
            this.setState({ col: 'col-xl-9'});
            Event.emit('show_min_games');
        }

        if(isMobile()){
            this.setState({ padding: 'p-0', ovh: 'ovh' });
        }
    }

    render() {
        let { col, padding, ovh } = this.state;

        const help = "Crypto Wheel is a game of chance and will score you a win if the arrow lands on the color you choose. Players can change the “Risk” value from Default to High to multiply their winnings and have a chance at earning even bigger!";
        
        return (
            <>
                <Helmet>
                    <title>Lucky Wheel - Crypto Casino Games</title>
                    <meta name="keywords" content="Crypto Lucky Wheel Game, Online Lucky Wheel Game, Bitcoin Lucky Wheel Game, Blockchain Lucky Wheel Game, Best Crypto Wheel Game, Coingator.io Lucky Wheel"/>
                    <meta name="description" content={help} />
                    <meta name="og:title" content="Play Online Lucky Wheel - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <meta name="og:description" content="Play Online Lucky Wheel - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <link rel="canonical" href="https://Coingator.io/wheel" />
                </Helmet>
                <ReactCSSTransitionGroup
                    transitionAppear={true}
                    transitionAppearTimeout={250}
                    transitionEnterTimeout={200}
                    transitionLeaveTimeout={200}
                    transitionName={'SlideIn'}
                >
                    {this._Mounted ?
                    <Row className={ "animated fadeIn " + ovh}>
                        <div className="d-none">Crypto Wheel Game Description: {help}</div>
                        <Col sm={12} className={'m-auto ' + col + ' ' + padding}>

                            {/* BANKROLL */}
                            <Card className="mb-0">
                                <Card.Body className="px-2 py-3">
                                    <Row>
                                        <Col md={6} className={'col-6 text-left'}>
                                            <BankRoll game={'wheel'}/>
                                        </Col>
                                        <Col md={6} className={'col-6 text-right'}>
                                            <TopBarGames help={help} image="/assets/images/covers_new/wheel.png" />
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                            {/* GAME */}
                            <Card className="mb-0">
                                <Card.Body className={'bg-dice-options p-1'} id="roll-panel">
                                    <Row>
                                        <Col sm={12} md={10} className={'m-auto'}>
                                             <Game engine={this.state.engine} />  
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                            {/* BET */}
                            <Card className="mb-0">
                                <Card.Body className={padding}>
                                    <Row>
                                        <Col sm={12} md={10} className={'m-auto'}>
                                           <Bet engine={this.state.engine} />
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                            {/* QUEUE */}
                            <Card className="mb-5">
                                <Card.Body className="p-1">
                                    <Row>
                                        <Col sm={12} md={12} className={'m-auto px-1'}>
                                            <Queue t={this.props.t} game={'wheel'}/>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                        </Col>
                    </Row>
                    :
                    <div className="game-loader text-center">
                        <div className="spinner-grow text-purple text-info my-3" role="status"/>
                    </div>
                    }
                </ReactCSSTransitionGroup>
            </>
        );
    }
}

export default Main;