import React from 'react';
import ReactDOM from "react-dom";
import {Button, Card, Col, Dropdown, Row} from "react-bootstrap";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import RangeCredit from "../../Components/Game/Addons";
import { __, wait, decode, isValidNumber, getElement, forceSatoshiFormat, sendNotfication, Event, playAudio } from "../../../Helper";
import {gameCoin} from "../../../actions/gameCoin";
import {setWallet} from "../../../actions/gameWallet";
import storage from "../../../Storage";
import Wheel from "./components/Wheel";
import C from "../../../Constant";
import "./components/Lib/myWheel";

const SPIN_BUTTON = "spin-to-win";
var HASH = false;

class Game extends React.Component {
    _isMounted = false;
    constructor (props){
        super(props);
        this.state = {
            engine: this.props.engine,
            started: false,
            bet: 'manual',
            isLogged: storage.getKey("logged") !== null ? true : false,
            playing: false,
            buttonText: 'BET',
            risk: 3,
            riskName: 'Default',
            amount: forceSatoshiFormat(storage.getKey('lam') ? storage.getKey('lam') : .00000010),
            segment: 11,
            game_status: null,
            lock: false,
            bitError: false,
            outError: false,
            inputDisabled: false,
            betNumber: 10,
            firstBetNumber: 10,
            onWin: 0,
            onLost: 0,
            stopOnWin: 0,
            wheelStart: null
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleBet = this.handleBet.bind(this);
    }

    componentWillUnmount() {
        let { engine, started } = this.state;
        this._isMounted = false;

        engine.init = false;

        try {
            if(started){
                document.getElementById('bet').click()
            }
        }
        catch (e) {}
    }

    componentDidMount(){
        let { engine } = this.state;
        this._isMounted = true;

        engine.on(C.PLAY_WHEEL, (data) => this.play(data));
        engine.on(C.BUSTED_WHEEL, (data) => this.busted(data));
        engine.on(C.ERROR_WHEEL, (data) => this.error(data));
        engine.on("stop_wheel", () => this.stopAuto());
        engine.on("stop_playing", () => this.stopManual());

        //Get Redux Coin
        this.props.gameCoin();
    }
    
    stopManual() {
        if(this._isMounted)
        {
            this.setState({ started: false, buttonText: 'BET', inputDisabled: false });
        }
    }

    stopAuto() {
        if(this._isMounted)
        {
            this.setState({ started: false, buttonText: 'Start Auto Mode', inputDisabled: false });
        }
    }

    error(data) {
        if(this._isMounted)
        {
            sendNotfication(data.message, 'info', 'top-center');

            if(data.code === 'credit')
                this.props.setWallet(true, data.uid);

            this.setState({inputDisabled: false});
            
            if(this.state.started){
                this.setState({ started: false, buttonText: 'Start Auto Mode' });
            }
        }
    }

    play(data){
        if(this._isMounted)
        {
            this.setState({ hash: data.hash, game_status: 'started', lock: true, playing: true, inputDisabled: true, playing: true });
            getElement('.' + SPIN_BUTTON).click();
            playAudio('wheel2.mp3')
            wait(2000).then(() => {
                playAudio('wheel2.mp3')
            })
        }
    }

    busted(data){
        if(this._isMounted)
        {
            let { profit } = data;
            let { engine, bet, started } = this.state;

            let isWin = false;

            if(parseFloat(profit) !== 0){
                isWin = true;
            }

            wait(100)
                .then( () => {
                    this.setState({ lock: false, playing: false, inputDisabled: false, playing: false });

                    if(bet === 'auto')
                    {
                        if(started)
                        {
                            wait(250)
                                .then( () => {
                                    engine.isWin = isWin;
                                    engine.roundProfit = __.toNumber(profit) + __.toNumber(engine.roundProfit);
                                    this.placeAutoBet();
                                });
                        }
                    }
                    else {
                        engine.init = false;
                    }
                })

                if(isWin === true)
                    playAudio('win.mp3')
        }
    }

    placeAutoBet(){
        if(this._isMounted)
        {
            let { engine, amount, betNumber, firstBetNumber, onWin, onLost, stopOnWin, segment, risk } = this.state;
            engine.init = true;
            engine.coin = this.props.coin;
            engine.amount = amount;
            engine.segment = segment;
            engine.risk = risk;
            engine.betNumber = firstBetNumber;
            engine.stopOnWin = parseInt(stopOnWin);
            engine.onWin = parseInt(onWin);
            engine.onLost = parseInt(onLost);
            engine.autoStart();

            this.setState({ betNumber: betNumber - 1 });
        }
    }

    placeBet(){
        if(this._isMounted)
        {
            let { engine, amount, segment, risk } = this.state;
            engine.init = true;
            engine.coin = this.props.coin;
            engine.amount = amount;
            engine.segment = segment;
            engine.risk = risk;
            engine.start();
        }
    }

    isCorrectBet(amount){
        if(amount.trim() === '')
            return false;

        if(!isValidNumber(amount))
            return false;
        
        if(parseFloat(amount) <= 0){
            this.setState({ amount: forceSatoshiFormat(0.00000010) });
        }
        else {
            amount = Math.min(parseFloat(storage.getKey('credit')), parseFloat(amount));
            this.setState({ amount: forceSatoshiFormat(amount) });
        }

        return true;
    }

    handleBet(e){
        e.preventDefault();

       // Check User
        if(!this.state.isLogged){
           return Event.emit('showAuthModal', true);
        }

        // Check is Correct Bet
        if(!this.isCorrectBet(this.state.amount))
            return false;

        this.setState({ inputDisabled: true });

        wait(100)
            .then( () => {
                if(this.state.bet === 'auto')
                {
                    if(this.state.started)
                    {
                        this.state.engine.init = false;
                        this.setState({ started: false, buttonText: 'Start Auto Mode', inputDisabled: false, });
                        return;
                    }
                    else {
                        this.setState({ started: true, buttonText: 'Stop Auto Mode' });
                        this.placeAutoBet();
                    }
                }
                else
                {
                    if(this.state.playing !== true)
                    {
                        // Check for Double request
                        if(this.state.lock === true)
                            return false;

                        this.setState({ inputDisabled: false });
                        this.placeBet();
                    }
                }
            })
    }

    handleInputChange(event){
        var target = event.target;
        var value  = target.type === 'checkbox' ? true : target.value;
        var name   = target.name;

        if(__.isEmpty(value)){
            if(name === 'amount')
                this.setState({ bitError: 'red-border' });
        }
        else {
            if(name === 'amount')
                this.setState({ bitError: false });
        }

        if(name === 'amount' || name === 'stopOnWin'){
            if(!isValidNumber(value)) return;
        }
        
        if(name === 'amount'){
            storage.setKey('lam', value);
        }

        this.setState({ [name]: value });
    }

    handleRisk = value => {
        if(this._isMounted){
            if(this.state.inputDisabled)
                return;

            this.setState({ risk: value });

            let name;
            if(value === 3) name = "Default";
            if(value === 2) name = "High";
            this.setState({ riskName: name });

            wait(10)
                .then( () => {
                    this.state.engine.emit('wheel_segment', {segment: this.state.segment, risk: value });
                })
        }
    };

    handleSegment = value => {
        if(this._isMounted){
            if(this.state.inputDisabled)
                return;

            this.setState({ segment: value });

            wait(10)
                .then( () => {
                    this.state.engine.emit('wheel_segment', {segment: value, risk: this.state.risk });
                })
        }
    };

    betMode = (type) => {
        let text;

        if(type === 'auto'){
            text = 'Start Auto Mode';
        }
        else {
            text = 'BET';
        }
        this.setState({ bet: type, buttonText: text });
    };
    
    setAmount = (val) => {
        var am = forceSatoshiFormat(val);
        this.setState({ amount: am });
        storage.setKey('lam', am );
    }

    setNumber = (e, num) => {
        e.preventDefault();
        this.setState({ betNumber: num, firstBetNumber: num });
    };

    render() {
        let {inputDisabled, selectedOption, bet, started, segment, riskName} = this.state;

        return(
            <>
                            <form className="w-100 mt-1" onSubmit={(e) => {this.handleBet(e)}}>
                                <Row>
                                    <Col xl={6} md={8} sm={12}>
                                        <div className={"form-group bet-input cashout mb-1 " + this.state.bitError}>
                                            <div className="input-group">
                                                <div className="input-group-append">
                                                    <span className="input-group-text">
                                                        <img src={'/assets/images/' + this.props.coin + '.png'} className={'mini-coin-7'} alt=""/>
                                                        BET
                                                    </span>
                                                </div>
                                                <input
                                                    type="text"
                                                    disabled={inputDisabled}
                                                    className="form-control"
                                                    id="amount" name="amount"
                                                    value={this.state.amount}
                                                    autoComplete={"off"}
                                                    onKeyUp={this.handleInputChange}
                                                    onChange={this.handleInputChange} />
                                                <div className="input-group-append">
                                                    <RangeCredit set={this.setAmount} />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xl={3} md={4} sm={12}>
                                        <div className={"form-group mb-1"}>
                                            <div className="input-group fw">
                                                <div className="input-group-append">
                                                    <span className="input-group-text">RISK</span>
                                                </div>
                                                <Dropdown className={"drop-select btn-block"}>
                                                    <Dropdown.Toggle split variant="" className={'py-0 mt-0'} >
                                                     <span className="text-muted text-center">
                                                        <i className={'mdi mdi-chevron-down mr-1'} />
                                                         {riskName}
                                                     </span>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu disabled={inputDisabled} className={"dopdown-menu-bottom"}>
                                                        <Dropdown.Item onClick={e => this.handleRisk(3)} className={'animated fadeIn'}>Default</Dropdown.Item>
                                                        <Dropdown.Item onClick={e => this.handleRisk(2)} className={'animated fadeIn'}>High</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xl={3} md={12} sm={12}>
                                        <div className={"form-group mb-1"}>
                                            <div className="input-group fw">
                                                <div className="input-group-append">
                                                    <span className="input-group-text">SEGMENT</span>
                                                </div>
                                                <Dropdown className={"drop-select btn-block"}>
                                                    <Dropdown.Toggle split variant="" className={'py-0 mt-0'} >
                                                    <span className="text-muted text-center">
                                                        <i className={'mdi mdi-chevron-down mr-1'} />
                                                        {segment}
                                                    </span>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className={"dopdown-menu-bottom"}>
                                                        <Dropdown.Item onClick={e => this.handleSegment(8)} className={'animated fadeIn'}>7</Dropdown.Item>
                                                        <Dropdown.Item onClick={e => this.handleSegment(11)} className={'animated fadeIn'}>10</Dropdown.Item>
                                                        <Dropdown.Item onClick={e => this.handleSegment(21)} className={'animated fadeIn'}>20</Dropdown.Item>
                                                        <Dropdown.Item onClick={e => this.handleSegment(31)} className={'animated fadeIn'}>30</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={5} md={5} sm={12} className={'m-auto'}>
                                            {bet === 'manual' &&
                                            <div className={ "form-group rev mt-2 mb-0"}>
                                                <Button variant={'btn btn-md btn-block mt-2 btn-bet'}
                                                        disabled={inputDisabled}
                                                        type="submit">
                                                    {this.state.buttonText}
                                                </Button>
                                            </div>
                                            }
                                            <a href="#" className={'hidden ' + SPIN_BUTTON} />
                                    </Col>
                                </Row>

                                {bet === 'auto' &&
                                <>
                                    <Row>
                                        <Col xl={6} md={6} sm={12}>
                                            <div className={"form-group bet-input mb-1"}>
                                                <div className="input-group">
                                                    <div className="input-group-append">
                                                        <span className="input-group-text">
                                                            Bets Num
                                                        </span>
                                                    </div>
                                                    <input
                                                        type="number"
                                                        disabled={inputDisabled}
                                                        className="form-control"
                                                        required={true}
                                                        value={this.state.betNumber}
                                                        onChange={e => this.setNumber(e, e.target.value)} />
                                                    <div className="input-group-append">
                                                        <button onClick={e=>this.setNumber(e,10)} className={'btn btn-s-4 btn-xs'}>10</button>
                                                        <button onClick={e=>this.setNumber(e,50)} className={'btn btn-s-4 btn-xs'}>50</button>
                                                        <button onClick={e=>this.setNumber(e,100)} className={'btn btn-s-4 btn-xs'}>100</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={6} md={6} sm={12}>
                                            <div className={"form-group bet-input mb-1"}>
                                                <div className="input-group">
                                                    <div className="input-group-append">
                                                <span className="input-group-text">
                                                           Increase Amount On Win
                                                </span>
                                                    </div>
                                                    <input
                                                        disabled={inputDisabled}
                                                        type="number"
                                                        className="form-control"
                                                        name="onWin"
                                                        min="0"
                                                        max="100"
                                                        autoComplete={"off"}
                                                        value={this.state.onWin}
                                                        onChange={e => this.setState({ onWin: e.target.value })} />
                                                    <div className="input-group-append">
                                                        <button className="btn bg-cs2 after-text no-hover npt nh" type="button">%</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={6} md={6} sm={12}>
                                            <div className={"form-group bet-input mb-1"}>
                                                <div className="input-group">
                                                    <div className="input-group-append">
                                                        <span className="input-group-text">
                                                           Increase Amount On Lost
                                                        </span>
                                                    </div>
                                                    <input
                                                        disabled={inputDisabled}
                                                        type="number"
                                                        className="form-control"
                                                        name="onLost"
                                                        min="0"
                                                        max="100"
                                                        autoComplete={"off"}
                                                        value={this.state.onLost}
                                                        onChange={e => this.setState({ onLost: e.target.value })} />
                                                    <div className="input-group-append">
                                                        <button className="btn bg-cs2 after-text no-hover npt nh" type="button">%</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={6} md={6} sm={12}>
                                            <div className={"form-group bet-input mb-1"}>
                                                <div className="input-group">
                                                    <div className="input-group-append">
                                                        <span className="input-group-text">
                                                          Stop On Win
                                                        </span>
                                                    </div>
                                                    <input
                                                        disabled={inputDisabled}
                                                        type="text"
                                                        className="form-control"
                                                        name="stopOnWin"
                                                        max="999999999"
                                                        placeholder=".."
                                                        value={this.state.stopOnWin}
                                                        autoComplete={"off"}
                                                        onChange={this.handleInputChange} />
                                                    <div className="input-group-append">
                                                        <div className="input-group-text">
                                                            <img src={'/assets/images/' + this.props.coin + '.png'} className={'mini-coin-2 mr-1'} alt=""/>
                                                            {this.props.coin}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={5} className={'m-auto'}>
                                            <div className={ "form-group rev mt-2 mb-0"}>
                                            <Button variant={'btn btn-md btn-block mt-2 btn-bet'}
                                                    type="submit">
                                                {this.state.buttonText}
                                                {started &&
                                                    <><div className={'ml-2 spinner-border spinner-border-sm'} /></>
                                                }
                                            </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </>
                                }
                            </form>
                            <div className="btn-group btn-group-toggle my-1 ml-1" data-toggle="buttons">
                               <label className={'' + bet === 'manual' ? 'btn bg-cs2 btn-xs active': 'btn bg-cs2 btn-xs '}>
                                    <input disabled={started} onClick={ e => this.betMode('manual')} type="radio" /> Manual
                                </label>
                               <label className={'' + bet === 'auto' ? 'btn bg-cs2 btn-xs active': 'btn bg-cs2 btn-xs '}>
                                    <input disabled={started} onClick={ e => this.betMode('auto')} type="radio" /> Auto
                                </label>
                            </div>
            </>
        );
    }
}

Game.propTypes = {
    coin: PropTypes.string,
    credit: PropTypes.string
};

const mapStateToProps = state => ({
    coin: state.items.coin,
    credit: state.items.credit
});

export default connect(mapStateToProps, { gameCoin, setWallet })(Game);