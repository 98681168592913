import React from 'react';
import {Row, Col, Button} from "react-bootstrap";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import RangeCredit from "../../Components/Game/Addons";
import {gameCoin} from "../../../actions/gameCoin";
import {setWallet} from "../../../actions/gameWallet";
import storage from "../../../Storage";
import C from "../../../Constant";
import {Event, forceSatoshiFormat, isValidNumber, wait, sendNotfication, playAudio} from "../../../Helper";

class Bet extends React.Component {
    _isMounted = false;
    constructor (props){
        super(props);
        this.state = {
            engine: this.props.engine,
            showCashOut: false,
            isLogged: storage.getKey("logged") !== null ? true : false,
            amount: forceSatoshiFormat(storage.getKey('lam') ? storage.getKey('lam') : .00000010),
            inputDisabled: false,
            inputDisabled2: true,
            buttonText: 'BET',
            tprofit: forceSatoshiFormat(.00000010),
            hprofit: forceSatoshiFormat(.00000010),
            lprofit: forceSatoshiFormat(.00000010),
            firstWin: true
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleBet = this.handleBet.bind(this);
    }

    componentWillUnmount() {
        let { engine} = this.state;
        engine.init = false;
        this._isMounted = false;
    }

    componentDidMount() {
        let { engine } = this.state;
        this._isMounted = true;

        this.props.gameCoin();
        engine.on(C.PLAY_HILO, () => this.play());
        engine.on(C.BUSTED_HILO, () => this.busted());
        engine.on(C.ERROR_HILO, (data) => this.error(data));
        engine.on("win", () => this.win());
        engine.on("lost", () => this.lost());
        engine.on("stop_playing", () => this.stopManual());
    }
    
    stopManual() {
        this.setState({ started: false, buttonText: 'BET', inputDisabled: false });
    }

    handleBet(e){
        e.preventDefault();
        let { isLogged, amount, showCashOut } = this.state;

       // Check User
        if(!isLogged){
           return Event.emit('showAuthModal', true);
        }

        if(parseFloat(amount) <= 0){
            this.setState({ amount: forceSatoshiFormat(0.00000010) });
        }
        else {
            amount = Math.min(parseFloat(storage.getKey('credit')), parseFloat(amount));
            this.setState({ amount: forceSatoshiFormat(amount) });
        }

        this.setState({inputDisabled: true});

        if(showCashOut){
            this.cashOut();
        }
        else {
            this.placeBet();
        }
    }

    play() {
        if(this._isMounted){
            this.setState({ showCashOut: true, inputDisabled2: false });
        }
    }

    error(data) {
        if(this._isMounted){
            sendNotfication(data.message, 'info', 'top-center');

            if(data.code === 'credit')
                this.props.setWallet(true, data.uid);

            this.setState({inputDisabled: false});
        }
    }

    placeBet(){
        if(this._isMounted){
            let { amount, engine } = this.state;

            wait(100)
                .then(() => {
                    engine.init = true;
                    engine.amount = amount;
                    engine.coin   = this.props.coin;
                    engine.start();
                })
        }
    }

    cashOut(){
        if(this._isMounted){
            let { amount, tprofit, engine } = this.state;

            wait(100)
                .then(() => {
                    engine.init   = true;
                    engine.amount = amount;
                    engine.profit = tprofit;
                    engine.coin   = this.props.coin;
                    engine.cashOut();
                    playAudio('win.mp3');
                })
        }
    }

    busted(){
        if(this._isMounted){
            this.setState({ showCashOut: false, inputDisabled: false, inputDisabled2: true, firstWin: true });
            this.setState({ tprofit: this.state.amount, lprofit: this.state.amount, hprofit: this.state.amount })
        }
    }

    handleInputChange(event){
        let target = event.target;
        let value = target.value;

        if(target.name === 'amount'){
            if(!isValidNumber(value)) return;
        }
        
        if(target.name === 'amount'){
            storage.setKey('lam', value);
        }

        this.setState({tprofit: value, hprofit: value, lprofit: value});

        this.setState({ [target.name]: value });
    }
    
    setAmount = (val) => {
        var am = forceSatoshiFormat(val);
        this.setState({ amount: am });
        storage.setKey('lam', am );
        this.setState({tprofit: am, hprofit: am, lprofit: am});
    }

    skip = (e) => {
        if(this._isMounted){
            e.preventDefault();
            let { engine } = this.state;
            engine.next();
        }
    };

    higher = (e) => {
        if(this._isMounted){
            e.preventDefault();
            let { engine } = this.state;
            engine.higher();
        }
    };

    lower = (e) => {
        if(this._isMounted){
            e.preventDefault();
            let { engine } = this.state;
            engine.lower();
        }
    };

    win = () => {
        if(this._isMounted){
            this.setState({ inputDisabled: false, firstWin: false });
            if(!this.state.firstWin){
                this.setState({ tprofit: this.state.tprofit * 2, lprofit: this.state.lprofit * 2, hprofit: this.state.hprofit });
            }
        }
    };

    lost = () => {
        if(this._isMounted){
            this.setState({ inputDisabled: false, showCashOut: false, inputDisabled2: true });
            // playAudio('lost.mp3');
        }
    };

    render() {
        let { inputDisabled, inputDisabled2, showCashOut, buttonText } = this.state;
        return (
            <>
                <form className="w-100 hilo-form" onSubmit={(e) => {this.handleBet(e)}}>
                    <Col md={12} sm={12} className="m-auto">
                        <Row>
                            <Col md={3} sm={12}>
                                <div className={ "hhilo form-group rev mb-3"}>
                                    { showCashOut ?
                                        <Button variant={'btn btn-md btn-block mt-2 btn-bet btn-bet-success'}
                                                disabled={inputDisabled}
                                                type="submit">
                                            CashOut
                                        </Button>
                                        :
                                        <button className={'btn btn-md btn-block mt-2 btn-bet'}
                                                disabled={inputDisabled}
                                                type="submit">
                                            {buttonText}
                                        </button>
                                    }
                                </div>
                            </Col>
                            <Col md={3} xl={3} className={'col-4'}>
                                <div className={ "form-group rev mb-3"}>
                                    <Button variant={'btn btn-md btn-block mt-2 btn-hilo'}
                                            disabled={inputDisabled2}
                                            onClick={ e => this.higher(e) }
                                    >
                                        <i className={'mdi mdi-clipboard-arrow-up mr-2 text-success align-middle font-17'} />
                                        Higher Or Some
                                    </Button>
                                </div>
                            </Col>
                            <Col md={3} xl={3} className={'col-4'}>
                                <div className={ "form-group rev mb-3"}>
                                    <Button variant={'btn btn-md btn-block mt-2 btn-hilo'}
                                            disabled={inputDisabled2}
                                            onClick={ e => this.lower(e) }
                                    >
                                        <i className={'mdi mdi-clipboard-arrow-down mr-2 text-danger align-middle font-17'} />
                                        Lower Or Some
                                    </Button>
                                </div>
                            </Col>

                            <Col md={3} xl={3} className={'col-4'}>
                                <div className={ "form-group rev mb-3"}>
                                    <Button variant={'btn btn-md btn-block mt-2 btn-hilo'}
                                            disabled={inputDisabled2}
                                            onClick={ e => this.skip(e) }
                                    >
                                        <i className={'mdi mdi-arrow-right-bold-circle mr-2 align-middle font-17'} />
                                        Skip
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={12} sm={12} className="m-auto">
                        <Row>
                            <Col md={6} sm={12}>
                                <div className={"form-group bet-input mt-1 mb-1"}>
                                    <div className="input-group">
                                        <div className="input-group-append">
                                            <span className="input-group-text">
                                                <img src={'/assets/images/' + this.props.coin + '.png'} className={'mini-coin-7'} alt="COIN"/>
                                                BET
                                            </span>
                                        </div>
                                        <input disabled={showCashOut ? true : false} type="text" className="form-control text-left" id="amount" name="amount"
                                               placeholder=".." value={this.state.amount} autoComplete={"off"} onKeyUp={this.handleInputChange} onChange={this.handleInputChange} />
                                        <div className="input-group-append">
                                            <RangeCredit set={this.setAmount} />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6} sm={12}>
                                <div className={"form-group bet-input mt-1 mb-1"}>
                                    <div className="input-group">
                                        <div className="input-group-append">
                                            <span className="input-group-text">Total Profit</span>
                                        </div>
                                        <input disabled={true} type="text" className="form-control text-left" name="tprofit" value={forceSatoshiFormat(this.state.tprofit / 3)} />
                                        <div className="input-group-append">
                                            <span className="input-group-text"><img src={'/assets/images/' + this.props.coin + '.png'} className={'mini-coin-2'} alt=""/></span>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6} sm={12}>
                                <div className={"form-group bet-input mt-1 mb-1"}>
                                    <div className="input-group">
                                        <div className="input-group-append">
                                            <span className="input-group-text"> <i className={'mdi mdi-arrow-up-bold text-success'} /> High Profit</span>
                                        </div>
                                        <input disabled={true} type="text" className="form-control text-left" name="hprofit" value={forceSatoshiFormat(this.state.hprofit / 3)} />
                                        <div className="input-group-append">
                                            <span className="input-group-text"><img src={'/assets/images/' + this.props.coin + '.png'} className={'mini-coin-2'} alt=""/></span>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6} sm={12}>
                                <div className={"form-group bet-input mt-1 mb-1"}>
                                    <div className="input-group">
                                        <div className="input-group-append">
                                            <span className="input-group-text"> <i className={'mdi mdi-arrow-down-bold text-danger'} /> Low Profit</span>
                                        </div>
                                        <input disabled={true} type="text" className="form-control text-left" name="lprofit" value={forceSatoshiFormat(this.state.lprofit / 3)} />
                                        <div className="input-group-append">
                                            <span className="input-group-text"><img src={'/assets/images/' + this.props.coin + '.png'} className={'mini-coin-2'} alt=""/></span>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </form>
            </>
        );
    }
}

Bet.propTypes = {
    coin: PropTypes.string,
    credit: PropTypes.string
};

const mapStateToProps = state => ({
    coin: state.items.coin,
    credit: state.items.credit
});

export default connect(mapStateToProps, { gameCoin, setWallet })(Bet);