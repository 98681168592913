import React from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import {Event} from "./Helper";

import games from "./App/games";

import Index from "./App/Pages";
import Home from "./App/Pages/Parts/Home";
import NotFound from "./App/Pages/404";
import Affiliate from "./App/Pages/Affiliate";
import BonusAff from "./App/Pages/Affiliate/Bonus";
import Bonus from "./App/Pages/Bonus";
// import Vip from "./App/Pages/Vip";
import Rules from "./App/Pages/Rules";
import Policies from "./App/Pages/Policies";

import Login from "./App/Pages/Auth/Login";
import Register from "./App/Pages/Auth/Register";
import UserStat from "./App/Components/User/Stat/Modal";
import GameStat from "./App/Components/Game/Stat";
import UserSetting from "./App/Components/User/Setting";

import SlotsHome from "./App/Slots/index";
import Slots from "./App/Slots/Game";
import GamesHome from "./App/Games/index";

import Crash from "./App/Games/Crash";
// import CrashHole from "./App/Games/CrashHole";
import Keno from "./App/Games/Keno";
import Wheel from "./App/Games/Wheel";
import Limbo from "./App/Games/Limbo";
import Mine from "./App/Games/Mine";
import Hilo from "./App/Games/Hilo";
import Hashdice from "./App/Games/HashDice";
import ClassicDice from "./App/Games/ClassicDice";
import SingleKeno from "./App/Games/SingleKeno";
import Plinko from "./App/Games/Plinko";
import VideoPoker from "./App/Games/VideoPoker";
import Blackjack from "./App/Games/BlackJack";
import Goal from "./App/Games/Goal";
// import Baccarat from "./App/Games/Baccarat";
import Roulette from "./App/Games/Roulette";
import SnackAndLadders from "./App/Games/SnakeAndLadders";

function Router(props) {
    let location = useLocation();
    let background = location.state && location.state.background;
    let loc = location.pathname;
    const { t } = props;
    return (
        <>
            <Switch history={props.history} location={background || location}>
                <Route exact path="/" children={<Index t={t} location={loc} content={<Home t={t} />} />} />
                <Route path="/register" children={<Index t={t} content={<Home t={t} content={<Register t={t} history={props.history} show={true} />} />} />} />
                <Route path="/login" children={<Index t={t} content={<Home t={t} content={<Login t={t} history={props.history} show={true} />} />} />} />
                <Route path="/setting" children={<Index t={t} content={<UserSetting t={t} history={props.history} />} />} />
                <Route path="/affiliate" children={<Index t={t} content={<Affiliate t={t} history={props.history} />} />} />
                <Route path="/aff" children={<Index t={t} content={<BonusAff params={location} history={props.history} t={t} />} />} />
                
                <Route path="/rules" children={<Index t={t} content={<Rules params={location} history={props.history} t={t} />} />} />
                <Route path="/policies" children={<Index t={t} content={<Policies params={location} history={props.history} t={t} />} />} />

                <Route path="/user/:id" children={<Index t={t} content={<Home t={t} content={<UserStat t={t} history={props.history} params={location} byLink={true} />} />} />} />
                <Route path="/game/:id" children={<Index t={t} content={<GameStat t={t} params={location} />} />} />
                
                {/*<Route path="/crash-hole" children={<Index t={t} content={<CrashHole t={t} />} />} />*/}
                <Route path="/crash" children={<Index t={t} content={<Crash t={t} />} />} />
                <Route path="/keno" children={<Index t={t} content={<Keno t={t} />} />} />
                <Route path="/wheel" children={<Index t={t} content={<Wheel t={t} />} />} />
                <Route path="/limbo" children={<Index t={t} content={<Limbo t={t} />} />} />
                <Route path="/hash-dice" children={<Index t={t} content={<Hashdice t={t} />} />} />
                <Route path="/classic-dice" children={<Index t={t} content={<ClassicDice t={t} history={props.history} />} />} />
                <Route path="/mine" children={<Index t={t} content={<Mine t={t} history={props.history} />} />} />
                <Route path="/singlekeno" children={<Index t={t} content={<SingleKeno t={t} history={props.history} />} />} />
                <Route path="/hilo" children={<Index t={t} content={<Hilo t={t} history={props.history} />} />} />
                <Route path="/roulette" children={<Index t={t} content={<Roulette t={t} history={props.history} />} />} />
                <Route path="/plinko" children={<Index t={t} content={<Plinko t={t} />} />} />
                <Route path="/videopoker" children={<Index t={t} content={<VideoPoker t={t} />} />} />
                <Route path="/blackjack" children={<Index t={t} content={<Blackjack history={props.history} />} />} />
                <Route path="/goal" children={<Index t={t} content={<Goal history={props.history} />} />} />
                <Route path="/snake-and-ladders" children={<Index t={t} content={<SnackAndLadders history={props.history} />} />} />
                {/*<Route path="/baccarat" children={<Index t={t} content={<Baccarat history={props.history} />} />} />*/}
                <Route path="/slots/:id" children={<Index t={t} content={<Slots t={t} history={props.history} />} />} />
                <Route path="/slots" children={<Index t={t} content={<SlotsHome t={t} />} />} />
                <Route path="/games" children={<Index t={t} content={<GamesHome t={t} />} />} />
                <Route path="/bonus" children={<Index t={t} content={<Bonus t={t} />} />} />
                {/*<Route path="/vip" children={<Index t={t} content={<Vip t={t} />} />} />*/}
                <Route path="*">
                    <NotFound history={props.history} />
                </Route>
            </Switch>
        </>
    );
}

export default Router;