import React from "react";
import {Row, Col} from "react-bootstrap";
import storage from "../../../../Storage";
import socket from "../../../../Socket";
import {decode, encode, wait, isEmail, sendNotfication, __} from "../../../../Helper";
import ReactTooltip from "react-tooltip";
import C from "../../../../Constant";

class Parent extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            firstUserName: storage.getKey('name'),
            firstEmail: storage.getKey('email'),
            email: storage.getKey('email'),
            username: storage.getKey('name'),
            // checkbox2: storage.getKey('show_usd') === null ? false : true, 
            checkbox2: false, 
            loadingEmail: false,
            loadingUsername: false,
            loadingPrivacy: false,
            loading2Fa: false,
            loading: true,
            loadingCheck: false,
        };
    }

    componentDidMount(){
        this._isMounted = true;
        socket.on(C.EDIT_ACCOUNT, data => this.updateProfile(decode(data)));
    }

    componentWillMount() {
        wait(2000).then(() => {
            this.setState({ loading: false });
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    updateProfile(data){
        if(this._isMounted)
        {
            this.setState({ loadingUsername: false, loadingEmail: false });
            if(data.status)
            {
                storage.setKey('name', data.username);
                storage.setKey('email', data.email);
                sendNotfication('Your Account Setting was Updated !', 'success', 'top-center');
                window.location.replace('../');
            }
            else {
                return sendNotfication(data.error, 'success', 'top-center');
            }
        }
    }

    save(input){
        if(this._isMounted)
        {
            let { email, username, firstUserName, firstEmail } = this.state;

            if(input === 'email')
            {
                if(email === firstEmail){
                    return sendNotfication('Please Enter New Email Address !', 'info', 'top-center');
                }

                if(!email)
                {
                    document.getElementById('email').classList.add('border-danger');
                    return;
                }
                
                if(email.trim() === "")
                {
                    document.getElementById('email').classList.add('border-danger');
                    return;
                }

                if(!isEmail(email))
                {
                    document.getElementById('email').classList.add('border-danger');
                    return;
                }

                this.setState({ loadingEmail: true });

                wait(100).then(() => {
                    socket.emit(C.EDIT_ACCOUNT, encode({
                        email: email
                    }));
                })
            }

            if(input === 'username')
            {
                if(username === firstUserName){
                    return sendNotfication('Please Enter New Username !', 'info', 'top-center');
                }

                if(!username)
                {
                    return;
                }

                if(username.trim() === "")
                {
                    return;
                }

                this.setState({ loadingUsername: true });

                wait(500).then(() => {
                    socket.emit(C.EDIT_ACCOUNT, encode({
                        username: username
                    }));
                })
            }
        }
    }

    handleCheckBox = () => {
        this.setState({ loadingCheck: true, checkbox: true })
        wait(1200).then(() => {
            this.setState({ checkbox: false, loadingCheck: false });
            return sendNotfication('This feature will soon be added', 'info', 'top-center');
        })
    }

    handleCheckBox2 = () => {
        this.setState({ loadingCheck2: true, checkbox2: true });

        // if(this.state.checkbox2){
        //     this.setState({ checkbox2: false });
        //     storage.setKey('show_usd', false)
        // }
        // else {
        //     this.setState({ checkbox2: true });
        //     storage.setKey('show_usd', true)
        // }
        wait(1200).then(() => {
            this.setState({  loadingCheck2: false, checkbox2: false });
            return sendNotfication('This feature will soon be added', 'info', 'top-center');
        })
    }

    render() {
        return (
            <>
                { this.state.loading ?

                    <div className="text-center">
                        <div className="spinner-grow text-white my-2" role="status"/>
                    </div>
                    :
                    <>
                    <ReactTooltip />
                    <Row className="mt-5">
                        <Col sm={6}>
                            <label htmlFor="email">Email Address</label>
                            <div className="input-group">
                                <input
                                    type="email"
                                    id="email"
                                    autoComplete={'off'}
                                    className="form-control"
                                    placeholder={'Enter New Email'}
                                    value={this.state.email}
                                    required={true}
                                    onChange={e => this.setState({ email: e.target.value })}
                                />
                                <div className="input-group-append">
                                    <button onClick={e => this.save('email') } type="button" className="btn bg-cs7 no-shadow btn-clipboard" data-tip="Save">
                                        {this.state.loadingEmail ?
                                            <div className="spinner-border spinner-border-sm" role="status" />
                                            : <i className="mdi mdi-content-save-settings"/>
                                        }
                                    </button>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6}>
                            <label htmlFor="username">Username</label>
                            <div className="input-group">
                                <input
                                    type="username"
                                    id="username"
                                    autoComplete={'off'}
                                    className="form-control"
                                    value={this.state.username}
                                    required={true}
                                    onChange={e => this.setState({ username: e.target.value })}
                                />
                                <div className="input-group-append">
                                    <button onClick={e => this.save('username') } type="button" className="btn bg-cs7 no-shadow btn-clipboard" data-tip="Save">
                                        {this.state.loadingUsername ?
                                            <div className="spinner-border spinner-border-sm" role="status" />
                                            : <i className="mdi mdi-content-save-settings"/>
                                        }
                                    </button>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} md="6">
                            <label className="mt-3" htmlFor="mi"></label>
                            <div className="input-group">
                                <div class="custom-control custom-switch switch-danger">
                                    <input type="checkbox" class="custom-control-input" id="mi" checked={this.state.checkbox} onChange={this.handleCheckBox}  />
                                    <label class="custom-control-label cpt" for="mi"> Login Notice by Email
                                       {this.state.loadingCheck &&
                                            <div className="spinner-border spinner-border-sm ml-2" role="status" />
                                        }
                                    </label>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} md="6">
                            <label className="mt-3" htmlFor="mi3"></label>
                            <div className="input-group">
                                <div class="custom-control custom-switch switch-danger">
                                    <input type="checkbox" class="custom-control-input" id="mi3" checked={this.state.checkbox2} onChange={this.handleCheckBox2}  />
                                    <label class="custom-control-label cpt" for="mi3"> Show All Currencies by USD
                                       {this.state.loadingCheck2 &&
                                            <div className="spinner-border spinner-border-sm ml-2" role="status" />
                                        }
                                    </label>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    </>
                }
            </>
        );
    }
}

export default Parent;