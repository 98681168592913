import React from "react";
import { Modal } from "react-bootstrap";
import { Link } from 'react-router-dom'

export default class Aml extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <>
                <p className={'text-white font-light mb-2 font-14 l30 mb-4'}>
                    <p>
                        <b>AML POLICY</b>
                    </p>

                    <p>
                        <b>Coingator.io</b>&nbsp;company carries out all appropriate measures to combat money laundering and international terrorism (AML Policy). Thus, the Company has a strong position to prevent all kinds of illegal activity. To fulfill these obligations, the Company is bound to inform competent authorities if there is a reason to suspect that funds which were deposited by the User into his account are related to illegal activity or terrorism financing. The company is also obliged to block such funds and to undertake measures provided for by the rules of the AML policy.
                    </p>

                    <p>
                        Money laundering means:
                    </p>

                    <ul>
                        <li>
                            <ul>
                                <li>
                                    hiding or keeping&nbsp;privacy regarding the information about the real source, location, disposal, movement, ownership or other property rights related to property obtained as a result of illegal activity,
                                </li>
                                <li>
                                    conversion, moving, obtaining, possession or use of property which was got as the result of criminal activity for the purpose of concealing the illicit source of such property or assisting persons involved&nbsp;in crime to avoid legal consequences of their actions,
                                </li>
                                <li>
                                    a situation in which the property was obtained as a result of criminal activity committed on the territory of another state.
                                </li>
                            </ul>
                        </li>
                    </ul>

                    <p>
                        In order to prevent the infiltration of criminal capital into the economy of the state, many countries fight against money laundering and the terrorism financing.
                    </p>

                    <p>
                        The company applies internal laws and regulations and special measures to help international organizations in combating money laundering and the terrorism financing around the world.
                    </p>

                    <p>
                        When you open an account, you agree to undertake the following obligations:
                    </p>

                    <ul>
                        <li>
                            <ul>
                                <li>
                                    You agree that you comply with all applicable laws and regulations on combating money laundering and terrorism financing, including the АМL Policy.
                                </li>
                                <li>
                                    You confirm that you have no information or any suspicions about the fact that&nbsp;&nbsp;funds used for depositing in the past, present or future, are received from any illegal source, or have any relation to legalization of income obtained&nbsp;&nbsp;illegally, or other unlawful activity prohibited by applicable law or the instructions of any international organizations;
                                </li>
                                <li>
                                    You agree to immediately provide any information we think fit to require according to applicable laws and regulatory requirements in respect of combating the legalization of funds obtained illegally.
                                </li>
                            </ul>
                        </li>
                    </ul>

                    <p>
                        The company collects and keeps the passport or other ID of the User, and reports on all changes made in the account;
                    </p>

                    <p>
                        The company monitors any suspicious activity on the User&#39;s account, as well as operations carried out under special conditions;
                    </p>

                    <p>
                        The company has the right to ban the User at any time, if the Company has grounds for supposing that this operation has any relation to money laundering and criminal activity. In accordance with international law the Company is not obliged to inform the User about his suspicious activity and let him know that it has been passed to relevant authorities.
                    </p>

                    <p>
                        In accordance with the internal AML procedures the Company performs initial and ongoing personal identity verification procedures as provided by the level of risk of each User.
                    </p>

                    <ul>
                        <li>
                            <ul>
                                <li>
                                    The company will ask you to provide the minimal information to confirm your identity.
                                </li>
                                <li>
                                    The company will record and preserve all data and ID, as well as which methods of confirmation have been used and the results of verification procedures.
                                </li>
                                <li>
                                    The company will check your personal data to match the list of persons suspected of terrorism, which is formed by the authorized state and independent authorities. A minimum set of identification data includes: the User&#39;s full name; date of birth (for individuals); residential address or registered address of the User;
                                </li>
                                <li>
                                    sources of funds that you plan to Deposit into the account.
                                </li>
                            </ul>
                        </li>
                    </ul>

                    <p>
                        To verify and confirm the authenticity of the above-mentioned data, the Company may require the following documents:
                    </p>

                    <ul>
                        <li>
                            <ul>
                                <li>
                                    passport or identification card, or other document equivalent that meets the following requirements: contains the name, date of birth and a photograph of the document holder;
                                </li>
                                <li>
                                    issued by the national public authorities, a recently obtained receipt for the payment of utility bills (not older than 3 months) or other documents confirming the address of the User.
                                </li>
                            </ul>
                        </li>
                    </ul>

                    <p>
                        The company may also require other additional information, confirmed by relevant documents. In certain cases, the Company may also require notarized copies of documents from the User.
                    </p>

                </p>
            </>
        );
    }
}