import React from "react";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    icon: {
        width: 34,
        marginRight: 5
    }
});

export default function Slots(){
    const classes = useStyles();
    return <img src="/assets/images/new_icons/slots.svg" className={classes.icon} alt="Slots" />
}