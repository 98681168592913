/* eslint-disable */
class Text {
	constructor(count, t, c, s, x, y){
		this.t = t
		this.x = x
		this.y = y
		this.color = c
		this.size = s
		this.item = null;
		this.count = count
		this.show();
	}
	
	show(){
		const style = new PIXI.TextStyle({
			fontFamily: 'Titillium Web',
			fontWeight: 700,
			align: 'center',
			fontSize: this.size,
			fill: this.color,
			wordWrap: true,
			wordWrapWidth: 440
		});
		
		const t = new PIXI.Text(this.t, style);
		t.x = this.x
		t.y = this.y
		t.anchor.set(0.5);
		t.sortChildren(this.count);
		t.zIndex = this.count;
		t.id = this.count;
		t.type = 'text';
		t.value = this.t
		this.item = t;
	}
}

export default Text;