/* eslint-disable */

class CardBack {
	constructor(id, c, x, y){
		this.id = id
		this.c = c
		this.x = x
		this.y = y
		this.cards = []
	}
	
	add(){
		let card = new PIXI.Sprite(PIXI.loader.resources[ this.c ].texture);
		card.x = this.x;
		card.y = this.y;
		card.anchor.set(0.5);
		card.interactive = true;
		card.sortChildren(3);
		card.zIndex = 1;
		card.id = this.id;
		this.cards[this.id] = card
	}
}

export default CardBack;