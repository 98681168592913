import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Row, Col, Button} from "react-bootstrap";
import RangeCredit from "../../Components/Game/Addons";
import {gameCoin} from "../../../actions/gameCoin";
import {setWallet} from "../../../actions/gameWallet";
import storage from "../../../Storage";
import C from "../../../Constant";
import {Event, __, isValidNumber, wait, forceSatoshiFormat, sendNotfication, isMobile} from "../../../Helper";

class Bet extends React.Component {
    _isMounted = false;
    constructor (props){
        super(props);
        this.state = {
            engine: this.props.engine,
            mobile: false,
            isLogged: storage.getKey('logged')!== null ? true : false,
            amount: forceSatoshiFormat(storage.getKey('lam') ? storage.getKey('lam') : .00000010),
            inputDisabled: false,
            InsuranceDisabled: true,
            splitDisabled: true,
            hitDisabled: true,
            standDisabled: true,
            doubleDisabled: true,
            buttonText: 'Deal'
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleBet = this.handleBet.bind(this);
    }

    componentWillUnmount() {
        let { engine} = this.state;
        engine.init = false;
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        let { engine } = this.state;

        this.props.gameCoin();
        engine.on(C.PLAY_BLACKJACK, (data) => this.play(data));
        engine.on(C.BUSTED_BLACKJACK, () => this.busted());
        engine.on(C.ERROR_BLACKJACK, (data) => this.error(data));
        engine.on("stop_playing", () => this.stopManual());

        this.winAmount(this.state.amount);

        if(isMobile()){
            this.setState({ mobile: true });
        }
    }
    
    stopManual() {
        this.setState({ buttonText: 'Deal', inputDisabled: false });
    }

    play(data) {
        if(this._isMounted){
            this.setState({ inputDisabled: true });
        }
    }

    busted(){
        if(this._isMounted){
            wait(2000).then(() => {
                this.setState({ inputDisabled: false });
            })
        }
    }

    placeBet(){
        if(this._isMounted){
            let { amount, engine } = this.state;

            wait(100)
                .then(() => {
                    engine.init = true;
                    engine.amount = amount;
                    engine.coin   = this.props.coin;
                    engine.start();
                })
        }
    }

    error(data) {
        if(this._isMounted){
            sendNotfication(data.message, 'info', 'top-center');

            if(data.code === 'credit')
                this.props.setWallet(true, data.uid);

            this.setState({ inputDisabled: false });
        }
    }

    handleBet(e){
        e.preventDefault();
        let { isLogged, amount } = this.state;

       // Check User
        if(!isLogged){
           return Event.emit('showAuthModal', true);
        }

        if(parseFloat(amount) <= 0){
            this.setState({ amount: forceSatoshiFormat(0.00000010) });
        }
        else {
            amount = Math.min(parseFloat(storage.getKey('credit')), parseFloat(amount));
            this.setState({ amount: forceSatoshiFormat(amount) });
        }

        this.placeBet();
    }

    handleInputChange(event){
        let target = event.target;
        let value = target.value;
        if(target.name === 'amount'){
            if(!isValidNumber(value)) return;
        }
        if(target.name === 'amount'){
            storage.setKey('lam', value);
        }
        this.setState({ [target.name]: value });

        this.winAmount(value);
    }

    winAmount = (value) => {
        this.setState({ winAmount: forceSatoshiFormat(value / 2) });
    }
    
    setAmount = (val) => {
        var am = forceSatoshiFormat(val);
        this.setState({ amount: am });
        storage.setKey('lam', am );
        this.setState({ winAmount: forceSatoshiFormat(am / 2) });
    }

    render() {
        let { bet, mobile, inputDisabled, InsuranceDisabled, splitDisabled, hitDisabled, standDisabled, doubleDisabled } = this.state;
        return (
            <>
                <form className="w-100 bj" onSubmit={(e) => {this.handleBet(e)}}>
                    <Row>
                        <Col md={2} xl={2} className={'col-4'}>
                            <Button variant={'btn btn-md btn-block my-1 btn-bet'}
                                    disabled={inputDisabled}
                                    id="deal"
                                    type="submit">
                                {this.state.buttonText}
                            </Button>
                        </Col>
                        <Col md={2} xl={2} className={'col-4'}>
                            <Button variant={'btn btn-md btn-block mt-2 btn-hilo'}
                                    disabled={hitDisabled}
                                    id="hit"
                                    type="button">
                               Hit
                            </Button>
                        </Col>
                        <Col md={2} xl={2} className={'col-4'}>
                            <Button variant={'btn btn-md btn-block mt-2 btn-hilo'}
                                    disabled={standDisabled}
                                    id="stand"
                                    type="button">
                               Stand
                            </Button>
                        </Col>
                        <Col md={2} xl={2} className={mobile ? 'col-6' : 'col-4'}>
                            <Button variant={'btn btn-md btn-block mt-2 btn-hilo'}
                                    disabled={doubleDisabled}
                                    id="double"
                                    type="button">
                               Double Down
                            </Button>
                        </Col>
                        <Col md={2} xl={2} className={mobile ? 'col-6' : 'col-4'}>
                            <Button variant={'btn btn-md btn-block mt-2 btn-hilo'}
                                    disabled={splitDisabled}
                                    id="split"
                                    type="button">
                                Split
                            </Button>
                        </Col>
                        <Col md={2} xl={2} className={mobile ? 'col-12' : 'col-4'}>
                            <Button variant={'btn btn-md btn-block mt-2 btn-hilo'}
                                    disabled={InsuranceDisabled}
                                    id="insurance"
                                    type="button">
                               Insurance
                            </Button>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col xl={6} md={8} sm={12}>
                            <div className={"form-group mb-1 bet-input"}>
                                <div className="input-group">
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            <img src={'/assets/images/' + this.props.coin + '.png'} className={'mini-coin-7'} alt=""/>
                                            BET
                                        </span>
                                    </div>
                                    <input disabled={inputDisabled} type="text" className="form-control text-left" id="amount" name="amount"
                                            placeholder=".." value={this.state.amount} autoComplete={"off"} onKeyUp={this.handleInputChange} onChange={this.handleInputChange} />
                                    <div className="input-group-append">
                                        <RangeCredit set={this.setAmount} />
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xl={6} md={4} sm={12}>
                            <div className={"form-group mb-1 bet-input"}>
                                <div className="input-group">
                                    <div className="input-group-append">
                                        <span className="input-group-text">WIN AMOUNT</span>
                                    </div>
                                    <input
                                        disabled={true} type="text" className="form-control text-left"
                                        placeholder=".." value={this.state.winAmount} autoComplete={"off"} />
                                    <div className="input-group-append">
                                        <button className="btn bg-cs2 after-text no-hover npt nh" type="button"><i className="mdi mdi-close" /></button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </form>
            </>
        );
    }
}

Bet.propTypes = {
    coin: PropTypes.string,
    credit: PropTypes.string
};

const mapStateToProps = state => ({
    coin: state.items.coin,
    credit: state.items.credit
});

export default connect(mapStateToProps, { gameCoin, setWallet })(Bet);