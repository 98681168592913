import React, { Component } from "react";
import {Card} from "react-bootstrap";
import ManualBet from './includes/ManualBet';

class Bet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bet: 'manual',
        };
    }

    betMode = (type) => {
        this.setState({ bet: 'manual' });
    };

    render() {
        let { bet } = this.state;
        return (
            <Card className={"no-shadow mb-1"}>
                <Card.Body className="bet-form no-shadow p-2">
                    <ManualBet engine={this.props.engine} mobile={this.props.mobile}/>
                </Card.Body>
            </Card>
        );
    }
}

export default Bet;