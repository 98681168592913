import React from "react";
import md5 from "md5";
import PropTypes from "prop-types";
import { Table } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import socket from "../../../../Socket";
import storage from "../../../../Storage";
import UserModal from "../../User/Stat/Modal";
import {__, fixDate, Event, wait, decode, encode, forceSatoshiFormat, timeConvertor, getSingleRandomInt, SITE, getRandomInt, getGameID, cryptoToUsd} from "../../../../Helper";
import C from "../../../../Constant";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
}));

function Queue(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    let { game } = props;
    let isLogged = storage.getKey("logged") !== null ? true : false;

    let content;

    if(props.live)
    {
        content = (
            <div className={classes.root}>
                <AppBar position="static" color="transparent">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        variant="fullWidth"
                        aria-label="full width tabs"
                        centered
                    >
                        <Tab icon={<i className={'mdi mdi-chart-areaspline'} />} label="My Bets" {...a11yProps(0)} />
                    </Tabs>
                </AppBar>
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                >
                    <TabPanel value={value} index={0} dir={theme.direction}>
                        { (isLogged) ?
                            <MyBets t={props.t} game={'hash_dice'} />
                            :
                            <>
                                <div className={'alert bg-soft-dark mt-2 font-13 text-white text-center'}>
                                    You must be logged to see your stats
                                </div>
                            </>
                        }
                    </TabPanel>
                </SwipeableViews>
            </div>
        );
    }
    else
    if(props.slots)
    {
        content = (
            <div className={classes.root}>
                <AppBar position="static" color="transparent">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        variant="fullWidth"
                        aria-label="full width tabs"
                        centered
                    >
                        <Tab icon={<i className={'mdi mdi-chart-bar'} />} label="All Bets" {...a11yProps(0)} />
                        <Tab icon={<i className={'mdi mdi-chart-areaspline'} />} label="My Bets" {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                >
                    <TabPanel value={value} index={0} dir={theme.direction}>
                        <AllBets t={props.t} game={game} slot={true} />
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={theme.direction}>
                        { (isLogged) ?
                            <MyBets t={props.t} game={game} />
                            :
                            <>
                                <div className={'alert bg-soft-dark mt-2 font-13 text-white text-center'}>
                                    You must be logged to see your stats
                                </div>
                            </>
                        }
                    </TabPanel>
                </SwipeableViews>
            </div>
        );
    }
    else 
    {
        content = (
            <div className={classes.root}>
                <AppBar position="static" color="transparent">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        variant="fullWidth"
                        aria-label="full width tabs"
                        centered
                    >
                        <Tab icon={<i className={'mdi mdi-chart-bar'} />} label="All Bets" {...a11yProps(0)} />
                        <Tab icon={<i className={'mdi mdi-chart-areaspline'} />} label="My Bets" {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                >
                    <TabPanel value={value} index={0} dir={theme.direction}>
                        <AllBets t={props.t} game={game} slot={false} />
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={theme.direction}>
                        { (isLogged) ?
                            <MyBets t={props.t} game={game} />
                            :
                            <>
                                <div className={'alert bg-cs2 mt-2 font-13 text-white text-center'}>
                                    You must be logged to see your stats
                                </div>
                            </>
                        }
                    </TabPanel>
                </SwipeableViews>
            </div>
        );
    }

    return content;
}

class AllBets extends React.Component {
	_isMounted = false;
    
    constructor (props){
        super(props);
        this.state = {
            players: [],
            fakePlayers: [],
            loading: true,
            name: storage.getKey('name'),
            nobets: false,
            fakePlayers: [],
            stop: false
        };
    }

    componentDidMount(){
		this._isMounted = true;
        wait(200).then( () => {
            socket.emit(C.LAST_BETS_BY_GAME, encode({game: this.props.game }));
        });

        socket.on(C.LAST_BETS_BY_GAME, data => this.makeList(decode(data)));
        socket.on(C.ADD_BET, data => this.addBet(decode(data)));
        socket.on(C.ADD_SLOT_BET, data => this.addBet(decode(data)));

        Event.on('connect', () => {
            this.setState({ stop: false })
        });

        Event.on('disconnect', () => {
            this.setState({ stop: true })
        });
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    addBet(player){
		if(this._isMounted)
        {
            if(this.props.slot)
            {
                // if(parseFloat(player.game) === parseFloat(this.props.game)){
                    this.setState(state => ({ players: [player, ...state.players] }));
                        
                    if(this.state.players.length > 10)
                        this.setState({ players: __.dropRight(this.state.players, this.state.players.length - 10) });
                // }
            }
            else
			if(player.game === this.props.game)
            {
                this.setState(state => ({ players: [player, ...state.players] }));
                    
                if(this.state.players.length > 10)
                    this.setState({ players: __.dropRight(this.state.players, this.state.players.length - 10) });
			}
		}
    }

    //Make Bets List
    makeList(arr){
		if(this._isMounted){
			if(typeof arr.history === "undefined") return;
			this.setState({ loading: false });

			if(arr.history.length === 0){
				this.setState({ nobets: true });
			}
            this.setState({ players: arr.history })
		}
    }

    render () {
         const list = this.state.players.map((player, i) =>
            <Players key={i.toString()} forMy={false} player={player} />
        );
        return(
            <>
                <div className={'table-responsive last-bets num-style'}>
                    {this.state.loading ?
                    <>
                        <div className="text-center">
                            <div className="spinner-grow text-white my-3" role="status"/>
                        </div>
                    </>
                    :
                    <Table className={"mb-0"}>
                        <thead>
                        <tr>
                            <th>
                                ID
                            </th>
                            <th>
                                USERNAME
                            </th>
                            <th>
                                BET
                            </th>
                            <th>
                                PROFIT
                            </th>
                            <th>
                                DATE/TIME
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {list}
                        </tbody>
                    </Table>
                    }
                    {this.state.nobets &&
                        <div className={'alert bg-soft-dark mt-2 font-13 text-yellow text-center'}>
                            No Records
                        </div>
                    }
                </div>
            </>
        );
    }
}

class MyBets extends React.Component {
	_isMounted = false;
    constructor (props){
        super(props);
        this.state = {
            loading: true,
            players: [],
            status: null,
            name: storage.getKey('name'),
            game: this.props.game
        };
    }

    componentDidMount(){
		this._isMounted = true;
        socket.emit(C.MY_BETS, encode({game: this.state.game}));
        socket.on(C.MY_BETS, data => this.makeList(decode(data)));
        socket.on(C.ADD_BET, data => this.addList(decode(data)));
        socket.on(C.ADD_SLOT_BET, data => this.addList(decode(data)));
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    addList(player){
		if(this._isMounted){
			if(player.name === this.state.name)
            {
                this.setState(state => ({ players: [player, ...state.players] }));
                    
                if(this.state.players.length > 10)
                    this.setState({ players: __.dropRight(this.state.players, this.state.players.length - 10) });
			}
        }
    }

    makeList(arr){
		if(this._isMounted){
			if(typeof arr.history === "undefined") return;
			this.setState({ loading: false });

			if(arr.history.length === 0)
				this.setState({ status: 'You have not played yet !'})

            this.setState({ players: arr.history })
		}
    }

    render () {
         const list = this.state.players.map((player, i) =>
            <Players forMy={true} key={i.toString()} player={player} />
        );
        return(
                <div className={'table-responsive last-bets num-style'}>

                    {this.state.loading ?
                    <>
                        <div className="text-center">
                            <div className="spinner-grow text-white my-3" role="status"/>
                        </div>
                    </>
                    :
                    <Table className={"mb-0"}>
                        <thead>
                        <tr>
                            <th>
                                ID
                            </th>
                            <th>
                                AMOUNT
                            </th>
                            <th>
                                PROFIT
                            </th>
                            <th>
                                DATE/TIME
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {list}
                        </tbody>
                    </Table>
                    }
                    {this.state.status !== null &&
                        <div className={'alert text-uppercase bg-soft-dark mt-2 font-13 text-yellow text-center'}>You have not played yet !</div>
                    }
                </div>
        );
    }
}

class Players extends React.Component{
    constructor(props){
        super(props);
    }

    gameDetail = () => {
        Event.emit('single_game_modal', { data: this.props.player })
    }

    render() {
        let { forMy } = this.props;
        let {name, uid, amount, profit, coin, created, gid, direct} = this.props.player;
        let isWinner = false;
        let date = fixDate(created);

        if(direct){
            date = timeConvertor(created);
        }

        if(__.toNumber(profit) !== 0.00000000)
            isWinner = true;

        return(
            <tr className={'q-crash'}>
                <td className="cpt" onClick={this.gameDetail}>
                    {gid}
                </td>
                {forMy === false &&
                    <td>
                        <UserModal id={uid} username={name} isWinner={isWinner} />
                    </td>
                }
                <td onClick={this.gameDetail} className={"num-style cpt text-white"}>
                    <img src={'/assets/images/' + __.lowerCase(coin) + '.png'} className={'mini-coin-2 hidden-sm cpt'} alt={coin}/>
                    {forceSatoshiFormat(amount, coin, true)}
                </td>
                <td onClick={this.gameDetail} className={(isWinner===true) ? 'text-success-2 num-style cpt': 'text-warning num-style cpt'}>
                    <img src={'/assets/images/' + __.lowerCase(coin) + '.png'} className={'mini-coin-2 hidden-sm cpt'} alt={coin}/>
                    {forceSatoshiFormat(profit, coin, true)}
                </td>
                <td className="cpt" onClick={this.gameDetail}>
                    {date}
                </td>
            </tr>
        );
    }
}

export default Queue;