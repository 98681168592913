import React from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import {Helmet} from "react-helmet";
import Games from "../Pages/Parts/Home/Games";

class Index extends React.Component {
    constructor(props){
        super(props);
        this.state = {
        	search: null
        }
    }

    componentDidMount(){
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
    
    change = (e) => {
    	let value = e.target.value;
    	this.setState({ search: value });
    }

    render() {
        return <>
                <Helmet>
                    <title>Coingator.io | Original Games - Crypto Casino Games, Slot Games and Crypto Gambling</title>
                    <meta name="keywords" content="Crypto Casino Games, Online Casino Games, Slot Games, Bitcoin Crash Game, Dice Games, Live Casino Games, Crypto Casino Games, Blockchain Games, Bitcoin Casino Games, Best Crypto Online Game, Crypto Gambling Games, Coingator.io Crypto Games"/>
                    <meta name="description" 
                        content="Coingator.io is the crypto casino games and gambling games. Supports More than 17 Crypto Coins. Fast Withdrawl, provably fair, and Hot wallet." />
                    <meta name="og:title" content="Coingator.io is the crypto casino games and gambling games. Supports More than 17 Crypto Coins. Fast Withdrawl, provably fair, and Hot wallet." />
                    <meta name="og:description" content="Coingator.io is the crypto casino games and gambling games. Supports More than 17 Crypto Coins. Fast Withdrawl, provably fair, and Hot wallet." />
                    <link rel="canonical" href="https://Coingator.io/games" />
                </Helmet>
             <ReactCSSTransitionGroup
             	transitionAppear={true}
             	transitionAppearTimeout={250}
             	transitionEnterTimeout={200}
             	transitionLeaveTimeout={200}
             	transitionName={'SlideIn'}
             >
        		<div id="st">
        			<Games/>
        		</div>
			</ReactCSSTransitionGroup>
        </>
    }
}

export default Index;