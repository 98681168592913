import { CLASSIC_DICE_SET_RANGE, CLASSIC_DICE_SET_CHANCE, CLASSIC_DICE_SET_TYPE } from './types';

export const setClassicDicePayout = payout => dispatch => {
    dispatch({
        type: CLASSIC_DICE_SET_RANGE,
        payload: payout
    })
};

export const setClassicDiceChance = chance => dispatch => {
    dispatch({
        type: CLASSIC_DICE_SET_CHANCE,
        payload: chance
    })
};

export const setClassicDiceType = type => dispatch => {
    dispatch({
        type: CLASSIC_DICE_SET_TYPE,
        payload: type
    })
};