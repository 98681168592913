/* eslint-disable */

import { Bodies, Body, Composite, Engine, Events, World } from "matter-js";
import {playAudio, isMobile, wait} from "../../../../Helper";

function random(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

let engine;

function Canvas() {
    let self = this;
	
    self.pixi = PIXI;
    self.app = null;
    self.container = null;
	self.result = [];
	self.busted = false;
	self.timer = [];
	self.balls = [];
	self.bockets = [];
	self.engine = null;
	self.renderer = null;
	self.ticker = null
	self.stage = null

    self.init = function (e) {
    	self.engine = e;

		engine = Engine.create();
		Engine.run(engine);
		Events.on(engine, 'collisionStart', self.collisionStart);
		engine.world.gravity.y = 1.2;

        self.renderer = new self.pixi.Renderer({
            view: document.getElementById("game"),
			width: 500,
			height: 500,
			transparent: true
        });

        if(isMobile())
        {
            self.renderer.resize(250, 250);
        }

		self.stage = new self.pixi.Container();
        self.container = new self.pixi.Container();
		self.container.sortableChildren = true;
        self.stage.addChild(self.container);

		const ticker = new self.pixi.Ticker();
		ticker.add(animate)
		ticker.start();
		self.ticker = ticker;
		
		let count = 0;

		// Start Animation
        function animate(delta){
			count += 0.1;
			
			if(self.busted) return;
				
			 let bodies = Composite.allBodies(engine.world);
			  bodies.forEach((n, i) => {
				let render = n.render;
				if (!render.visible) {
				  return;
				}
				
				if (['ball'].includes(n.label)) {
					if(n.label === 'ball'){
						self.balls[n.id].x = n.position.x
						self.balls[n.id].y = n.position.y
					}
				}
			  });

		
			self.renderer.render(self.stage);
		}

		self.start();	

    };
	
    self.start = function(){
        const container = new self.pixi.Container();
		container.sortableChildren = true;
        self.stage.addChild(container);
        self.container = container;

		self.addWall(10)
		self.addWall(self.renderer.width - 10)

		var n = 8;
			for (let i = 0; i <= n; i++) {
			for (let j = 0; j <= i; j++) {
				var x = 0.50 + (j - (i / 2)) / (10);
				var y = (i + 1) / (8);
				if(i !== 0)
					self.addPlinko(100 * x * 5, 400 * y - 50);
			}
		}
		
		for(var i = 0; i < 9; i++){
			var x = 72 + i * 45
			var y = self.renderer.height - 40
			self.addBocket(x, y, i)
		}
    }
	
    self.play = function(){
		self.addBall(self.renderer.width / 2, 10);
    }
	
	self.addPlinko = function(x, y){
		let options = { 
			isStatic: true,
			friction: 0,
			label: 'plinko'
		}

		let radius = 10
		let width = 18
		let height = 18

        if(isMobile())
        {
        	radius = 5;
            x /=2
            y /=2
			width = 9
			height = 9
        }
		
		let body = Bodies.circle(x, y, radius, options);
		self.addBody(body)
		
		var c = PIXI.Sprite.from('/assets/images/pin.png');
			c.x = body.position.x;
			c.y = body.position.y;
			c.width = width;
			c.height = height;
			c.anchor.set(0.5);
			c.sortChildren(1);
			c.zIndex = 1;
		self.container.addChild(c);
    }
	
	self.addBocket = function(x, y, t){
        if (t === 4)
            t = 0.5
        else if (t === 5)
            t = 1
        else if (t === 6)
            t = 1.1
        else if (t === 7)
            t = 2.1
        else if (t === 8)
            t = 5.6
        else if (t === 3)
            t = 1.0
        else if (t === 2)
            t = 1.1
        else if (t === 1)
            t = 2.1
        else if (t === 0)
            t = 5.6
		
		let options = { 
			isStatic: true,
			label: 'bocket'
		}

		let width = 50;
		let height = 48;

        if(isMobile())
        {
            x /=2
            y = self.renderer.height - 15
			width = 25;
			height = 24
        }
		
		let body = Bodies.rectangle(x, y, width, height, options);
		self.addBody(body)
		
		var block = new self.pixi.Container();
		block.sortableChildren = true;
		block.id = body.id;
		block.score = t.toFixed(2);
		
		let c = PIXI.Sprite.from('/assets/images/bocket.png');
			c.x = body.position.x;
			c.y = body.position.y;
			c.width = width;
			c.height = height;
			c.anchor.set(0.5);
			c.sortChildren(3);
			c.zIndex = 1;
			

		let font = 12;
		let xx = 15;
		let yy = 9

        if(isMobile())
        {
        	font = 8
        	xx = 7.5
        	yy =  4.5
        }
		
		const style = new PIXI.TextStyle({
			fontFamily: 'tahoma',
			fontSize: font,
			fill: '#000',
			stroke: '#4a1850',
			dropShadow: true,
			dropShadowColor: '#000000',
			dropShadowBlur: 5,
			dropShadowAngle: Math.PI / 4,
			dropShadowDistance: 1,
		});

		let n = new PIXI.Text(t.toFixed(2) + 'x', style);
			n.x = c.x - xx
			n.y = c.y - yy
			n.sortChildren(4);
			n.zIndex = 2;
				
		block.addChild(c, n);
		self.container.addChild(block)
		self.bockets[block.id] = block
    }
	
	self.addBall = function(x, y){

		let rest = 0.59;
		let r1 = -0.05
		let r2 = 0.05

        if(isMobile())
        {
			rest = 0.49;
			r1 = -0.03
			r2 = 0.03
        }

		let options = { 
			restitution: rest,
			torque: random(r1, r2),
			label: 'ball',
        	friction: 0.1,
		}


		let radius = 10;
		let width = 38;
		let height = 42
		let rand1 = -1.5
		let rand2 = 1.5

        if(isMobile())
        {
        	radius = 5
			width = 15
			height = 16
			rand1 = -1.2
			rand2 = 1.2
        }
		
		let body = Bodies.circle(x + random(rand1, rand2), y, radius, options);
		self.addBody(body)
		
		var c = self.pixi.Sprite.from('/assets/images/ball.png');
			c.x = body.position.x;
			c.y = body.position.y;
			c.width = width;
			c.height = height;
			c.anchor.set(0.5);
			c.sortChildren(2);
			c.zIndex = 1;
			c.interactive = true;
		self.container.addChild(c);
		self.balls[body.id] = c;
    }
	
	
	self.addWall = function(x){
		var body = Bodies.rectangle(x, self.renderer.height, 10, self.renderer.height, {isStatic: true});
		self.addBody(body)
	}
	
	self.addBody = function(...bodies){
		World.add(engine.world, ...bodies);
	}
	
	self.removeBody = function(...bodies){
		World.remove(engine.world, ...bodies);
	}
	
	self.collisionStart = function({ pairs }, timestamp, source, name){
		pairs.forEach(({ bodyA, bodyB }) => {
			[bodyA, bodyB].forEach(body => {
				if(body.label === 'plinko'){
					playAudio('ding.mp3');
				}

				if (body.label === 'bocket') {
					self.sendScore(self.bockets[body.id].score)
					playAudio('win.mp3');
					let unique = body.id + random(5, 100)
					self.timer[unique] = setInterval(function () {
						if(self.bockets[body.id]){
							self.bockets[body.id].y -= 1;
							wait(50).then(() => {
								self.bockets[body.id].y = 0
								clearInterval(self.timer[unique]);
							})
						}
					}, 10);
				}
				
				if(body.label === 'ball'){

					let down = self.renderer.height - 100;

					if(isMobile()){
						down = self.renderer.height - 50;
					}

					let finish = down
					if(self.balls[body.id].y >= finish){
						self.container.removeChild(self.balls[body.id]);
						self.removeBody(body);
					}
				}
			});
		  });
	}

	self.sendScore = function(score){
	    const e = self.engine;
	    e.init = true;
	    e.bonus = parseFloat(score);
		e.busted();
	}

    self.destroy = function(){
		self.ticker.stop()
		self.renderer = null;
		self.container = null;
		self.stage = false;
	}
	
}

export default Canvas;