import React from "react";
import {connect} from "react-redux";
import {setGif} from "../../../../actions/gameChat";
import storage from "../../../../Storage";
import { SITE, GIPHY_API } from "../../../../Helper";
import axios from "axios";

class Gif extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            show: false
        }
    }

    handleClick = (e) => {
        e.preventDefault();
        this.setState({ show: !this.state.show })
    }

    render(){

        let logged = storage.getKey('logged') !== null ? true : false

        return(
            <>

            { logged === true ?
                <button className="btn btn-send-chat btn-block" onClick={this.handleClick}><i className="mdi mdi-image-area-close font-20"/></button>
                :
                <button type="button" className="btn btn-block btn-send-chat"><i className="mdi mdi-image-area-close font-20"/></button>
            }

            {
                this.state.show &&
                <div className="gif-container animated fadeInUp">
                    <GifList clicked={this.handleClick} props={this.props} />
                </div>
            }

            </>
        );
    }
}

class GifList extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            gifs: []
        }
    }

    componentDidMount() {
        let self = this;

        axios({
            url: SITE + "/trending.json",
            method: 'GET',
        })
        .then(function(req) {
            let response = req.data;
            let trending = response.data;
            trending.forEach((item, i) => {
                self.setState(state => ({ gifs: [item, ...state.gifs] }));
            });
        });
    }

    render() {
        const gifs = this.state.gifs.map((item, i) =>
            <MakeGif key={i.toString()} redux={this.props} image={item.images.downsized.url} />
        );
        return gifs;
    }
}

function MakeGif(props){

    const { image } = props;

    const set = (e) => {
        props.redux.props.setGif(image);
        props.redux.clicked(e);
    }

    return (
            <>
                <img onClick={set} src={image} className={'img-fluid'} alt="GIF"/>
            </>
        );
}

export default connect(null, { setGif })(Gif);