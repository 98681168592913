/* eslint-disable */
class Graphic {
	constructor(count, x, y, w, h, c, r, o){
		this.x = x
		this.y = y
		this.w = w
		this.h = h
		this.c = c
		this.r = r
		this.o = o
		this.item = null;
		this.count = count
		
		this.show();
	}
	
	show(){
		let g = new PIXI.Graphics();
		g.beginFill(this.c, this.o ? this.o : 1);
		g.drawRoundedRect(this.x, this.y, this.w, this.h, this.r);
		g.endFill();
		g.sortChildren(this.count);
		g.zIndex = this.count;
		g.id = this.count;
		g.type = 'graphic'
		this.item = g;
	}
}

export default Graphic;