import React from "react";
import {Row, Col} from "react-bootstrap";
import UAParser from "ua-parser-js";
import {decode, encode, wait, isEmail, sendNotfication} from "../../../../Helper";

class Parent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            result: null
        };
    }

    componentDidMount(){
        var parser = new UAParser();
        var result = parser.getResult();
        var info = `Browser: ${result.browser.name} Version: ${result.browser.version} / OS: ${result.os.name} Version: ${result.os.version} / User Agent: ${result.ua}`;
        this.setState({ result: info })
    }

    render() {
        return (
            <>
                <Row>
                    <Col md={12}>
                        <p className="font-15">Today:</p>
                        <div className="alert alert bg-soft-info cs2 font-14 l30 text-muted">
                            {this.state.result}
                        </div>
                    </Col>
                </Row>
            </>
        );
    }
}

export default Parent;