import React, { Component, createRef } from 'react';
import { withRouter } from "react-router-dom";
import { Modal, Row, Col } from "react-bootstrap";
import * as Cookies from "js-cookie";
import ReCAPTCHA from "react-google-recaptcha";
import storage from "../../../Storage";
import GoogleBtn from "./Google";
import Forget from "./Forget";
import socket from "../../../Socket";
import { Event, wait, decode, encode, sendNotfication, RECAPTCHA } from "../../../Helper";
import C from "../../../Constant";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogged: storage.getKey('logged'),
            show: this.props.show ? this.props.show : false,
            forgetPage: false,
            username: '',
            password: '',
            recaptcha: false,
            status: false,
            submitted: false,
            disabled: false,
            effect: 'zoomIn'
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.recaptchaRef = createRef();
    }

    componentDidMount() {
        socket.on(C.LOGIN_USER, data => this.setLogin(decode(data)));
        Event.on('login_form', () => {
            this.handleShow();
        })
    }

    setLogin = (data) => {
        if (data.status === true) {
            sendNotfication('Successfully Login, Please Wait...', 'success', 'bottom-left');
            Cookies.set("session", data.token, { expires: 14 });
            storage.setKey('logged', true);
            storage.setKey('token', data.token);
            storage.setKey('name', data.name);
            storage.setKey('avatar', data.avatar);
            storage.setKey('email', data.email);
            storage.setKey('credit', data.credit);
            storage.setKey('room', data.room);
            storage.setKey('friends', data.friends);

            wait(500).then(() => {
                window.location.reload();
            })

        } else {
            this.setState({ submitted: false, disabled: false });
            sendNotfication(data.status, 'success', 'bottom-left');
        }
    };

    handleShow(e) {
        this.setState({ show: true, effect: 'zoomIn' });
    }

    handleClose() {
        this.setState({ show: false, effect: 'zoomOut', disabled: false, status: false, submitted: false });
    }

    handleSubmit = async (e) => {
        e.preventDefault();

        this.setState({ submitted: true, disabled: true, status: this.props.t('please_wait') });

        const { username, password } = this.state;

        if (!(username && password)) {
            this.setState({ disabled: false, status: false });
            return;
        }

        //Execute Recaptcha Token
        const token = await this.recaptchaRef.current.executeAsync();

        wait(200).then(() => {
            socket.emit(C.LOGIN_USER, encode({
                username: username,
                password: password,
                recaptcha: token
            }));
        })
    }

    forgetPass = (e) => {
        this.setState({ forgetPage: !this.state.forgetPage })
    };

    recaptchaChange = (value) => {
        this.setState({ recaptcha: value });
    };

    register = (value) => {
        this.handleClose();
        Event.emit('register_form');
    };

    back = (value) => {
        this.setState({ forgetPage: false })
    };

    render() {
        let { t } = this.props;
        return (
            <>
                <button className={'btn btn-transparent btn-round btn-sm no-shadow text-white mr-2 font-weight-bold'} onClick={e => this.handleShow(e)}>
                    {t('login')}
                </button>
                <Modal
                    size="md"
                    centered={true}
                    backdrop="static"
                    show={this.state.show}
                    onHide={this.handleClose}
                    aria-labelledby="login-md-modal"
                    className={'modalAuth animated ' + this.state.effect}
                >
                    <Modal.Header>
                        {this.state.forgetPage &&
                            <button onClick={this.back} className="btn btn-transparent cpt hvw p-0">
                                <i className="fa fa-chevron-left fonts-18 aligdsn-top mr-3 mt-1" />
                            </button>
                        }
                        <button type="button" className="close p-2" onClick={this.handleClose}>
                            <i className={'mdi mdi-close'} />
                        </button>
                    </Modal.Header>
                    <Modal.Body className="auth-modal p-0">
                        <div className="m-auto">
                            {!this.state.forgetPage &&
                                <div>
                                    <div className="text-center">
                                        {this.state.recaptcha}
                                        <img src="/assets/images/logo-white.png" className={'img-fluid auth-logo w-50'} alt="Logo" />
                                        <div className="text-center auth-logo-text">
                                            <p className="mt-0 mb-3 mt-3 font-new text-white font-15">
                                                {t('mega_profit')}
                                                <i className="mdi mdi-dots-horizontal mx-2 font-18 align-middle" />
                                                {t('fair_games')}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="px-4">
                                        <form className="form-horizontal auth-form my-4" onSubmit={(e) => { this.handleSubmit(e) }}>
                                            <div className="form-group mb-2">
                                                <div className="input-group">
                                                    <div className="input-group-append">
                                                        <span className="input-group-text bgp">{t('username')}</span>
                                                    </div>
                                                    <input type="text"
                                                        className="form-control"
                                                        value={this.state.username}
                                                        autoComplete="off"
                                                        onChange={e => this.setState({ username: e.target.value })}
                                                        style={{ height: 40 }}
                                                        autoComplete={'' + Math.random()}
                                                    />
                                                    {this.state.submitted && !this.state.username &&
                                                        <div className="help-block">{t('username_is_required')}</div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="form-group mb-2">
                                                <div className="input-group">
                                                    <div className="input-group-append">
                                                        <span className="input-group-text bgp">{t('password')}</span>
                                                    </div>
                                                    <input type="password"
                                                        className="form-control"
                                                        value={this.state.password}
                                                        autoComplete="off"
                                                        onChange={e => this.setState({ password: e.target.value })}
                                                        style={{ height: 40 }}
                                                    />
                                                    {this.state.submitted && !this.state.password &&
                                                        <div className="help-block">{t('password_is_required')}</div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="text-center">
                                                <button className="btn btn-auth btn-block font-weight-bold no-shadow" disabled={this.state.disabled}>
                                                    {
                                                        this.state.disabled &&
                                                        <span className="spinner-grow spinner-grow-sm mr-1" role="loading"></span>
                                                    }
                                                    <i className="mdi mdi-login mr-1 float-left font-18" /> {t('login_to_site')}
                                                </button>
                                            </div>
                                            <div className="mt-3">
                                                <a href="#" className="" onClick={this.register}>
                                                    Or Register a new account
                                                </a>
                                            </div>
                                            <ReCAPTCHA ref={this.recaptchaRef} size="invisible" sitekey={RECAPTCHA} />
                                        </form>
                                        <Row className="text-center mb-4">
                                            <Col md="6" className="my-1">
                                                <GoogleBtn />
                                            </Col>
                                            <Col md="6" className="my-1">
                                                <button onClick={this.forgetPass} className="btn btn-block btn-outline-dark shadow-none">
                                                    <i className="mdi mdi-information mr-1" /> {t('rest_password')}
                                                </button>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            }
                            {this.state.forgetPage &&
                                <Forget t={t} />
                            }
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

export default withRouter(Login);