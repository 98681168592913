function Card(card) {
    this.getRank = function () {
        return card.rank;
    };
    this.getSuit = function () {
        return card.suit;
    };
    this.getValue = function () {
        var rank = this.getRank(),
            value = 0;
        if (rank === "A") {
            value = 11;
        } else if (rank === "K") {
            value = 10;
        } else if (rank === "Q") {
            value = 10;
        } else if (rank === "J") {
            value = 10;
        } else {
            value = parseInt(rank, 0);
        }
        return value;
    };
}

export default Card;