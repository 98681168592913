/* eslint-disable */
import React from 'react';
import {wait, chkd, isMobile, getRandomInt, Event, playAudio} from "../../../Helper";
import C from "../../../Constant";
import storage from "../../../Storage";
import Canvas from "./components/Canvas";

let loaded = false;

class Game extends React.Component {
    _isMounted = false;
    constructor(props){
        super(props);
        this.state = {
            engine: this.props.engine,
            token: storage.getKey('token'),
            isLogged: storage.getKey("logged") !== null ? true : false,
            canvas: null
        }
    }

    componentDidMount() {
        this._isMounted = true;
        let { engine } = this.state;

        if(this._isMounted){
            this.setUpCanvas();
            engine.on(C.PLAY_ROULETTE, () => this.play());
            engine.on(C.BUSTED_ROULETTE, (data) => this.endGame(data));
            Event.on('coin_changed', (c) => this.coinChanged(c));
            Event.on('reset_table', () => this.resetTable());
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.destroy();
    }

    destroy = () => {
        this.setState({ engine: null, canvas: null });
    }

    setUpCanvas(){
        if(this._isMounted){
            let canvas = new Canvas(this.state.engine, this.state.isLogged)
            this.setState({ canvas: canvas })
            canvas.init();
            canvas.changeChip(storage.getKey('coin') !== null ? storage.getKey('coin') : 'BTC');
        }
    }

    coinChanged(c){
        if(this._isMounted){
            this.state.canvas.changeChip(c);
        }
    }

    resetTable(){
        if(this._isMounted){
            this.state.canvas.reset();
        }
    }

    play = () => {
        if(this._isMounted){
            this.state.canvas.play();
        }
    };

    endGame(data){
        if(this._isMounted){
        }
    }

    render() {
        return(
            <div className="_mine_container animated fadeIn">
                <div className="_cf_info _cf_info_m">
                    <div className="text-center" id="cc">
                        <canvas id="game" />
                    </div>
                </div>
            </div>
        );
    }
}

export default Game;