import React from "react";
import {Col, Row, Card} from "react-bootstrap";
import {forceSatoshiFormat} from "../../../../Helper";

const birthday = "September 25, 2021 00:00:00";

class Contest extends React.Component {
    _isMounted = false;

    constructor(props){
        super(props);
        this.state = {
            loading: false,
            fund: 0
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.timeRemainingUpdate();
        this.updateFund();
    }

    componentWillMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    updateFund = () => {
        if(this._isMounted)
        {
            let self = this;
            var counter = 0;
            var interval = setInterval(function() {
                counter++;
                var current = Date.now() / 100000000000000;
                var s = current.toString().substr(4, 10);
                var n = Number(s) / 10000000000
                var c = n.toString().substr(0, 11)
                self.setState({ fund: forceSatoshiFormat(c / 2) });
            }, 1000);
        }
    };

    timeRemainingUpdate = () => {
        if(this._isMounted)
        {
          let self = this;
          var second = 1000,
                minute = second * 60,
                hour = minute * 60,
                day = hour * 24,
				countDown = new Date(birthday).getTime();
				
              var x = setInterval(function() {
                let now = new Date().getTime(),
                     distance = countDown - now;
                    self.setState({ days: Math.floor(distance / (day)) })
                    self.setState({ hours: Math.floor((distance % (day)) / (hour)) })
                    self.setState({ minutes: Math.floor((distance % (hour)) / (minute)) })
                    self.setState({ seconds: Math.floor((distance % (minute)) / second) })
                if (distance < 0)  {
                  clearInterval(x);
                }
              }, 0)
      }
    };

    render() {
        return(
                <Row>
                    <Col sm={12}>
                        <div className="contests">
                                    <Card className="bg-transparent mb-0">
                                        <Card.Body className="py-0">
                                            <Row>
                                                <Col xl="6" md="6" sm="12">
                                                </Col>
                                                <Col xl="6" md="6" sm="12" className="">
                                                    <div className="text-right">
                                                        <div className="con t-warning">
                                                            <h5 className="text-">BANK SAVING FUND</h5>
                                                            <h2><b>{this.state.fund}</b> <span className="text-warning">BTC</span></h2>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                        </div>
                    </Col>
                </Row>
        );
    }
}

export default Contest;