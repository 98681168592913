import React from 'react';
import {Helmet} from "react-helmet";
import {Row, Col, Card} from "react-bootstrap";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import BankRoll from "../../Components/Game/BankRoll";
import Queue from "../../Components/Game/Queue";
import TopBarGames from "../../Components/Game/Addons/TopBar";
import Game from "./Game";
import Bet from "./Bet";
import Engine from "./Engine";
import {isMobile, wait, Event} from "../../../Helper";

class Main extends React.Component {
    _Mounted = false;
    constructor (props){
        super(props);
        this.state = {
            engine: new Engine(),
            padding: "p-1"
        };
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        this._Mounted = true;
        let { engine } = this.state;
        
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        // Start Engine
        engine.started = true;

        wait(600).then(() => {
            this.handleResize();
        });
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        let { engine } = this.state;
        window.removeEventListener('resize', this.handleResize);
        this._Mounted = false;

        // Stop Engine
        engine.started = false;
        engine.trigger = null;
        engine.off()
    }

    handleResize(){
        let clientWidth = document.documentElement.clientWidth || document.body.clientWidth;

        if(clientWidth < 1540)
        {
            this.setState({ col: 'col-xl-12'});
            Event.emit('hide_games');
        }
        else {
            this.setState({ col: 'col-xl-9'});
            Event.emit('show_min_games');
        }
        if(isMobile()){
            this.setState({ padding: 'p-0', ovh: 'ovh' });
        }
    }

    render() {
        let { engine, col, padding, ovh } = this.state;

        const help = <p className="text-white">Goal is simple, place your bet amount and choose your risk variance. As you progress to the goal you will the multiplier amount listed at the bottom of each column. You can cash our whenever you want but be careful of the mines! When you kick the ball on a mine you lose the bet. </p>;
        return (
            <>
                <Helmet>
                    <title>Goal - Crypto Casino Games</title>
                    <meta name="keywords" content="Crypto Goal Game, Online Goal Game, Bitcoin Goal Game, Blockchain Goal Game, Best Crypto Goal Game, Coingator.io Goal"/>
                    <meta name="description" content="Crypto Goal Game is simple, place your bet amount and choose your risk variance. As you progress to the goal you will the multiplier amount listed at the bottom of each column." />
                    <meta name="og:title" content="Play Online Goal - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <meta name="og:description" content="Play Online Goal - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <link rel="canonical" href="https://Coingator.io/goal" />
                </Helmet>
                <ReactCSSTransitionGroup
                    transitionAppear={true}
                    transitionAppearTimeout={250}
                    transitionEnterTimeout={200}
                    transitionLeaveTimeout={200}
                    transitionName={'SlideIn'}
                >
                    {this._Mounted ?
                    <Row className={ "animated fadeIn " + ovh}>
                        <div className="d-none">Crypto Goal Game Description: {help}</div>
                        <Col sm={12} className={'m-auto ' + col + ' ' + padding}>

                            {/* MENUS */}
                            <Card className="mb-0">
                                <Card.Body className="px-2 py-3">
                                    <Row>
                                        <Col md={6} className={'col-6 text-left'}>
                                            <BankRoll game={'goal'}/>
                                        </Col>
                                        <Col md={6} className={'col-6 text-right'}>
                                            <TopBarGames help={help} image={"/assets/images/covers_new/__goal.png"}/>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                            {/* GAME */}
                            <Card className="mb-0">
                                <Card.Body className={'p-1 py-2 goal-face'} id="roll-panel">
                                    <Row>
                                        <Col sm={12} md={10} className={'m-auto'}>
                                             <Game engine={engine} />  
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                            {/* BETS */}
                            <Card className="mb-0">
                                <Card.Body className={padding}>
                                    <Row>
                                        <Col sm={12} md={12} className={'m-auto'}>
                                           <Bet engine={engine} />
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                            {/* QUEUE */}
                            <Card className="mb-5">
                                <Card.Body className="p-1">
                                    <Row>
                                        <Col sm={12} md={12} className={'m-auto px-1'}>
                                            <Queue t={this.props.t} game={'goal'}/>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                        </Col>
                    </Row>
                    :
                    <div className="game-loader text-center">
                        <div className="spinner-grow text-info my-3" role="status"/>
                    </div>
                    }
                </ReactCSSTransitionGroup>
            </>
        );
    }
}

export default Main;