import React from 'react';
import {Card, Row, Col} from "react-bootstrap";
import Canvas from "./Canvas";
import Queue from "./Queue";
import History from "./History";
import Bet from "./Bet";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import {Helmet} from "react-helmet";
import BankRoll from "../../Components/Game/BankRoll";
import {wait, Event} from "../../../Helper";
import Help from "./Help";
import Engine from "./Engine";

class Game extends React.Component {
    _Mounted = false;
    constructor(props) {
        super(props);
        this.state = {
            engine: new Engine(),
            mobile: false
        };
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount(){
        let { engine } = this.state;
        
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        // Start Engine
        engine.started = true;
        
        wait(600).then(() => {
            this._Mounted = true;
            this.handleResize();
        });
        window.addEventListener('resize', this.handleResize);

        if(this.isMobile()){
            this.setState({ height: "unset", mobile: true });
        }
        else {
            this.setState({ height: this.getHeight(), mobile: false });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
        this._Mounted = false;

        let { engine } = this.state;

        // Stop Engine
        engine.started = false;
        engine.trigger = null;
        engine.off()
    }

    handleResize(){
        if(this.getWidth() < 1540)
        {
            this.setState({ col: 'col-xl-12'});
            Event.emit('hide_games');
        }
        else {
            this.setState({ col: 'col-xl-9'});
            Event.emit('show_min_games');
        }

        if(this.isMobile()){
            this.setState({ mobile: true, ovh: 'ovh' });
        }
        else {
            this.setState({ mobile: false });
        }
    }

    getHeight(){
        return document.documentElement.clientHeight || document.body.clientHeight;
    }

    getWidth(){
        return document.documentElement.clientWidth || document.body.clientWidth;
    }

    isMobile(){
        return this.getWidth() < 992
    }

    render() {
        let { engine, col, ovh } = this.state;
        return (
            <>
                <Helmet>
                    <title>Keno - Crypto Casino Games</title>
                    <meta name="keywords" content="Crypto Keno Game, Online Keno Game, Bitcoin Keno Game, Blockchain Keno Game, Best Crypto Keno Game, Coingator.io Keno"/>
                    <meta name="description" content="Play Online Keno - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <meta name="og:title" content="Play Online Keno - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <meta name="og:description" content="Play Online Keno - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <link rel="canonical" href="https://Coingator.io/keno" />
                </Helmet>
                <ReactCSSTransitionGroup
                    transitionAppear={true}
                    transitionAppearTimeout={250}
                    transitionEnterTimeout={200}
                    transitionLeaveTimeout={200}
                    transitionName={'SlideIn'}
                >
                    {this._Mounted ?
                    <Row className={ "animated fadeIn " + ovh}>
                            <Col sm={12} className={'m-auto ' + col}>
                                <Card className="mb-0">
                                <Card.Body className="px-2 py-3">
                                        <Row>
                                            <Col md={6} className={'col-6 text-left'}>
                                                <BankRoll game={'keno'}/>
                                            </Col>
                                            <Col md={6} className={'col-6 text-right'}>
                                                <History engine={engine} />
                                                <Help/>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={12} className={'m-auto ' + col}>
                                <Canvas engine={engine} />
                            </Col>
                            <Col sm={12} className={'m-auto ' + col}>
                                <Card className="mb-0">
                                    <Card.Body className="p-1">
                                        <Row>
                                            <Col sm={12} md={12} className={'text-left'}>
                                                <Bet engine={engine} mobile={this.state.mobile}/>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                                <Col sm={12} className={'m-auto ' + col}>
                                    <Card className="mb-0">
                                        <Card.Body className="py-0 px-2">
                                            <Queue game={'keno'} engine={engine} t={this.props.t}/>
                                        </Card.Body>
                                    </Card>
                                </Col>
                        </Row>
                        :
                        <div className="game-loader text-center">
                            <div className="spinner-grow text-info my-3" role="status"/>
                        </div>
                    }
                </ReactCSSTransitionGroup>
            </>
        );
    }
}

export default Game;