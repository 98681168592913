import { SET_HEADS_OR_TAILS_RESULT, SET_CLASSIC_DICE_RESULT, SET_BOMB_WIRE } from './types';

export const setHeadsOrTailsResult = (result) => dispatch => {
    dispatch({
        type: SET_HEADS_OR_TAILS_RESULT,
        payload: result
    })
};

export const setClassicDiceResult = (result) => dispatch => {
    dispatch({
        type: SET_CLASSIC_DICE_RESULT,
        payload: result
    })
};

export const setBombWire = (count) => dispatch => {
    dispatch({
        type: SET_BOMB_WIRE,
        payload: count
    })
};