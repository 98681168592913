import React from "react";
import { Link } from "react-router-dom";
import {Col, Row, Card, Table} from "react-bootstrap";
import UserModal from "../Components/User/Stat/Modal";
import {__, isMobile, Game, toUSD} from "../../Helper";
import socket from "../../Socket";
import C from "../../Constant";

class Winners extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            players: []
        }
    }

    componentDidMount() {
        socket.emit(C.TOP_WINNERS);
        socket.on(C.TOP_WINNERS, data => this.makeList(data));
    }

    makeList = (data) => {
        this.setState({ loading: false, players: data });
    };

    render(){
        const { t } = this.props;
        const list = this.state.players.map((player, i) => <Players key={__.toString(i)} medal={i + 1} player={player} />)
       
        return(
                this.state.loading ?
                    <div className="text-center">
                        <div className="spinner-grow text-white my-3" role="status"/>
                    </div>
                :
                <div className={'table-responsive last-bets num-style mb-0 tww'}>
                    <Table className={"mb-2"}>
                        <thead>
                            <tr>
                            { !isMobile() &&
                                 <th>#</th>
                            }
                            <th>
                                {t('username')}
                            </th>
                            <th>
                                {t('wagered')}
                            </th>
                            <th>
                                {t('prize')}
                            </th>
                            <th>
                                {t('medal')}
                            </th>
                            </tr>
                        </thead>
                        <tbody>{list}</tbody>
                    </Table>
                </div>
        );
    }
}

function Players(props){
        let { player, medal } = props;
        let num = medal;
        
        if(medal === 10)
            medal = 1

        let username = player.name;
        let avatar = player.avatar;

        const coins = ['btc', 'eth', 'bch', 'usdt', 'ltc', 'trx', 'bnb', 'doge', 'xrp', 'ada', 'usdp', 'usdc', 'mkr', 'nexo', 'tusd', 'busd'];
        var highArr = [];
        var plusProfit = 0;

        coins.forEach((coinName, i) => {
            var np = !__.isUndefined(player.profit[coinName]) ? player.profit[coinName] : 0;
            np = __.toNumber(np);

            if(np > 0 && np !== NaN)
            {
                var convertToUsd = np * __.toNumber(Game[__.upperCase(coinName)]);
                plusProfit += __.toNumber(convertToUsd);
                highArr.push(plusProfit);
            }
        });

        let total = __.sum(highArr);

        if(total === 0) return null;

        let wagered = toUSD(total);
        let prize = toUSD(__.toNumber(total/7));

        if(__.isNaN(wagered)) return null
        if(wagered === NaN) return null

        if(parseFloat(wagered) === 0) return null;

        return(
            <tr className={'q-crash2'}>
                { !isMobile() &&
                    <td>{num}</td>
                }
                <td>
                    <img className="rounded thumb-xs mr-1" src={avatar} /> 
                    <UserModal id={player.id} username={username} isWinner={false} />
                </td>
                <td className={'num-style text-success'}>
                    {wagered}
                </td>
                <td className={'num-style text-success'}>
                    {prize}
                </td>
                <td>
                    <img className="rounded-circle thumb-xs ffi" src={'/assets/images/badges/badge-' + medal + '.svg'} /> 
                </td>
            </tr>
        );
}

function sortByProfit(input) {
    function r(c) {
        return c.profit ? - c.profit : null;
    }
    return __.sortBy(input, r);
}

export default Winners;