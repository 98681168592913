/* eslint-disable */
import Graphic from "../Graphic/index.js";
import Sprite from "../Sprite/index.js";
import { wait, lerp, backout, rand, isMobile } from "../Helper/index.js";

var SYMBOL_SIZE = 50;

if(isMobile())
	SYMBOL_SIZE = 25

var count = 0;
	
var locked = false;

function Animate() {}

Animate.prototype.render = function (delta, obj) {
    let self = obj;

    count += 0.05;

	// Message Animate
    if (self.showMessage) {
        wait(2000).then(() => {
            self.message.x += 2 * (count * Math.PI + delta);
        });
        if (self.message.x >= self.width) {
            self.stage.removeChild(self.message);
            self.showMessage = false;
        }
    }
	
	// Trophy Animate
    if (self.isWinner) {
        wait(2000).then(() => {
			self.winItem.visible = true
			self.winText.visible = true
			count += 0.5;
			
			self.winItem.scale.x += Math.min(1.1, 0.1 * delta)
			self.winItem.scale.y += Math.min(1.1, 0.1 * delta)
			
			if(self.winItem.scale.x > 1.1){
				self.winItem.scale.x = 1.1
				self.winItem.scale.y = 1.1
			}
			
			wait(2000).then(() => {
				self.winItem.x +=  0.4 * count
				self.winText.x -= 0.4 * count
				wait(1500).then(() => {
					self.stage.removeChild(self.winItem);
					self.stage.removeChild(self.winText);
					self.isWinner = false;
				});
			});
        });
    }

	// Waiting Animate
    if (self.timerShowing) {
        let timer = (1 - new Date().getTime()) / 1e3;
        timer = timer <= 0 ? "0.0" : timer.toFixed(1);
        self.timer.text = timer + "s Starting Round...";
    }

	// Roulette Animate
    for (var i = 0; i < self.reels.length; i++) {
        var r = self.reels[i];
        r.blur.blurX = (r.position - r.previousPosition) * 8;
        r.previousPosition = r.position;

        //Update symbol positions on reel.
        for (var j = 0; j < r.symbols.length; j++) {
			
			if(!isMobile()){
				var n = r.symbols[j];
				n.width = 50;
				n.height = 50;
				n.y = 60;
				n.scale.x = 0.2;
				n.scale.y = 0.2;
				n.x = ((r.position + j) % r.symbols.length) * SYMBOL_SIZE - SYMBOL_SIZE * 2.7 + 25

				var b = r.backend[j];
				b.width = 50;
				b.height = 50;
				b.x = ((r.position + j) % r.symbols.length) * SYMBOL_SIZE - SYMBOL_SIZE * 2.7;
			}
			else {
				var n = r.symbols[j];
				n.height = 25;
				n.width = 25;
				n.y = 35;
				n.scale.x = 0.2;
				n.scale.y = 0.2;
				n.x = ((r.position + j) % r.symbols.length) * SYMBOL_SIZE - SYMBOL_SIZE * 2.7 + 13

				var b = r.backend[j];
				b.width = 25;
				b.height = 25;
				b.y = 5;
				b.x = ((r.position + j) % r.symbols.length) * SYMBOL_SIZE - SYMBOL_SIZE * 2.7;
			}
        }
    }
	
    //Second Update
    var now = Date.now();
    var remove = [];
    for (var i = 0; i < self.tweening.length; i++) {
        var t = self.tweening[i];
        var phase = Math.min(1, (now - t.start) / t.time);
		
        t.object[t.property] = lerp(t.propertyBeginValue, t.target, t.easing(phase));

		var result = this.calculate(t.target);
		
        if (t.change) t.change(t);

        if (phase == 1) {
			this.setWinner(result, self, count);
            t.object[t.property] = t.target;
            if (t.complete) t.complete(result);
            remove.push(t);
        }
    }
    for (var i = 0; i < remove.length; i++) {
        self.tweening.splice(self.tweening.indexOf(remove[i]), 1);
    }
};

Animate.prototype.setWinner = function (result, self, count) {
	var id;
	
	if(result > 0 && result <= 18){
		id = 0;
	}
	
	if(result === 0){
		id = 1;
	}
	
	if(result >= 19 && result <= 36){
		id = 2
	}
	
	let win = new Sprite(2, 'win.gif' , 1, 2).item;
	win.zIndex = 22222
	let mask = new Sprite(2, 'hg.png' , 1, -18).item;
	mask.zIndex = 22222
	mask.width = 160
	mask.height = 160
	self.table[id].zIndex = 222;
	self.table[id].addChild(win, mask);
	
	let playerWin = 0;
	
	if(!_.isUndefined(self.tableAmounts[id])){
		playerWin = parseFloat(self.tableAmounts[id]);
	}
	
	// Send Amount to Socket.
	self.event.emit('win', {playerWin, result});
	
	var t = 0.9
	var starter = setInterval(function () {
		count += 0.5;
		t -= 0.1
		
		mask.rotation += 0.01 * count
		
		win.scale.x = 1.2 + Math.sin(count) * 0.08;
		win.scale.y = 1.2 + Math.sin(count) * 0.08;
		
		wait(2500).then(() => {
			for(var i = 0; i < self.tem_chip.length; i++){
				self.container.removeChild(self.tem_chip[i]);
			}
			self.table[0].removeChildren()
			self.table[1].removeChildren()
			self.table[2].removeChildren()
			self.tableAmounts = [];
			self.table_amount = 0;
			self.amount_place.text = 0
			self.engine.data = [];
			self.playerStarted = false;
			clearInterval(starter);
		})
	}, 50);
}

Animate.prototype.calculate = function (r) {
	var res = r % 37
	
	var result;
	
	switch(res){
		case 1 :
			result = 28
			break;
		case 2 :
			result = 24
			break;
		case 3:
			result = 15
			break;
		case 4:
			result = 5
			break;
		case 5:
			result = 11
			break;
		case 6:
			result = 36
			break;
		case 7:
			result = 6
			break;
		case 8:
			result = 10
			break;
		case 9:
			result = 33
			break;
		case 10:
			result = 31
			break;
			
		case 11:
			result = 12
			break;
		case 12:
			result = 25
			break;
		case 13:
			result = 32
			break;
		case 14:
			result = 30
			break;
		case 15:
			result = 29
			break;
		case 16:
			result = 34
			break;
		case 17:
			result = 7
			break;
		case 18:
			result = 13
			break;
			break;
		case 19:
			result = 4
			break;
		case 20:
			result = 2
			break;
		case 21:
			result = 20
			break;
		case 22:
			result = 0
			break;
		case 23:
			result = 16
			break;
		case 24:
			result = 8
			break;
		case 25:
			result = 14
			break;
		case 26:
			result = 27
			break;
		case 27:
			result = 3
			break;
		case 28:
			result = 18
			break;
		case 29:
			result = 21
			break;
		case 30:
			result = 35
			break;
		case 31:
			result = 22
			break;
		case 32:
			result = 26
		case 33:
			result = 17
			break;
		case 34:
			result = 9
			break;
		case 35:
			result = 1
			break;
		case 36:
			result = 19
			break;
	}

	return result
}

export default Animate;