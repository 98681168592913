import React, { useEffect } from 'react';
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PropTypes from 'prop-types';
import {Helmet} from "react-helmet";
import clsx from 'clsx';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Zoom from '@material-ui/core/Zoom';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import UpIcon from '@material-ui/icons/KeyboardArrowUp';
import { green } from '@material-ui/core/colors';
import Box from '@material-ui/core/Box';
import Privacy from "./Privacy";
import UserAgreement from "./UserAgreement";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `action-tab-${index}`,
    'aria-controls': `action-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#0d131d',
    position: 'relative',
    minHeight: 200,
  }
}));

export default function FloatingActionButtonZoom() {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  
  useEffect(() => {
    window.scrollTo(0,0);

  let check = window.location.href.search("tab");
  if(check !== -1){
    let tab = window.location.href.split("tab")[1];
    tab = tab.replace('=', '');
    tab = parseFloat(tab);
    setValue(tab)
  }

  }, []);

  return (
        <>
    <Helmet>
        <title>Rules - Crypto Casino Games</title>
        <meta name="keywords" content="Crypto Slot Games, Online Slot Games, Bitcoin Slot Games, Blockchain Slot Games, Best Crypto Slot Games, Coingator.io Slot Games"/>
        <meta name="description" content="Play Online Slot Games - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
        <meta name="og:title" content="Play Online Slot Games - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
        <meta name="og:description" content="Play Online Slot Games - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
        <link rel="canonical" href="https://Coingator.io/rules" />
    </Helmet>
  <ReactCSSTransitionGroup
      transitionAppear={true}
      transitionAppearTimeout={250}
      transitionEnterTimeout={200}
      transitionLeaveTimeout={200}
      transitionName={'SlideIn'}
  >
    <div className={'container ' +  classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="action tabs example"
        >
          <Tab className="text-white" label="Privacy Policy" {...a11yProps(0)} />
          <Tab className="text-white" label="User Agreement" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
         <Privacy />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
        <UserAgreement />
        </TabPanel>
      </SwipeableViews>
    </div>
    </ReactCSSTransitionGroup>
    </>
  );
}