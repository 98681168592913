/* eslint-disable */
class Sprite {
	constructor(count, i, x, y, w, h, s){
		this.image = i
		this.x = x
		this.y = y
		this.width = w
		this.height = h
		this.scale = s
		this.item = null;
		this.count = count

		this.show();
	}
	
	show(){
		const sprite = PIXI.Sprite.from('/assets/images/roulette/' + this.image);
		sprite.x = this.x;
		sprite.y = this.y;
		if(this.width && this.height){
			sprite.width = this.width
			sprite.height = this.height
		}
		if(this.scale){
			sprite.scale.x = this.scale;
			sprite.scale.y = this.scale;
		}
		sprite.anchor.set(0.5);
		sprite.sortChildren(this.count);
		sprite.zIndex = this.count;
		sprite.id = this.count;
		sprite.type = 'sprite'
		
		if(this.image === 'BlackChip.png')
			sprite.isChip = true;
		else
			sprite.isChip = false;

		this.item = sprite;
	}
}

export default Sprite;