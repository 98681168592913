import React, { useEffect, useState, useRef } from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import $ from "jquery";
import rangeslider from "rangeslider.js";
import stroage from "../../../../Storage";
import "../../../../Static/css/range.css";
  
function CreditRange(props) {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  var credit = props.credit; // From Redux
  var currentValue = stroage.getKey('lam') !== null ? stroage.getKey('lam') : 0.00000100;

  const handleClick = (event) => {
    event.preventDefault()
    setShow(!show);
    setTarget(event.target);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
          setShow(false);
        }
    }
    document.addEventListener("mousedown", handleClickOutside);
      return () => {
        $('#range_c').rangeslider('destroy');
        document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

    const setupRange = () => {
      $('#range_c').rangeslider({
          polyfill: false,
          
          // Callback function
          onInit: function(e) {
          },
      
          // Callback function
          onSlide: function(position, value) {
            props.set(value)
          },
      
          // Callback function
          onSlideEnd: function(position, value) {}
      });
    }

    setTimeout(() => {
      setupRange();
    }, 1);

    const min = (e) => {
       e.preventDefault();
       let min =  0.00000010
       props.set(min)
       var r = $('#range_c');
       r.val(min).change();
    }

    const max = (e) => {
       e.preventDefault();
       let max = stroage.getKey('credit');
       props.set(max)
       var r = $('#range_c');
       r.val(max).change();
    }


  return (
    <div ref={ref}>
      <button onClick={handleClick} className={'btn bg-cs6 2 text-white btn-lg shadow-none h-100'}>
        <i className="mdi mdi-unfold-more-vertical font-15"/>
      </button>
      {show &&
        <div className="tooltip_credit d-flex animated fadeIn">
          <button className="btn bg-cs6 btn-xs text-white" onClick={min}>MIN</button>
          <div type="range" id="range_c" min="0.00000010" max={credit} value={currentValue} step="0.00000010" data-rangesliders />
          <button className="btn bg-cs6 btn-xs text-white" onClick={max}>MAX</button>
        </div>
      }
    </div>
  );
}

CreditRange.propTypes = {
    credit: PropTypes.string
};

const mapStateToProps = state => ({
    credit: state.items.credit
});

export default connect(mapStateToProps, { })(CreditRange);