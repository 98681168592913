/* eslint-disable */

class CardHold {
	constructor(x, y, w, h){
		this.x = x
		this.y = y
		this.w = w
		this.h = h
		this.hold = null
		this.text = null
	}
	
	add(){
		const graphics = new PIXI.Graphics();
		graphics.lineStyle(0);
		graphics.beginFill(0x000000, 0.35);
		graphics.drawRoundedRect(this.x, this.y, this.w, this.h, 5);
		graphics.endFill();
		graphics.sortChildren(9);
		graphics.zIndex = 5555;
		
		const style = new PIXI.TextStyle({
			fontFamily: 'tahoma',
			fontSize: 25,
			fill: '#FFF',
			fontWeight: 700,
			align: 'center',
			dropShadow: true,
			dropShadowAlpha: 0.8,
			dropShadowAngle: 2.1,
			dropShadowBlur: 4,
			dropShadowColor: '0x111111',
			dropShadowDistance: 10,
			lineJoin: 'round',
			wordWrap: true,
			wordWrapWidth: 440
		});
		
		let n = new PIXI.Text("HOLD", style);
		n.x = this.x / 1.5
		n.y = this.y / 3.5
		n.sortChildren(8);
		n.zIndex = 1;
		graphics.addChild(n);
		this.hold = graphics;
		this.text = n;
	}
}

export default CardHold;