import React, { Component } from "react";
import {Card} from "react-bootstrap";
import ManualBet from './components/ManualBet';
// import AutoBet from './components/AutoBet';

class Parent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bet: 'manual',
        };
    }

    betMode = (type) => {
        // this.setState({ bet: type });
        this.setState({ bet: 'manual' });
    };

    render() {
        let { bet } = this.state;
        return (
            <Card className={"no-shadow mb-1"}>
                <Card.Body className="bet-form no-shadow p-2">
                    {/*
                    <div className="btn-group btn-group-toggle" data-toggle="buttons">
                        <label className={'' + bet === 'manual' ? 'btn-outline-tabs btn-xs active': 'btn-outline-tabs btn-xs '}>
                            <input onClick={ e => this.betMode('manual')} type="radio" /> Manual
                        </label>
                        <label className={'' + bet === 'auto' ? 'btn-outline-tabs btn-xs active': 'btn-outline-tabs btn-xs disabled'}>
                            <input onClick={ e => this.betMode('auto')} type="radio" /> Auto
                        </label>
                    </div>
                    */}
                    <ManualBet mobile={this.props.mobile} engine={this.props.engine} />
                    {/*{bet === 'manual' ?
                        <ManualBet mobile={this.props.mobile} engine={this.props.engine} />
                        :
                        <AutoBet/>
                    }*/}
                </Card.Body>
            </Card>
        );
    }
}

export default Parent;