import MyEmitter from "events";
import storage from "../../../Storage";
import socket from "../../../Socket";
import C from "../../../Constant";
import {Event, encode, decode, forceSatoshiFormat} from "../../../Helper";

/**
 * Game Engine
 */
function Engine(){

    var self = this;

    /**
     * Game Name
     */
    self.game = 'mine';

    /**
     * Socket Connection
     */
    self.ws = socket;

    /**
     * Itialize Game
     */
    self.trigger = new MyEmitter();

    /**
     * Itialize Game
     */
    self.init = false;

    /**
     * Game Status
     */
    self.gameStatus = null;

    /**
     * Round Amount
     */
    self.amount = null;

    /**
     * Round Profit
     */
    self.profit = null;

    /**
     * Number of Mines
     */
    self.mine = null;

    /**
     * Player Coin
     */
    self.coin = null;

    /**
     * Selected Land
     */
    self.land = null;

    /*
     * Start Engine
    */
    self.started = true;

    if(self.started)
    {
        self.ws.on(C.PLAY_MINE, (data) => {
            data = decode(data);

            switch(data.command)
            {
                case 'play':
                    return self.trigger.emit(C.PLAY_MINE, data);
                case 'busted':
                    return self.trigger.emit(C.BUSTED_MINE, data);
                case 'clicked':
                    return self.trigger.emit(C.CLICKED_MINE, data);
                case 'cashout':
                    return self.trigger.emit(C.CASHOUT_MINE, data);
                case 'error':
                    return self.trigger.emit(C.ERORR_MINE, data);
            }
        });
    }
}

Engine.prototype.off = function (){
    let self = this;
    self.ws.off(C.PLAY_MINE);
}

Engine.prototype.on = function (name, callback){
    let self = this;
    if(!self.started) return;
    if(self.trigger === null) return;
        return self.trigger.on(name, (data) => callback(data));
}

Engine.prototype.emit = function (name, data = []){
    let self = this;
    if(!self.started) return;
    if(self.trigger === null) return;
        return self.trigger.emit(name, data);
}

/**
 * Add Player for playing game
 */
Engine.prototype.start = function () {
    let self = this;

    if(!self.init)
        return;

    if(self.amount < 0.00000010){
        self.amount = forceSatoshiFormat(0.00000010)
    }
    
    self.ws.emit(C.PLAY_MINE, encode({
        command: 'play',
        amount: self.amount,
        mine: self.mine,
        coin: self.coin
    }));
};

/**
 * Clicked On the Land
 */
Engine.prototype.clicked = function () {
    let self = this;
    self.ws.emit(C.PLAY_MINE, encode({
        command: 'clicked',
        land: parseInt(self.land)
    }));
};

/**
 * CashOut
 */
Engine.prototype.cashout = function () {
    let self = this;
    self.ws.emit(C.PLAY_MINE, encode({
        command: 'cashout',
        game: self.game,
        profit: self.profit
    }));
};

/** Start Game Engine **/
export default Engine