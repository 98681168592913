import React from 'react'
import { Link } from 'react-router-dom'
import PerfectScrollbar from 'perfect-scrollbar';
import games from "../../../games";
import {__, Event, randomString} from "../../../../Helper";

class Games extends React.Component {
    constructor(props){
        super(props);
        this.state = {}
    }

    componentDidMount() {
        const ps = new PerfectScrollbar('.main-icon-menu', {
            wheelSpeed: 1,
            suppressScrollX: true,
            wheelPropagation: true,
            minScrollbarLength: 2
        });
        ps.update();
    }

    forceShow = () => {
        Event.emit('show_games');
    }

    render(){
        const list = games.map((game, i) =>
           !game.disabled && <AddGame key={__.toString(game.id)} events={this.props} item={game} />
        );
        return(
            <>
                <React.Fragment>
                    <div className="main-icon-menu" onMouseEnter={ this.forceShow }>
                        <nav className="nav text-center p-0">
                            <ul className="p-0 m-0">
                                {list}
                            </ul>
                        </nav>
                    </div>
                </React.Fragment>
            </>
        );
    }
}

function AddGame(props){
    const {item} = props;
    return (
                <li>
                    <Link to={'/' + item.prefix} className="nav-link">
                        <i className={item.icon + " mr-2"} /> <span>{item.name}</span>
                    </Link>
                </li>
        );  
}

export default Games;