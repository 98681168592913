import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Button, Dropdown } from "react-bootstrap";
import RangeCredit from "../../Components/Game/Addons";
import { gameCoin } from "../../../actions/gameCoin";
import { setWallet } from "../../../actions/gameWallet";
import storage from "../../../Storage";
import C from "../../../Constant";
import {Event, __, forceSatoshiFormat, isValidNumber, wait, sendNotfication} from "../../../Helper";

class Bet extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            engine: this.props.engine,
            isLogged: storage.getKey("logged") !== null ? true : false,
            amount: forceSatoshiFormat(storage.getKey('lam') ? storage.getKey('lam') : .00000010),
            started: false,
            fields: 'Medium',
            profit: 0,
            cashout: false,
            inputDisabled: false,
            buttonText: "BET",
            btnType: "btn-bet"
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleBet = this.handleBet.bind(this);
    }

    componentDidMount() {
        let { engine } = this.state;
        this._isMounted = true;
        this.props.gameCoin();
        engine.on(C.PLAY_GOAL, () => this.play());
        engine.on(C.CLICKED_GOAL, (data) => this.clicked(data));
        engine.on(C.CASHOUT_GOAL, () => this.end());
        engine.on(C.BUSTED_GOAL, () => this.busted());
        engine.on(C.ERORR_GOAL, (data) => this.error(data));
        engine.on("stop_playing", () => this.stopManual());
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    
    stopManual() {
        this.setState({ started: false, buttonText: 'BET', inputDisabled: false });
    }

    busted = () => {
        if(this._isMounted)
        {
            this.setState({
                inputDisabled: true,
                inputDisabled2: true
            });

            return this.end();
        }
    }

    end() {
        if(this._isMounted)
        {
            wait(2200).then(() => {
                this.setState({
                    profit: 0,
                    cashout: false,
                    buttonText: "BET",
                    btnType: "btn-bet",
                    inputDisabled: false,
                    inputDisabled2: false,
                    started: false
                });
            });
        }
    }

    play() {
        if(this._isMounted){
            this.setState({
                started: true,
                inputDisabled: true,
                inputDisabled2: true,
                buttonText: "Game is Started!",
            });
        }
    }

    clicked(data) {
        if(this._isMounted){
            let { profit, coin } = data;
            let calc = __.toNumber(profit) + __.toNumber(this.state.profit);
            let win = forceSatoshiFormat(calc);

            this.setState({
                inputDisabled: false,
                btnType: "btn-bet-success",
                buttonText: win + " " + coin + " CashOut",
                cashout: true,
                profit: win,
            });
        }
    }

    error(data) {
        if(this._isMounted){
            sendNotfication(data.message, 'info', 'top-center');

            if(data.code === 'credit')
                this.props.setWallet(true, data.uid);

            this.setState({ inputDisabled: false });
        }
    }

    handleBet(e) {
        e.preventDefault();
        let { isLogged, amount } = this.state;

       // Check User
        if(!isLogged){
           return Event.emit('showAuthModal', true);
        }
        
        if(parseFloat(amount) <= 0){
            this.setState({ amount: forceSatoshiFormat(0.00000010) });
        }
        else {
            amount = Math.min(parseFloat(storage.getKey('credit')), parseFloat(amount));
            this.setState({ amount: forceSatoshiFormat(amount) });
        }

        this.setState({ inputDisabled: true});
        this.placeBet();
    }

    placeBet() {
        if(this._isMounted){
            let {amount, engine, cashout, profit } = this.state;
            engine.init = true;

            if (cashout) {
                engine.profit = profit;
                engine.cashout();
                return;
            }

            wait(100).then(() => {
                engine.amount = amount;
                engine.coin = this.props.coin;
                engine.start();
            });
        }
    }

    handleInputChange(event) {
        let target = event.target;
        let value = target.value;

        if (target.name === "amount") {
            if (!isValidNumber(value)) return;
        }
        
        if(target.name === 'amount'){
            storage.setKey('lam', value);
        }

        this.setState({ [target.name]: value });
    }

    handleInputFields = (value) => {
        if(!this.state.started){
            this.setState({ fields: value });
            Event.emit('goal_risk', value)
        }
    };
    
    setAmount = (val) => {
        var am = forceSatoshiFormat(val);
        this.setState({ amount: am });
        storage.setKey('lam', am );
    }

    render() {
        let { inputDisabled, inputDisabled2, fields } = this.state;
        return (
            <>
                <form
                    className="w-100"
                    onSubmit={(e) => {
                        this.handleBet(e);
                    }}
                >
                    <Row>
                        <Col xl={6} md={7} sm={12}>
                            <div className={"form-group mb-1 bet-input"}>
                                <div className="input-group">
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                          <img
                                              src={"/assets/images/" + this.props.coin + ".png"}
                                              className={"mini-coin-7"}
                                              alt=""
                                          />
                                          BET
                                        </span>
                                    </div>
                                    <input
                                        disabled={inputDisabled2}
                                        type="text"
                                        className="form-control text-left"
                                        id="amount"
                                        name="amount"
                                        placeholder=".."
                                        value={this.state.amount}
                                        autoComplete={"off"}
                                        onKeyUp={this.handleInputChange}
                                        onChange={this.handleInputChange}
                                    />
                                    <div className="input-group-append">
                                        <RangeCredit set={this.setAmount} />
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xl={6} md={5} sm={12}>
                            <div className={"form-group mb-1"}>
                                <div className="input-group fw">
                                    <div className="input-group-append">
                                        <span className="input-group-text"> 
                                            <i className="mdi mdi-bomb font-25 text-danger mr-1" />
                                            RISK
                                        </span>
                                    </div>
                                    <Dropdown drop={''} className={"drop-select btn-block"}>
                                        <Dropdown.Toggle split variant="" className={"py-0 mt-0"}>
                                              <span className="text-muted text-center">
                                                <i className={"mdi mdi-chevron-down mr-1"} /> {fields}
                                              </span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu
                                            disabled={inputDisabled2}
                                            className={"dopdown-menu-bottom " + inputDisabled2}
                                        >
                                            <Dropdown.Item
                                                onClick={(e) => this.handleInputFields('Medium')}
                                                className={"animated fadeIn"}
                                            >
                                                Medium
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={(e) => this.handleInputFields('High')}
                                                className={"animated fadeIn"}
                                            >
                                                High
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={5} sm={12} className=" m-auto">
                            <div className={"form-group rev mt-2 mb-0"}>
                                <Button
                                    variant={"btn btn-md btn-block mt-2 " + this.state.btnType}
                                    disabled={inputDisabled}
                                    type="submit"
                                >
                                    {this.state.buttonText}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </form>
            </>
        );
    }
}

Bet.propTypes = {
    coin: PropTypes.string,
    credit: PropTypes.string
};

const mapStateToProps = (state) => ({
    coin: state.items.coin,
    credit: state.items.credit
});

export default connect(mapStateToProps, {
    gameCoin,
    setWallet
})(Bet);
