import React from 'react';
import {isMobile, wait} from "../../../Helper";

class Game extends React.Component {
    constructor (props){
        super(props);
        this.state = {
            height: null,
            font: null,
            number: Math.floor(Math.random()* 10).toFixed(2)
        };
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        wait(600).then(() => {
            this.handleResize();
        });
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize(){
        if(isMobile()){
            this.setState({ height: '18rem', font: '2.6875rem' });
        }
    }

    render() {
        return(
            <>
                <div className="limbo-content" id="limbo-game-canvas" style={{ minHeight: this.state.height }}>
                  <div className="game-rocket notranslate animated zoomIn">
                  <div className="rocket-number animated zoomIn" style={{ fontSize: this.state.font }}>
                      {this.state.number}
                      <span className="gv-x">×</span>
                  </div>
                  </div>
                  <div class="rocket">
                    <div class="rocket-body">
                      <div class="body"></div>
                      <div class="fin fin-left"></div>
                      <div class="fin fin-right"></div>
                      <div class="window"></div>
                    </div>
                    <div class="exhaust-flame"></div>
                    <ul class="exhaust-fumes">
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                    </ul>
                    <ul class="star">
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                    </ul>
                  </div>
                </div>
            </>
        );
    }
}

export default Game;