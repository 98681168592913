import { Link } from "react-router-dom";
import React from "react";

class Logo extends React.Component {
    render() {
        return (
            <>
                <div className="topbar-left">
                    <Link to="/" className="logo">
                        <span>
                            <img src="/assets/images/logo-white.png" className="logo-sm ml-4 d-none d-md-inline" alt="Logo" />
                            <img src="/assets/images/logo-mobile.png" className="logo-sm ml-2 d-inline d-md-none" alt="Logo" />
                            <span className={"ml-1 font-25 text-logo"}>
                                <h1 className="d-none">Coingator Crypto Casino</h1>
                            </span>
                        </span>
                    </Link>
                </div>
            </>
        );
    }
}

export default Logo;