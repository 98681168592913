import React from 'react';
import {Row, Col, Card} from "react-bootstrap";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import {Helmet} from "react-helmet";
import {isMobile, wait, Event,} from "../../../Helper";
import BankRoll from "../../Components/Game/BankRoll";
import TopBarGames from "../../Components/Game/Addons/TopBar";
import Queue from "../../Components/Game/Queue";
import Table from "./components/Table";
import Engine from "./Engine";
import Game from "./Game";
import Bet from "./Bet";
import "../../../Static/css/videoPoker.css"

class Index extends React.Component {
    _Mounted = false;
    constructor (props){
        super(props);
        this.state = {
            engine: new Engine(),
            padding: "p-1",
            isMobile: isMobile() ? true : false
        };
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        let { engine } = this.state;
        
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        // Start Engine
        engine.started = true;

        wait(600).then(() => {
            this.handleResize();
        });
        window.addEventListener('resize', this.handleResize);
    }

    componentWillMount() {
        wait(500).then(() => {
            this._Mounted = true;
        })
    }

    componentWillUnmount() {
        let { engine } = this.state;
        window.removeEventListener('resize', this.handleResize);
        this._Mounted = false;

        // Stop Engine
        engine.started = false;
        engine.off()
    }

    handleResize(){
        let clientWidth = document.documentElement.clientWidth || document.body.clientWidth;

        if(clientWidth < 1540)
        {
            this.setState({ col: 'col-xl-12'});
            Event.emit('hide_games');
        }
        else {
            this.setState({ col: 'col-xl-9'});
            Event.emit('show_min_games');
        }

        if(this.state.isMobile){
            this.setState({ padding: 'p-0', ovh: 'ovh' });
        }
    }

    render() {
        let { col, padding, ovh, isMobile } = this.state;

        const help = <p className="text-white">Video poker has many variants, but all are based on one of poker’s simplest varieties, five-card draw.
            You are dealt a five-card poker hand, then given one opportunity to keep the cards you wish to keep and discard the ones you don’t wish to retain, receiving new cards in return. This then makes up your final hand.
            <b>Video Poker Tips & Tricks</b> <br/>
            Hit deal to receive your initial five-card poker hand <br/>
            Discard cards you don’t deem useful (up to all five can be discarded) <br/>
            Receive replacement cards from the same ‘virtual deck’.<br/>
            Any winnings are paid out according to the paytable. <br/>
            </p>
        
        return (
            <>
                <Helmet>
                    <title>Video Poker - Crypto Casino Games</title>
                    <meta name="keywords" content="Crypto Video Poker Game, Online Video Poker Game, Bitcoin Video Poker Game, Blockchain Video Poker Game, Best Crypto Video Poker Game, Coingator.io Video Poker"/>
                    <meta name="description" content="Crypto Video poker Game has many variants, but all are based on one of poker’s simplest varieties, five-card draw.
            You are dealt a five-card poker hand, then given one opportunity to keep the cards you wish to keep and discard the ones you don’t wish." />
                    <meta name="og:title" content="Play Online Video Poker - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <meta name="og:description" content="Play Online Video Poker - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <link rel="canonical" href="https://Coingator.io/videopoker" />
                </Helmet>
                <ReactCSSTransitionGroup
                    transitionAppear={true}
                    transitionAppearTimeout={250}
                    transitionEnterTimeout={200}
                    transitionLeaveTimeout={200}
                    transitionName={'SlideIn'}
                >
                    {this._Mounted ?
                    <Row className={ "animated fadeIn " + ovh}>
                        <div className="d-none">Crypto Video Poker Game Description: {help}</div>
                        <Col sm={12} className={'m-auto ' + col + ' ' + padding}>

                            {/* BANKROLL */}
                            <Card className="mb-0">
                                <Card.Body className="px-2 py-3">
                                    <Row>
                                        <Col md={6} className={'col-6 text-left'}>
                                            <BankRoll game={'videopoker'}/>
                                        </Col>
                                        <Col md={6} className={'col-6 text-right'}>
                                            <TopBarGames help={help} image="/assets/images/vp.png" />
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                            {/* GAME */}
                            <Card className="mb-0">
                                <Card.Body className={'bg-dice-options p-1 animated fadeIn'} id="roll-panel">
                                    <Row>
                                        <Col sm={12} md={12} className={'m-auto'}>
                                            {isMobile ?
                                                <Table engine={this.state.engine} />
                                            :
                                                <Game engine={this.state.engine} />
                                            }
                                        </Col>
                                        <Col sm={12} md={9} className={'m-auto'}>
                                            {isMobile ?
                                                <Game engine={this.state.engine} />
                                            :
                                                <Table engine={this.state.engine} />
                                            }
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                            {/* BET */}
                            <Card className="mb-0">
                                <Card.Body className={padding}>
                                    <Row>
                                        <Col sm={12} md={10} className={'m-auto'}>
                                           <Bet engine={this.state.engine} />
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                            {/* QUEUE */}
                            <Card className="mb-5">
                                <Card.Body className="p-1">
                                    <Row>
                                        <Col sm={12} md={12} className={'m-auto px-1'}>
                                            <Queue t={this.props.t} game={'videopoker'}/>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                        </Col>
                    </Row>
                    :
                    <div className="game-loader text-center">
                        <div className="spinner-grow text-info my-3" role="status"/>
                    </div>
                    }
                </ReactCSSTransitionGroup>
            </>
        );
    }
}

export default Index;