import React, { Component } from 'react'
import Modal from "react-bootstrap/Modal";

class Help extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose(){
        this.setState({ show: false, effect: 'zoomOut' });
    }

    handleShow(){
        this.setState({ show: true, effect: 'zoomIn' });
    }

    render() {
        return(
            <>
                <button onClick={this.handleShow} className={"btn btn-sm bg-cs2 cpt animated fadeInDown"} data-tip="Help">
                    <i className={'mdi mdi-help-circle font-15'} />
                </button>
                <Modal
                    size="md"
                    scrollable={true}
					backdrop="static"
                    centered={true}
                    show={this.state.show}
                    onHide={this.handleClose}
                    aria-labelledby="wallet-lg-modal"
                    className={"animated " + this.state.effect}
                >
                    <Modal.Header className="Header">
                        <button type="button" className="close p-2" onClick={this.handleClose}>
                            <i className={'mdi mdi-close'}/>
                        </button>
                    </Modal.Header>
                    <Modal.Body className="modal-helper">
                        <p className="text-center">
                            <img className="img-fluid" src={this.props.image} alt="Help" />
                        </p>
                        <p className={'font-light font-15 text-white'}>
                            {this.props.content}
                        </p>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

export default Help;