import MyEmitter from "events";
import Swal from "sweetalert2";
import storage from "../../../Storage";
import socket from "../../../Socket";
import C from "../../../Constant";
import {forceSatoshiFormat, encode, decode} from "../../../Helper";

/**
 * Game Engine
 */
function Engine(){

    let self = this;

    /**
     * Socket Connection
     */
    self.ws = socket;

    /**
     * Game Name
     */
    self.game = 'blackjack';

    /**
     * Event Trigger
     * @type {module:events.internal}
     */
    self.trigger = new MyEmitter();

    /**
     * Itialize Game
     */
    self.init = false;

    /**
     * Round Amount
     */
    self.amount = null;

    /**
     * Player Coin
     */
    self.coin = null;

    /**
     * Player profit
     */
    self.profit = false;

    /**
     * Game Result Cards
    */
    self.pCards = [];
    self.dCards = [];

    /*
     * Start Engine
    */
    self.started = true;

    if(self.started)
    {
        self.ws.on(C.PLAY_BLACKJACK, (data) => {
            data = decode(data);

            switch(data.command)
            {
                case 'play':
                    return self.trigger.emit(C.PLAY_BLACKJACK, data);
                case 'busted':
                    return self.trigger.emit(C.BUSTED_BLACKJACK, data);
                case 'error':
                    return self.trigger.emit(C.ERROR_BLACKJACK, data);
            }
        });
    }
}

Engine.prototype.off = function (){
    let self = this;
    self.ws.off(C.PLAY_BLACKJACK);
}

Engine.prototype.on = function (name, callback){
    let self = this;
    if(!self.started) return;
    if(self.trigger === null) return;
        return self.trigger.on(name, (data) => callback(data));
}

Engine.prototype.emit = function (name, data = []){
    let self = this;
    if(!self.started) return;
    if(self.trigger === null) return;
        return self.trigger.emit(name, data);
}

/**
 * Add Player for playing game
 */
Engine.prototype.start = function () {
    let self = this;

    if(!self.init)
        return;

    if(self.amount < 0.00000010){
        self.amount = forceSatoshiFormat(0.00000010)
    }

    // if(parseFloat(storage.getKey('credit')) === parseFloat(self.amount)){
    //     return Swal.fire({
    //                 title: 'Are you sure to play with all your credits ?',
    //                 text: "",
    //                 icon: 'warning',
    //                 showCancelButton: true,
    //                 confirmButtonColor: '#3085d6',
    //                 cancelButtonColor: '#d33',
    //                 confirmButtonText: 'Yes!'
    //             }).then((result) => {
    //             if (result.value) {
    //                 self.ws.emit(C.PLAY_BLACKJACK, encode({
    //                     command: 'play',
    //                     amount: self.amount,
    //                     coin: self.coin
    //                 }));
    //             }
    //             else {
    //                 self.trigger.emit('stop_playing');
    //             }
    //     });
    // }

    self.ws.emit(C.PLAY_BLACKJACK, encode({
        command: 'play',
        amount: self.amount,
        coin: self.coin
    }));
};


/**
 * Busted Game
 */
Engine.prototype.busted = function () {
    let self = this;
    
    self.ws.emit(C.PLAY_BLACKJACK, encode({
        command: 'busted',
        profit: self.profit,
        pCards: self.pCards,
        dCards: self.dCards,
    }));
};

/** Start Game Engine **/
export default Engine