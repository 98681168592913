import React from "react";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    games: {
        width: 34,
        marginRight: 5
    }
});

export default function Home(){
    const classes = useStyles();
    return <img src="/assets/images/new_icons/home.svg" className={classes.games} alt="Home" />
}