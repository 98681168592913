/* eslint-disable */
import jQuery from 'jquery';
import {__, Event, wait, forceSatoshiFormat} from "../../../../Helper";
import storage from "../../../../Storage";
import Game from "./Game";
import Player from "./Player";
import GameObj from "./Object";

var $ = jQuery.noConflict();

function BlackJack(engine)
{
	var credit = storage.getKey("credit");

	var player	= new Player(engine, credit),
		dealer = new Player(engine, credit),
		deal;

	GameObj['running'] = false;
	GameObj['blackjack'] = false;
	GameObj['insured'] = 0;

	var game = new Game(engine, player, dealer);

	$('#hit').on('click', function() {
		player.hit();
	});

	$('#stand').on('click', function() {
		player.stand();
	});

	$('#double').on('click', function() {
		player.dbl();
	});

	$('#split').on('click', function() {
		player.split();
	});

	$('#insurance').on('click', function() {
		player.insure();
	});

	/*
	 * Play Game
	*/
	BlackJack.prototype.play = function(){
		if(!GameObj['running']) {
			game.newGame();
		}
	};
}

export default BlackJack;