import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Button, Row, Col, Dropdown} from "react-bootstrap";
import {gameCoin} from "../../../../actions/gameCoin";
import {setWallet} from "../../../../actions/gameWallet";
import storage from "../../../../Storage";
import C from "../../../../Constant";
import {Event, __, wait, isValidNumber, forceSatoshiFormat, sendNotfication} from "../../../../Helper";
import {userUID} from "../../../../Auth";

class ManualBet extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            engine: this.props.engine,
            buttonText: 'BET',
            buttonType: '',
            playing: false,
            inputDisabled: false,
            risk: 'low',
            buttonProgress: false,
            numbers: [],
            bitError: false,
            outError: false,
            lock: false,
            holding: false,
            amount: forceSatoshiFormat(storage.getKey('lam') ? storage.getKey('lam') : .00000010),
            isLogged: storage.getKey('logged') !== null ? true : false,
            coin: storage.getKey('coin')
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleRisk = this.handleRisk.bind(this);
        this.handleBet = this.handleBet.bind(this);
        this.clearTable = this.clearTable.bind(this);
        this.autoPick = this.autoPick.bind(this);
    }

    componentDidMount(){
        let { engine } = this.state;
        this._isMounted = true;

        //Set Coin for Redux
        this.props.gameCoin();
        
        engine.on(C.STATUS_KENO, data => this.checkStatus(data));
        engine.on(C.WAITING_KENO, () => this.checkWaitingGame());
        engine.on(C.BUSTED_KENO, () => this.checkBustedGame());
        engine.on(C.STARTED_KENO, () => this.checkStartedGame());
        engine.on(C.PLAY_KENO, data => this.handlePlay(data));
        engine.on(C.KENO_AMOUNT, data => this.amount(data));
        engine.on(C.ERROR_KENO, data => this.handleError(data));
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleError(data) {
        if(this._isMounted){
            sendNotfication(data.message, 'info', 'top-center');

            this.setState({ holding: false, look: false, playing: false });

            if(data.code === 'credit')
                this.props.setWallet(true, data.uid);

            this.setDefaultButton();
        }
    }

    handleRisk(e){
        this.setState({ risk: e });
    };

    handleInputChange(event){
        let target = event.target;
        let value = target.value;
        let name = target.name;

        if(__.isEmpty(value)){
            if(name === 'amount')
                this.setState({ bitError: 'red-border' });

            if(name === 'risk')
                this.setState({ outError: 'red-border' });
        }
        else {
            if(name === 'amount')
                this.setState({ bitError: false });

            if(name === 'risk')
                this.setState({ outError: false });
        }

        if(!isValidNumber(value)) return;
        
        if(name === 'amount'){
            storage.setKey('lam', value);
        }

        this.setState({ [name]: value });
    }

    setDefaultButton(){
        if(this._isMounted) {
            this.setState({
                inputDisabled: false,
                buttonType: '',
                buttonText: "BET"
            });
        }
    }

    setDisabledButton(){
        if(this._isMounted) {
            this.setState({
                inputDisabled: true,
                buttonType: '',
                buttonText: "BET"
            });
        }
    }

    setOutButton(){
        if(this._isMounted) {
            this.setState({
                inputDisabled: true,
                buttonType: 'btn-success',
                buttonText: "CANCEL"
            });
        }
    }

    handlePlay = (data) => {
        if(this._isMounted){
            if(parseFloat(data.uid) === parseFloat(userUID()))
                this.setState({ playing: true });
        }
    }

    checkWaitingGame(){
        if(this._isMounted){
            if(this.state.holding === true){
                this.setState({ holding: false });
                this.placeBet();
            }

            for ( var i = 0; i < 40; i++ ) {
                if(document.getElementById('b_' + i) !== null)
                    document.getElementById('b_' + i).classList.remove('alerts');
            }
        }
    }

    checkStartedGame(){
        if(this._isMounted){
            if(this.state.lock === true || this.state.playing === true){
                this.setState({lock: false});
                this.setDisabledButton();
            }
        }
    }

    checkBustedGame = () => {
        if(this._isMounted){
            let { engine } = this.state;

            if(this.state.holding === false)
            {
                engine.init = false;
                clearInterval(this.state.buttonProgress);
                this.setState({ playing: false });
                this.setDefaultButton();
            }
        }
    };

    checkStatus(data)
    {
        if(this._isMounted){
            switch (data.status)
            {
                case 'waiting_keno':
                    this.checkWaitingGame();
                    break;
                case 'started_keno':
                    this.checkStartedGame();
                    break;
                case 'busted_keno':
                    this.checkBustedGame();
                    break;
                default:
            }
        }
    }

    isCorrectBet(amount){
        if(amount === 0)
            return false;

        if(!isValidNumber(amount))
            return false;

        return true;
    }

    placeBet(){
        if(this._isMounted){
            let { engine } = this.state;

            // Disable For double queue or network delay
            if(this.state.lock === true) return;

            this.setState({
                lock: true,
                playing: true,
                inputDisabled: true,
                buttonType: 'btn-secondary',
                buttonText: "Please Wait..."
            });

            wait(10).then(() => {
                engine.init    = true;
                engine.coin    = this.props.coin;
                engine.numbers = this.state.numbers;
                engine.amount  = forceSatoshiFormat(this.state.amount) * this.state.numbers.length;
                engine.force();
            });
        }
    }

    holdBet(){
        if(this._isMounted){
            if(this.state.holding === true)
            {
                this.setDefaultButton();
                this.setState({ holding: false });
            }
            else {
                this.setOutButton();
                this.setState({ holding: true });
            }
        }
    }

    handleBet(e){
        if(this._isMounted){

            e.preventDefault();

            let { amount } = this.state;

           // Check User
            if(!this.state.isLogged){
               return Event.emit('showAuthModal', true);
            }
			
			if(this.state.numbers.length < 3) {
				return sendNotfication('Please select at least 3 number', 'info','top-center');
			}

            // Check is Correct Bet
            if(!this.isCorrectBet(this.state.amount))
                return false;

            // Check for Double request
            if(this.state.lock === true)
                return false;
        
            if(parseFloat(amount) <= 0){
                this.setState({ amount: forceSatoshiFormat(0.00000010) });
            }
            else {
                amount = Math.min(parseFloat(storage.getKey('credit')), parseFloat(amount));
                this.setState({ amount: forceSatoshiFormat(amount) });
            }

            // Check Game Status to Play
            switch (this.state.engine.gameStatus)
            {
                case 'waiting_keno':
                    this.placeBet();
                 break;

                case 'started_keno':
                    this.holdBet();
                break;

                case 'busted_keno':
                    this.holdBet();
                break;

                default:
            }
        }
    }

    selectNum(num){
        if(this._isMounted){
            if(!this.state.numbers.includes(num)){
                if(this.state.numbers.length < 10)
                    this.setState(state => ({numbers:[num, ...state.numbers]}));
            }
            else {
                const remove = (num) => {
                    return __.without(this.state.numbers, num);
                };
                this.setState(() => ({numbers: remove(num)}));
            }
        }
    }

    clearTable(){
        if(this._isMounted){
            this.setState({ numbers: []})
        }
    }

    autoPick(){
        if(this._isMounted){
            this.clearTable();
            wait(getRandomInt(1, 150)).then(() => {
                for (var i = 0; i < 19; i++) {
                    let num = getRandomInt(1, 40);
                    if(!this.state.numbers.includes(num))
                    {
                        if(this.state.numbers.length !== 10){
                            this.setState(state => ({numbers:[num, ...state.numbers]}));
                        }
                    }
                }
            });
        }
    }

    amount(data){
        if(this._isMounted){
            data.amount.forEach((c, i) => {
                    var amount = c;
                    if(document.getElementById('b_' + amount) !== null){
                        wait(amount * 50).then(() => {
                            if(document.getElementById('b_' + amount) !== null)
                                document.getElementById('b_' + amount).classList.add('alerts');
                        });
                    }
            })
        }
    }

    render(){

        const makeNumber = (num) => {
            let disabled = false;

            if(this.state.lock === true || this.state.playing === true){
                disabled = true;
            }

            let active = false;

            if(this.state.numbers.includes(num)){
                active = "active";
            }

            let button = React.createElement('button',
                {
                    type: "button",
                    id: "b_" + num,
                    disabled: disabled,
                    className: "btn btn-soft-secondary btn-xl btn-block " + active,
                    onClick: () => this.selectNum(num)
                },
                num);

            return React.createElement('div', { className: "keno-numbers"}, button);
        };

        const generateNumber = () => {
            let make = [];
            for ( var i = 1; i < 41; i++ ) {
                make[i] = makeNumber(i);
            }
            return make;
        };

        let {inputDisabled, risk, numbers, buttonType, bitError, buttonText, amount} = this.state;

        return(
            <>
                <form className="w-100" onSubmit={(e) => {this.handleBet(e)}}>
                    <div className="grid-buttons">
                        {generateNumber()}
                    </div>
                    <Row className="mt-2">
                        <Col md={6} className={'col-6 px-2'}>
                            <button type={"button"} className={"btn btn-outline-light btn-block btn-xs"} disabled={inputDisabled} onClick={this.autoPick}>Auto Pick</button>
                        </Col>
                        <Col md={6} className={'col-6 px-2'}>
                            <button type={"button"} className={"btn btn-outline-light btn-block btn-xs"} disabled={inputDisabled} onClick={this.clearTable}>Clear Table</button>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4} lg={4} className={"m-auto"}>
                            <div className={ "form-group rev mb-1"}>
                                <Button variant={'btn btn-sm btn-block mt-2 btn-bet ' + buttonType}
                                        disabled={inputDisabled}
                                        type="submit">
                                    {buttonText}
                                </Button>
                            </div>
                        </Col>
                        <Col lg={5} sm={12} className={'mt-3'}>
                            <div className={ "form-group bet-input mb-2 " + bitError}>
                                <div className="input-group">
                                    <div className="input-group-append">
                                        <span className="input-group-text">BET</span>
                                    </div>
                                    <input disabled={inputDisabled} type="text" className="form-control text-left" id="amount" name="amount"
                                           max="900000000" placeholder=".." value={amount} autoComplete={"off"} required={true} onKeyUp={this.handleInputChange}
                                           onChange={this.handleInputChange} />
                                    <div className="input-group-append">
                                        <span className="input-group-text">{this.props.coin}</span>
                                    </div>
                                    <div className="input-group-append">
                                        <span className="input-group-text text-lowercase">x</span>
                                    </div>
                                    <div className="input-group-append">
                                        <span className="input-group-text text-lowercase">{numbers.length}</span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={3} sm={12}  className={'mt-3'}>
                            <div className={"form-group mb-0 " + bitError}>
                                <div className="input-group fw">
                                    <div className="input-group-append">
                                        <span className="input-group-text">RISK</span>
                                    </div>
                                    <Dropdown className={"drop-select btn-block"}>
                                        <Dropdown.Toggle split variant="" className={'py-0 mt-0'} >
                                                    <span className="text-muted text-center">
                                                        {risk}
                                                        <i className={'mdi mdi-chevron-down mr-1 ccol chera'} />
                                                     </span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu disabled={inputDisabled} className={"dopdown-menu-bottom " + inputDisabled }>
                                            <Dropdown.Item onClick={e => this.handleRisk('low')} className={'animated fadeIn'}>Low</Dropdown.Item>
                                            <Dropdown.Item onClick={e => this.handleRisk('medium')} className={'animated fadeIn'}>Medium</Dropdown.Item>
                                            <Dropdown.Item onClick={e => this.handleRisk('high')} className={'animated fadeIn'}>High</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </form>
            </>
        );
    }
}

function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

ManualBet.propTypes = {
    coin: PropTypes.string
};

const mapStateToProps = state => ({
    coin: state.items.coin
});

export default connect(mapStateToProps, { gameCoin, setWallet })(ManualBet);