/* eslint-disable */
import jQuery from 'jquery';
import Deal from "./Deal";
import GameObj from "./Object";

const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));

var $ = jQuery.noConflict();

function Game(engine, player, dealer) {
    this.newGame = function() {
        var wager = parseFloat(engine.amount);
        player.resetWager();
        player.setWager(wager);
        resetBoard();
        player.setCash(-wager);
        GameObj['deal'] = new Deal(dealer);
        GameObj['player'] = player;
        GameObj['running'] = true;
        GameObj['blackjack'] = false;
        GameObj['insured'] = false;
        GameObj['engine'] = engine;
        player.resetHand();
        dealer.resetHand();
        showBoard(player, dealer);
    };
}

export function setActions(opts) {
    var hand = GameObj['player'].getHand();
    var wager = parseFloat(GameObj['engine'].amount);

    if (!GameObj['running']) {
        $("#hit").prop("disabled", true);
        $("#stand").prop("disabled", true);
        $("#double").prop("disabled", true);
        $("#split").prop("disabled", true);
        $("#insurance").prop("disabled", true);
    }

    if (opts === "run") {
        $("#hit").prop("disabled", false);
        $("#stand").prop("disabled", false);

        if (GameObj['player'].checkWager(wager * 2)) {
            $("#double").prop("disabled", false);
        }
    } else if (opts === "split") {
        $("#split").prop("disabled", false);
    } else if (opts === "insurance") {
        $("#insurance").prop("disabled", false);
    } else if (hand.length > 2) {
        $("#double").prop("disabled", true);
        $("#split").prop("disabled", true);
        $("#insurance").prop("disabled", true);
    }
}

function showBoard(player, dealer) {
    GameObj['deal'].dealCard(4, 0, [player, dealer, player, dealer]);
}

/*
 * Busted Game
 */
export function getWinner() {
    var player = GameObj['player'];
    var dealer = GameObj['dealer'];
    var phand = player.getHand(),
        dhand = dealer.getHand(),
        pscore = player.getScore(),
        dscore = dealer.getScore(),
        wager = player.getWager(),
        winnings = 0,
        result;

    GameObj['running'] = false;
    setActions();

    GameObj['engine'].pCards = phand;
    GameObj['engine'].dCards = dhand;

    if (pscore > dscore) {
        if (pscore === 21 && phand.length < 3) {
            winnings = wager * 2 + wager / 2;
            player.setCash(winnings);
            player.setBank(winnings - wager);
            result = "Blackjack!";
            GameObj['engine'].profit = winnings;
        } else if (pscore <= 21) {
            winnings = wager * 2;
            player.setCash(winnings);
            player.setBank(winnings - wager);
            result = "You win!";
            GameObj['engine'].profit = winnings;
        } else if (pscore > 21) {
            winnings -= wager;
            player.setBank(winnings);
            result = "Bust";
            GameObj['engine'].profit = 0;
        }
    } else if (pscore < dscore) {
        if (pscore <= 21 && dscore > 21) {
            winnings = wager * 2;
            player.setCash(winnings);
            player.setBank(winnings - wager);
            result = "You win - dealer bust!";
            GameObj['engine'].profit = winnings;
        } else if (dscore <= 21) {
            winnings -= wager;
            player.setBank(winnings);
            GameObj['engine'].profit = 0;
            result = "You lose!";
        }
    } else if (pscore === dscore) {
        if (pscore <= 21) {
            if (dscore === 21 && dhand.length < 3 && phand.length > 2) {
                winnings -= wager;
                player.setBank(winnings);
                result = "You lose - dealer Blackjack!";
                GameObj['engine'].profit = 0;
            } else {
                winnings = wager;
                player.setCash(winnings);
                result = "Push";
                GameObj['engine'].profit = winnings;
            }
        } else {
            winnings -= wager;
            player.setBank(winnings);
            result = "Bust";
            GameObj['engine'].profit = 0;
        }
    }

    GameObj['engine'].busted();
    showAlert(result);

    dealer.flipCards();
    dealer.updateBoard();

    wait(2000).then(() => {
        resetBoard();
    })
}

function showAlert(msg) {
    $("#alert span").html("<strong>" + msg + "</strong>");
    $("#alert").fadeIn();
}

function resetBoard() {
    $("#dhand").html("");
    $("#phand").html("");
    $("#result").html("");
    $("#phand, #dhand").css("left", 0);
    $("#a").html("");
    $("#b").html("");
    $('#alert').fadeOut();
}

Number.prototype.formatMoney = function(c, d, t) {
    var n = this,
        s = n < 0 ? "-" : "",
        i = parseInt((n = Math.abs(+n || 0).toFixed(c))) + "",
        j = i.length;
    j = j > 3 ? j % 3 : 0;
    return s + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : '');
};

export default Game;