/* eslint-disable */
import {__, isMobile, wait, randomColor, getRandomBetween} from "../../../Helper";

let loaded = false;

function Canvas(){
    let self = this;

    self.machine = "./assets/images/hashdice/machine5.svg";
    self.ASSETS = [ self.machine ];

    self.app = null;
    self.container = null;
    self.btn = null;
    self.hbtn = null;
    self.lbtn = null;
    self.abtn = null;
    self.id = null;
    self.numbers = [];
    self.texts = [];
    self.fontSize = 100;
    self.textY = 90;
    self.magic = 0;
    self.started = false;
    self.numbers_color = "#0cf8b3";
    
    self.init = function () {
         self.app = new PIXI.Application({
            destroy: true,
            autoStart: false,
            width: 621,
            height: 245,
            antialias: true,
            transparent: true,
            resolution: 1,
            renderer: "canvas",
            view: document.getElementById("hashdicegame"),
        });

        self.app.stage.sortableChildren = true;

        if(isMobile())
        {
            self.fontSize = 45;
            self.textY = 35;
            self.app.renderer.autoDensity = true;
            self.app.renderer.resize(310, 138);
            self.machine = "./assets/images/hashdice/machine6.svg";
            self.ASSETS = [ self.machine ];
        }

        if(!loaded){
            loaded = true;
            PIXI.loader.add(self.ASSETS).load(self.start);
        }
        else {
            self.start(); // fix for blank canvas
        }
    }
    
    self.destroy = function () {
        self.ASSETS = null;
        self.machine = null;
        self.app = null;
        self.container = null;
        self.fontSize = 100;
        self.numbers = [];
        self.texts = [];
        self.textY = 55;
        self.id = null;
    }

    self.start = function () {
        const container = new PIXI.Container();
        container.sortableChildren = true;
        self.container = container;
        self.app.stage.addChild(container);

        self.addMachine(container);

        for(var i = 0; i < 5; i++){
           self.numbers[i] = self.addNumbers(self.textY, 0, i);
        }

        //Start Render Canvas
        self.app.start();

        let count = 0;

        // Start Animations
        self.app.ticker.add((delta) => {
            count += 0.1;

                for(var i = 0; i < 5; i++){
                    if(!__.isUndefined(self.numbers[i])){
                        self.numbers[i]._style._fontSize = (getRandomBetween(self.fontSize, self.fontSize * 2))  + 'px'
                        // self.numbers[i]._style._fontSize -= Math.cos(getRandomBetween(self.fontSize, self.fontSize * 2))  + 'px'
                    }
                }

            if(!self.started){
                for(var i = 0; i < 5; i++){
                    if(!__.isUndefined(self.numbers[i])){
                        self.numbers[i].scale.x += 1 * (0.05 * delta)
                        if(self.numbers[i].scale.x > 1){
                            self.numbers[i].scale.x = 1
                        }
                    }
                }
            }
        })
    }

    self.addMachine = function(){
        const graphics = new PIXI.Graphics();
        graphics.lineStyle(0);
        graphics.beginFill(0x1b2336, 0.20);

        let y = 0;

        if(isMobile())
            y = 10

        graphics.drawRoundedRect(0, y, self.app.screen.width, self.app.screen.height - 50, 10);
        graphics.endFill();
        self.container.addChild(graphics);
    }

    self.addNumbers = function (y, t, id) {
        if(self.container === null) return;
        
        self.container.removeChild(self.texts[id]);

        const style = new PIXI.TextStyle({
            fontFamily: "Titillium Web",
            fontWeight: "bold",
            fontSize: self.fontSize,
            dropShadow: true,
            dropShadowColor: '#000000',
            dropShadowBlur: 4,
            dropShadowAngle: Math.PI / 5,
            dropShadowDistance: 5,
            fill: [ self.numbers_color, randomColor() ],
        });

        self.texts[id] = new PIXI.Text(t, style);

        if(!isMobile())
            self.texts[id].x = ((id  * 100) + 60) + 50
        else
            self.texts[id].x = id * (self.app.screen.width / 5) + 30

        self.texts[id].y = self.app.screen.height / 2.5;
        self.texts[id].sortChildren(1)
        self.texts[id].zIndex = 1;
        self.texts[id].scale.x = -1
        self.texts[id].anchor.set(0.5);
        self.texts[id].interactive = true;
        self.container.addChild(self.texts[id]);

        return self.texts[id]
    }

    self.set = function (id, target) {
        self.numbers[id] = self.addNumbers(self.textY, target, id);
    }
    
    self.setAnim = function (color) {
        self.numbers_color = color
    }
}

export default Canvas;