import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Row, Col, Button, Card, Dropdown} from "react-bootstrap";
import RangeCredit from "../../Components/Game/Addons";
import {Event, isValidNumber, forceSatoshiFormat, sendNotfication, wait} from "../../../Helper";
import {setRisk} from "../../../actions/gameOption";
import {gameCoin} from "../../../actions/gameCoin";
import {setWallet} from "../../../actions/gameWallet";
import storage from "../../../Storage";
import C from "../../../Constant";

class Bet extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            engine: this.props.engine,
            bet: 'manual',
            started: false,
            risk: '8',
            riskName: 'Normal',
            amount: forceSatoshiFormat(storage.getKey('lam') ? storage.getKey('lam') : .00000010),
            isLogged: storage.getKey("logged") !== null ? true : false,
            credit: storage.getKey('credit'),
            rollDiceDisabled: false,
            cashoutDisabled: true,
            buttonText: 'Roll Dice',
            profit: 0
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        let { engine } = this.state;
        this.props.gameCoin();
        engine.on(C.PLAY_SNAKEANDLADDERS, (data) => this.play(data));
        engine.on(C.NEXT_SNAKEANDLADDERS, (data) => this.next(data));
        engine.on(C.CLICKED_SNAKEANDLADDERS, (data) => this.clicked(data));
        engine.on(C.CASHOUT_SNAKEANDLADDERS, (data) => this.cashout(data));
        engine.on(C.BUSTED_SNAKEANDLADDERS, (data) => this.busted(data));
        engine.on(C.ERROR_SNAKEANDLADDERS, (data) => this.error(data));
    }
    
    componentWillMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        let { engine } = this.state;
        this._isMounted = false;
        engine.init = false;
    }

    error(data) {
        if(this._isMounted){
            sendNotfication(data.message, 'info', 'top-center');

            if(data.code === 'credit')
                this.props.setWallet(true, data.uid);

            this.setState({ rollDiceDisabled: false, cashoutDisabled: true });
            this.setState({ started: false, buttonText: 'Roll Dice' });
        }
    }

    handleInputChange(event){
        let target = event.target;
        let value = target.value;

        if(target.name === 'amount'){
            if(!isValidNumber(value)) return;
        }
        
        if(target.name === 'amount'){
            storage.setKey('lam', value);
        }

        this.setState({ [target.name]: value });
    }

    handleBet(e) {
        e.preventDefault();
        let {  amount, isLogged, bet, started } = this.state;

       // Check User
        if(!isLogged){
           return Event.emit('showAuthModal', true);
        }
        
        if(parseFloat(amount) <= 0){
            this.setState({ amount: forceSatoshiFormat(0.00000010) });
        }
        else {
            amount = Math.min(parseFloat(storage.getKey('credit')), parseFloat(amount));
            this.setState({ amount: forceSatoshiFormat(amount) });
        }

        this.setState({ rollDiceDisabled: true, cashoutDisabled: true })

        if(started){
            this.rollDice();
        }
        else {
            this.placeBet();
        }
    }

    handleCashout(e){
        if(this._isMounted){
            let { engine, amount, profit } = this.state;
            this.setState({ started: false })

            wait(100)
                .then(() => {
                    engine.init = true;
                    engine.profit = profit;
                    engine.cashout();
                })
        }
    }

    rollDice(){
        let { engine } = this.state;
        wait(100).then(() => {
            engine.init = true;
            engine.next();
        })
    }

    placeBet(){
        if(this._isMounted){
            let { engine, amount } = this.state;
            this.setState({ started: true })
            wait(100)
                .then(() => {
                    engine.init = true;
                    engine.amount = amount;
                    engine.coin   = this.props.coin;
                    engine.start();
                })
        }
    }

    cashout(data){
        if(this._isMounted){
            this.setState({  rollDiceDisabled: false, cashoutDisabled: true, started: false, profit: 0 });
            this.state.engine.init = false;
        }
    }

    busted(data){
        if(this._isMounted){
            let { engine, bet } = this.state;

            wait(100)
                .then( () => {
                    this.setState({  rollDiceDisabled: false, cashoutDisabled: true, started: false, profit: 0 });
                    engine.init = false;
                })
        }
    }

    clicked(data) {
        if(this._isMounted){
            this.setState({ rollDiceDisabled: false, cashoutDisabled: false })
            this.setState({ profit: parseFloat(this.state.profit) + parseFloat(data.profit) });
        }
    }

    play(data) {
        if(this._isMounted){
        }
    }

    next(data) {
        if(this._isMounted){
        }
    }

    handleChange = value => {
        this.setState({ risk: value });
        this.props.setRisk(value);
        this.setState({ riskName: value });
    }
    
    setAmount = (val) => {
        var am = forceSatoshiFormat(val);
        this.setState({ amount: am });
        storage.setKey('lam', am );
    }

    render() {
        let {rollDiceDisabled, cashoutDisabled, amount, bet, riskName} = this.state;

        return (
            <>
                <Row>
                    <Col lg={12} md={12} sm={12} className={'m-auto'}>
                        <Card className={'mb-0'}>
                            <Card.Body className={'px-2 pt-2 py-0'}>
                                <Row>
                                    <Col xl={6} md={8} sm={12}>
                                        <div className={"form-group mb-1 bet-input"}>
                                            <div className="input-group">
                                                <div className="input-group-append">
                                                <span className="input-group-text">
                                                    <img src={'/assets/images/' + this.props.coin + '.png'} className={'mini-coin-7'} alt=""/>
                                                    BET
                                                </span>
                                                </div>
                                                <input disabled={rollDiceDisabled} type="text" className="form-control text-left" id="amount" name="amount"
                                                       placeholder=".." value={amount} autoComplete={"off"} onKeyUp={this.handleInputChange} onChange={this.handleInputChange} />
                                                <div className="input-group-append">
                                                    <RangeCredit set={this.setAmount} />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xl={6} md={4} sm={12}>
                                        <div className={"form-group mb-1"}>
                                            <div className="input-group fw">
                                                <div className="input-group-append">
                                                    <span className="input-group-text">Difficulty</span>
                                                </div>
                                                <Dropdown className={"drop-select btn-block"}>
                                                    <Dropdown.Toggle split variant="" className={'py-0 mt-0'} >
                                                    <span className="text-muted text-center">
                                                        <i className={'mdi mdi-chevron-down mr-1'} />
                                                        {riskName}
                                                     </span>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu disabled={rollDiceDisabled} className={"dopdown-menu-bottom " + rollDiceDisabled }>
                                                        <Dropdown.Item onClick={e => this.handleChange('Normal')} className={'animated fadeIn'}>
                                                            Normal
                                                        </Dropdown.Item>
                                                        <Dropdown.Item onClick={e => this.handleChange('Hard')} className={'animated fadeIn'}>
                                                            Hard
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6} className={"m-auto"}>
                                        <Button variant={'btn my-1 btn-block btn-bet btn-sm'}
                                                onClick={(e) => {this.handleBet(e)}}
                                                disabled={rollDiceDisabled}
                                                type="submit">
                                            {this.state.buttonText}
                                        </Button>
                                    </Col>
                                    <Col md={6} className={"m-auto"}>
                                        <Button variant={'btn my-1 btn-block btn-bet btn-sm'}
                                                onClick={(e) => {this.handleCashout(e)}}
                                                disabled={cashoutDisabled}
                                                type="submit">
                                                Cashout {forceSatoshiFormat(this.state.profit)}
                                        </Button>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </>
        );
    }
}

Bet.propTypes = {
    setRisk: PropTypes.number,
    coin: PropTypes.string
};

const mapStateToProps = state => ({
    coin: state.items.coin
});

export default connect(mapStateToProps, { setRisk, gameCoin, setWallet })(Bet);