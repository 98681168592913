import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Row, Col, Card} from "react-bootstrap";
import {setPlay} from "../../../actions/gamePlay";
import {setRisk} from "../../../actions/gameOption";
import {__, wait, getSingleRandomInt, randomColor, playAudio} from "../../../Helper";
import C from "../../../Constant";
import Canvas from "./components/Canvas";

class Game extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state ={
            engine: this.props.engine,
            canvas: null
        }
    }

    componentDidMount(){
        this._isMounted = true;

        var canvas = new Canvas();
            canvas.init(this.state.engine);
            
        this.setState({ canvas: canvas })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        //Prevent For Error, IMPORTANT
        this.props.setPlay(false);

        // Redux set Play
        if(nextProps.play === true){
            this.state.canvas.play();
        }
        // Redux set Risk
        if(nextProps.risk){
            // TODO
        }
    }

    componentWillUnmount() {
        this._isMounted = false;

        if(this.state.canvas !== null)
            this.state.canvas.destroy();
    }

    render() {
        return <>
              <div className="text-center animated fadeIn">
                 <canvas id="game" />
              </div>
        </>;
    }
}

Game.propTypes = {
    setPlay: PropTypes.bool,
    setRisk: PropTypes.func,
    play: PropTypes.string
};

const mapStateToProps = state => ({
    play: state.items.play,
    risk: state.items.risk
});

export default connect(mapStateToProps, { setPlay })(Game);