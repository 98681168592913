import {store} from "react-notifications-component";
import formatCurrency from "format-currency";
import _ from "lodash";
import md5 from "md5";
import * as Cookies from "js-cookie";
import EventEmitter from "events";
import storage from "./Storage"

/**
 * APP Mode
 * @type {bool}
 */
export const DEVELOPMENT = false;

/**
 * Lodash
 * @type {lodash}
 * @private
 */
export const __ = _;

/**
 * Bulk Bet API Key
 * @type {string}
 */
export const BULK_BET_API = "25DB7D8CC2591A1D7040FABB313BFEF6";

/**
 *
 * Site URL
 * @type {string}
 */
export const SITE = DEVELOPMENT ? 'http://localhost:3000' : 'https://coingator.io';

/**
 *
 * General Socket Address
 * @type {string}
 */
export const SOCKET = DEVELOPMENT ? 'ws://127.0.0.1:3004' : 'wss://api.coingator.io';

/**
 * General API Address
 * @type {string}
 */
export const API = DEVELOPMENT ? 'http://127.0.0.1:3004' : 'https://api.coingator.io';


/**
 * Show Bonus Wheel
 * @type {string}
 */
export const BONUS_WHEEL = false;

/*
 * Support Skype
*/
export const SUPPORT_SKYPE = 'https://join.skype.com/invite/lKdwz9dFnp2d';

/**
 * Event Emitter
 */
export const Event = new EventEmitter();
Event.setMaxListeners(0)

/**
 * Giphy API Key
 * @type {string}
 */
export const GIPHY_API = '70PnTlRw1xvraiQlQxhYOSJO2H6Cq06C';

/**
 * Google Client ID for oAuth
 * Secret: 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
 * @type {string}
 */
export const CLIENT_ID = DEVELOPMENT ? '838561129917-0u6l6pealopfoni4mcsd3jpcrq55qe9c.apps.googleusercontent.com' : '613489935012-o365sr2e73q54e1ls6slanku75ba8ft0.apps.googleusercontent.com';

/**
 * Google Recaptcha v3 / Site Key
 * @type {string}
 */
export const RECAPTCHA = DEVELOPMENT ? '6Lch39MfAAAAAKNrybEJi_r8OnT8TtWH6SaqJ3ne' : '6Lch39MfAAAAAKNrybEJi_r8OnT8TtWH6SaqJ3ne';

/**
 * defaultAvatar
 * @type {string}
 */
export const defaultAvatar = DEVELOPMENT ? 'http://127.0.0.1:3004/uploads/avatar.png' : 'https://api.coingator.io/uploads/avatar.png';

/**
 * Readable Crypto Format ( 0.00000009 )
 * @param value
 * @return {*}
 */
export function toCrypto(value) {
    var number = value * 100000000;
    return (number).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1");
}

/*
 * Check string is utf-8
*/
export function isUTF8(text) {
    var utf8Text = text;
    try {
        utf8Text = decodeURIComponent(escape(text));
        if(utf8Text) return false;
    } catch(e) {
       return true;
    }
    return true;
}

/**
 * Readable Satoshi Format ( 0.00000010 )
 * @param value
 * @param coin
 * @return {*}
 */
export function forceSatoshiFormat(val, coin = "BTC", usdFormat = false) {
  Number.prototype.format = function(n, x, s, c) {
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
      num = this.toFixed(Math.max(0, ~~n));

    return (c ? num.replace('.', c) : num).replace(
      new RegExp(re, 'g'),
      '$&' + (s || ',')
    );
  };

  var amount = parseFloat(val);
  
  var changed;

  if(isNaN(amount))
    changed = val;
  else
      changed = Number(amount.format(8, 30, '.','.')).toFixed(8);

  if(usdFormat){
    return cryptoToUsd(changed, coin);
  }
  else
    return changed;
}

/**
 * Crypto to USD
 * @param value
 * @param coin
 * @return {*}
 */
export function cryptoToUsd(val, coin = "BTC") {
    var usd = Game[_.upperCase(coin)]; // By /Socket
    var convert = parseFloat(val) * parseFloat(usd);
    return toUSD(convert)
}

/*
 * Crypto to USD
*/
export function usdToCrypto(value, coin) {
    var usd = Game[_.upperCase(coin)]; // By /Socket
    var convert = value / parseFloat(usd);
    return toUSD(_.toNumber(convert))
}

/*
 * USD Format
*/
export function toUSD(value) {
    let opts = { format: '%v %c', code: 'USD', maxFraction: 6, minFraction: 4 }
    let format = formatCurrency(value, opts);
    format = __.replace(format, ',', '');
    return parseFloat(format).toFixed(4) + ' USD';
}

/*
 * Get User ID from Coockie
*/
export const getUID = parseFloat(Cookies.get('uid'))

/**
 * Validate number
 * @param val
 * @return {*}
 */

export function isValidNumber(val) {
    if(!isNaN(val)){
        var check = /^[-]?\d+|\d+.$/.test(val);
        if(check){
            return true;
        }
    }
    return false;
}

/**
 * Validate Email
 * @param email
 * @return {boolean}
 */
export function isEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

/**
 * Check Client is Mobile or Not
 * @return {boolean}
 */
export function isMobile() {
    let clientWidth = document.documentElement.clientWidth || document.body.clientWidth;
    return clientWidth < 992
}

/**
 * Check Client is Tablet or Not
 * @return {boolean}
 */
export function isTablet() {
    let clientWidth = document.documentElement.clientWidth || document.body.clientWidth;
    return clientWidth < 1100
}

/**
 * Remove Value From Array
 * @param array
 * @param item
 */
export function removeItem(array, item){
    for( var i = 0; i < array.length; i++){
        if (array[i].name === item) {
            array.splice(i, 1); i--;
        }
    }
    return array;
}

/**
 * Get Element with validator
 * @param name
 * @return {boolean|any}
 */
export function getElement(name) {
    if(document.querySelector(name) !== null)
        return document.querySelector(name);
    return document.getElementById('helper');
}

/**
 * Convert TimeStamp To Readbale Date
 * @param unixTimestamp
 * @return {string}
 */
export function timeConvertor(unixTimestamp) {
    let now = new Date(_.toNumber(unixTimestamp));
    var sec  = now.getSeconds();
    var hr  = now.getHours();
    var min = now.getMinutes();
    hr = (hr<10) ? '0'+hr : ''+hr;
    min = (min<10) ? '0'+min : ''+min;
    min = (sec<10) ? '0'+sec : ''+sec;

    var y = now.getUTCFullYear();
    var m = (now.getUTCMonth()+1);
    var d = now.getUTCDate();
    var date = y + "-" + m + "-" + d;

    return date + ' - ' + hr + ':' + min + ':' + sec;
}

/**
 * Convert TimeStamp To Time
 * @param unixTimestamp
 * @return {string}
 */
export function convertToTime(unixTimestamp) {

    if(unixTimestamp == undefined)
        return 'Now';

    let date = new Date(_.toNumber(unixTimestamp));
    let options = {
        hour: 'numeric',
        minute: 'numeric',
        // second: 'numeric',
        hour12: true
    };
    return date.toLocaleString('en-US', options);
}

/*
 * Fix Date and Time For Human
 * Date Comming From Database with now() function
*/
export function fixDate(date){
    if(date === undefined) return date;
    if(date === null) return date;
    
    date = date.toString();
    var fix = date.replace('T', ' ');
    fix = fix.replace('Z', '');
    fix = fix.substr(0, date.length)
    let last =  fix.substr(fix.length - 4);
    return fix.replace(last, '');
}

/*
 * Get Just Date By DB
 * Date Comming From Database with now() function
*/
export function getDateByDb(date){
    if(date === undefined) return date;
    date = date.toString();
    return date.substr(0, 11);
}

/*
 * Get Just Time By DB
 * Date Comming From Database with now() function
*/
export function getTimeByDb(date){
    if(date === undefined) return date;
    date = date.toString();
    var fix = date.replace('T', ' ')
    fix = fix.replace('Z', '')
    fix = fix.substr(0, date.length)
    let last =  fix.substr(fix.length - 4);
    fix = fix.replace(last, '')
    return fix.substr(10, 6);
}

/**
 * Making Game Object
 * @type {any}
 */
export const Game = Object.create(null);

/**
 * Making User Object
 * @type {any}
 */
export const User = Object.create(null);

/**
 *
 * SetTimeOut Promise
 * @param ms
 * @return {Promise<any>}
 */
export const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));

/**
 * Send Notification
 * @param message
 * @param type
 * @param position
 * @return {*}
 */
export function sendNotfication(message, type, position, welcome = false) {
    if(!message) return;

      store.addNotification({
        message: _.toString(message),
        type: welcome ? 'success' : 'danger',
        insert: "top",
        container: 'bottom-left',
        animationIn: ["animated", "fadeInLeft"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
            duration: 6000,
            onScreen: true
        }
    });
}

/**
 * Get Random Integer Between with ceil
 * @param min
 * @param max
 * @return {number}
 */
export function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * Get Random Integer Between
 * @param min
 * @param max
 * @return {number}
 */
export function getRandomBetween(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * Get Random Integer 2
 * @param min
 * @param max
 * @return {number}
 */
export function getSingleRandomInt(length) {
    return Math.floor(Math.random()* length);
}

/**
 * Generate Random String with Integer
 * @param length
 * @return {string|string}
 */
export function randomString(length){
    var chars = '0123456789abcdefghiklmnopqrstuvwxyz'.split('');
    var str = '';
    for (var i = 0; i < length; i++) {
        str += chars[Math.floor(Math.random() * chars.length)];
    }
    return str;
}

/**
 * Generate Random String
 * @param length
 * @return {string|string}
 */
export function justRandomString(length){
    var chars = 'aBcDeFgHiKlMnOpQrStUvWxyZ'.split('');
    var str = '';
    for (var i = 0; i < length; i++) {
        str += chars[Math.floor(Math.random() * chars.length)];
    }
    return str;
}

/**
 * Generate Random Color
 * @return {string|string}
 */
export function randomColor(){
    for (var e = "#", t = 0; t < 6; t++)
        e += "0123456789ABCDEF"[Math.floor(16 * Math.random())];
    return e;
}

/*
 * Check Element Have ClassName
*/
export function hasClass(element, className) {
    if(className === null || className === undefined || element === null) return false;
    return (' ' + element.className + ' ').indexOf(' ' + className+ ' ') > -1;
}

/*
 * Check is Function
*/
export function isFunction(functionToCheck) {
 return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

/*
 * Add New Style
*/
export function addNewStyle(newStyle) {
    var styleElement = document.getElementById('styles_js');
    if (!styleElement) {
        styleElement = document.createElement('style');
        styleElement.type = 'text/css';
        styleElement.id = 'styles_js';
        document.getElementsByTagName('head')[0].appendChild(styleElement);
    }
    styleElement.appendChild(document.createTextNode(newStyle));
}

/*
 * Make Full Screen
*/
export function requestFullScreen(elem) {
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.webkitRequestFullscreen) { /* Safari */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) { /* IE11 */
    elem.msRequestFullscreen();
  }
}

/*
 Get Evo Games Name
*/
export function getGameName(id){
    id = parseFloat(id)
    switch(id){
            case 949:
                return 'roll the dice'
            case 5743:
                return 'panchin girl'
            case 946:
                return 'european roulette'
            case 5761:
                return 'my steries of the east'
            case 1009:
                return 'american roulette'
            case 995:
                return 'redqueen'
            case 5553:
                return 'texas holdem poker 3d'
            case 967:
                return 'baccarat'
            case 5669:
                return 'french roulette classic'
            case 745:
                return 'blackjack'
            case 961:
                return 'more or less'
            case 5344:
                return 'high striker'
            case 952:
                return 'thimbles'
            case 5525:
                return 'bomb squad'
            case 5523:
                return 'magic wheel'
            case 5573:
                return 'mine field'
            case 985:
                return 'rock paper scissors'
            case 1006:
                return 'indian poker'
            case 5759:
                return 'unlimited wishes'
            case 1012:
                return 'etraces'
            case 110:
                return 'sindbad'
            case 25:
                return 'clash of pirates'
            case 5736:
                return 'wheel of time'
            case 1003:
                return 'trip to the future'
            case 5585:
                return 'crown anchor'
            case 5452:
                return 'dungeon'
            case 5755:
                return 'runes of destiny'
            case 5742:
                return 'tree of light'
            case 5738:
                return 'valley of deams'
            case 5569:
                return 'riseofhorus'
            case 5741:
                return 'fruit super nova'
            case 5653:
                return 'midnight show'
            case 5398:
                return 'nuke world'
            case 5763:
                return 'sweet sugar'
            case 5758:
                return 'ellens fortune'
            case 5717:
                return 'raccoon tales'
            case 5593:
                return 'rocket stars'
            case 1018:
                return 'reign of dragons'
            case 5751:
                return 'wild bullets'
            case 5737:
                return 'night of the living tales'
            case 5641:
                return 'fluffy rangers'
            case 5673:
                return 'seaof spins'
            case 82:
                return 'elven princess'
            case 5487:
                return 'hungry night'
            case 5545:
                return 'mafia'
            case 5479:
                return 'brutal santa'
            case 979:
                return 'charming queens'
            case 107:
                return 'football'
            case 98:
                return 'atlantis'
            case 85:
                return 'aceround'
            case 88:
                return 'fruit burst'
            case 34:
                return 'vegas nights'
            case 722:
                return 'seventies'
            case 5748:
                return 'christmas party'
            case 725:
                return 'aeronauts'
            case 736:
                return 'battle tanks'
            case 94:
                return 'legend of era'
            case 113:
                return 'island totems'
            case 104:
                return 'cabaret'
            case 742:
                return 'dolphin streasure'
            case 719:
                return 'slavs'
    }
}

/*
 Get Evo Games ID
*/
export function getGameID(game){
        switch(game){
            case "rolldice":
                return 949
            case "pachingirl":
                return 5743
            case "europeanroulette":
                return 946
            case "mysteriesoftheeast":
                return 5761
            case "americanroulette":
                return 1009
            case "redqueen":
                return 955
            case "texasholdempoker3d":
                return 5553
            case "baccarat":
                return 967
            case "frenchrouletteclassic":
                return 5669
            case "blackjack":
                return 745
            case "moreorless":
                return 961
            case "highstriker":
                return 5344
            case "thimbles":
                return 952
            case "bombsquad":
                return 5525
            case "magicwheel":
                return 5523
            case "minefield":
                return 5573
            case "rockpaperscissors":
                return 985
            case "indianpoker":
                return 1006
            case "unlimitedwishes":
                return 5759
            case "etraces":
                return 1012
            case "sindbad":
                return 110
            case "clashofpirates":
                return 25
            case "wheeloftime":
                return 5736
            case "triptothefuture":
                return 1003
            case "crownanchor":
                return 5585
            case "dungeon":
                return 5452
            case "runesofdestiny":
                return 5755
            case "treeoflight":
                return 5742
            case "valleyofdeams":
                return 5738
            case "riseofhorus":
                return 5569
            case "fruitsupernova":
                return 5741
            case "midnightShow":
                return 5653
            case "nukeworld":
                return 5398
            case "sweetsugar":
                return 5763
            case "ellensfortune":
                return 5758
            case "raccoontales":
                return 5717
            case "rocketstars":
                return 5593
            case "reignofdragons":
                return 1018
            case "wildbullets":
                return 5751
            case "nightofthelivingtales":
                return 5737
            case "fluffyrangers":
                return 5641
            case "seaofspins":
                return 5673
            case "elvenprincess":
                return 82
            case "hungrynight":
                return 5487
            case "mafia":
                return 5545
            case "brutalsanta":
                return 5479
            case "charmingqueens":
                return 979
            case "football":
                return 107
            case "atlantis":
                return 98
            case "aceround":
                return 85
            case "fruitburst":
                return 88
            case "vegasnights":
                return 34
            case "seventies":
                return 722
            case "christmasparty":
                return 5748
            case "aeronauts":
                return 725
            case "battletanks":
                return 736
            case "legendofra":
                return 94
            case "islandtotems":
                return 113
            case "cabaret":
                return 104
            case "dolphinstreasure":
                return 742
            case "slavs":
                return 719
        }
}

/*
 * Security
*/
export function chkd(){
    // if(DEVELOPMENT) return true;
    // const h = window.location.host ? window.location.host : window.location.hostname;
    // const h5 = md5(h);
    
    // if(h5 !== '7638283f70c10db29b70bd15353c3246')
    // {
        // document.querySelector('body')['remove']();
        // for(var i = 0; i < 2550; i++)
        // {
            // if(SOCKET !== 'wss://api.coingator.io')
            // {
                // Event.emit('security');
            // }
        // }
        // return false;
    // }
    
    return true
}

export function playAudio(audio){
    if(storage.getKey('audio') !== 'off')
        new Audio('/assets/sounds/' + audio).play();
}

export function encode(data){
    try{
        return new Blob([ JSON.stringify(data) ])
    }
    catch(e){}
}

export function decode(data){
    try{
        var responseData = new TextDecoder().decode(data)
        return JSON.parse(responseData)
    }
    catch(e){}
}