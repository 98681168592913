import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Row, Col, Card} from "react-bootstrap";
import axios from "axios";
import C from "../../Constant";
import {__, encode, decode, wait, Event, BULK_BET_API, getUID} from "../../Helper";
import socket from "../../Socket";
import storage from "../../Storage";
import {gameCoin} from "../../actions/gameCoin";
import slots from './Providers/bulk.json';

class Frame extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            started: false,
            message: null,
            height: 505,
            token: storage.getKey('token'),
            game: this.props.game,
            url: null,
            demo_url: this.props.demo_url,
            disabled: true,
            disabledDemo: false,
            loader: false,
            loaderDemo: false
        }
    }

    componentDidMount(){
        this._isMounted = true;
        
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        
        this.props.gameCoin();
        
        socket.on(C.PLAT_SLOTS, (data) => this.play(decode(data)));

        Event.on('coin_changed', () => this.restartGame());
        Event.on('game_changed', () => this.checkAndStart());

        if(this.state.token !== null)
            this.setState({ disabled: false });

        slots.forEach((item, c) => {
            if(item.gameid === this.state.game){
                this.setState({ background: item.image_1 })
            }
        })
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    checkAndStart(){
        this.setState({ started: false });
    }

    restartGame = () => {
        if(this._isMounted)
        {
            if(this.state.started)
            {
                this.setState({ started: false, url: null });
                wait(400).then(() => {
                    this.startReal();
                })
            }
        }
    }

    startDemo = () => {
        this.setState({ loaderDemo: true, disabledDemo: true })

        let self = this;

        slots.forEach((item) => {
            if(item.gameid === this.props.game){
                self.setState({ loader: false, disabled: false, started: true, url : item.demo_url });
            }
        })

        // Static Demo URL
        //self.setState({ loader: false, disabled: false, started: true, url : this.props.demo_url });

        /*const URL = `https://api.bulk.bet/v2/createSession?apikey=${BULK_BET_API}&userid=demo&game=${this.props.game}&mode=demo`;
        axios({
            url: URL,
            method: 'GET',
        })
        .then(function(req){
            var response = req.data;

            if(response.status === 'ok')
                self.setState({ loaderDemo: false, disabledDemo: false, started: true, url : response.url })
        })*/
    }

    startReal = () => {
        let self = this;
        
        if(this.state.token !== null)
        {
            if(__.lowerCase(this.props.coin) === 'nexo'){
                return this.setState({ message: "NEXO is disabled in the slot games."})
            }
            if(__.lowerCase(this.props.coin) === 'nc'){
                return this.setState({ message: "NC is the test coin and can't work on the slot games."})
            }
            
            this.setState({ loader: true, disabled: true });

            this.setState({ message: null })


            const URL = `https://api.bulk.bet/v2/createSession?apikey=${BULK_BET_API}&userid=${getUID}&game=${this.state.game}&mode=real`;

            axios({
                url: URL,
                method: 'GET',
            })
            .then(function(req){
                var response = req.data;

                if(response.status === 'ok')
                    self.setState({ loader: false, disabled: false, started: true, url : response.url })
            })

            // socket.emit(C.PLAT_SLOTS, encode({ game: this.state.game, coin: this.props.coin }))
        }
    }

    play = (link) => {
        this.setState({ loader: false, disabled: false, started: true, url : link })
    }

    render() {
        return (
            <>
                {this.state.started ?
                    <iframe className={'fullScreen'} src={this.state.url}></iframe>
                    :
                    <div style={{ background: `url(${this.state.background}) center center / 100% no-repeat` }} className="sc-jEUCeg bSWQnZ">
                        <div className="mask">
                            <div className="tips">
                                <p className="">
                                    Entering a third-party game. 
                                    The balance of the selected currency will be displayed in USD, and if you change currency while playing, the game will refresh and restart.
                                    <br/>
                                    {this.state.message}
                                </p>
                                <div className="btn-wrap">
                                    <button className="sc-kEjbxe sc-crrsfI cLOWRl jcNNmq btn btn-normal" disabled={this.state.disabled} onClick={this.startReal}>
                                        <div className="button-inner">
                                          {this.state.loader ? 
                                            <>
                                                <div class="spinner-border spinner-border-sm text-white mr-1" role="status" />
                                            </>
                                            :
                                            <>
                                                <i className="mdi mdi-play-circle-outline mr-1 align-middle" />
                                           </>
                                          }
                                          Real Play
                                        </div>
                                    </button>
                                    <button className="sc-kEjbxe sc-iqHYGH cLOWRl fRWXki btn btn-normal" disabled={this.state.disabledDemo} onClick={this.startDemo}>
                                        <div className="button-inner">
                                           {this.state.loaderDemo ? 
                                            <>
                                                <div class="spinner-border spinner-border-sm text-white mr-1" role="status" />
                                            </>
                                            :
                                            <>
                                              <i className="mdi mdi-play-circle-outline mr-1 align-middle" />
                                           </>
                                          }
                                          Free Play
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        );
    }
}

Frame.propTypes = {
    coin: PropTypes.string.isRequired,
    credit: PropTypes.string
};

const mapStateToProps = state => ({
    coin: state.items.coin,
    credit: state.items.credit,
});

export default connect(mapStateToProps, { gameCoin })(Frame);