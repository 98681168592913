/* eslint-disable */
class Message {
	constructor(m, x, y){
		this.message = m
		this.x = x
		this.y = y
		this.item = null;
		
		this.show();
	}
	
	show(){
		const style = new PIXI.TextStyle({
			fontFamily: 'Titillium Web',
			fontSize: 50,
			fontStyle: 'italic',
			fontWeight: 'bold',
			fill: ['#000', '#333'], // gradient
			stroke: 'yellow',
			strokeThickness: 5,
			dropShadow: true,
			dropShadowColor: '#000000',
			dropShadowBlur: 4,
			dropShadowAngle: Math.PI / 6,
			dropShadowDistance: 6,
			wordWrap: true,
			wordWrapWidth: 440,
			lineJoin: 'round',
		});
		
		const msg = new PIXI.Text(this.message, style);
		msg.anchor.set(0.5);
		msg.x = this.x
		msg.y = this.y
		msg.interactive = true
		this.item = msg;
	}
}

export default Message;