/* eslint-disable */
import Sprite from "../Sprite/index.js";
import Graphic from "../Graphic/index.js";
import Text from "../Text/index.js";
import Message from "../Text/Message.js";
import { wait, rand } from "../Helper/index.js";
import {Event, playAudio, forceSatoshiFormat, __} from "../../../../../Helper";

function Game(engine) {
    let self = this;
	
    self.engine = engine;
    self.app = null;
	self.stage = null;
	self.ticker = null;
    self.container = null;
	self.objects = {};
	self.started = false;
	self.currentLand = 89;
	self.oldLand = 89
	self.dice = 0;
	self.lost = false;
	
    self.init = function () {
        self.app = new PIXI.Renderer({
            view: document.getElementById("game"),
            width: 610,
            height: 600,
			transparent: true,
			antialias: false,
			resolution: window.devicePixelRatio
        });
		
		self.stage = new PIXI.Container();
        self.container = new PIXI.Container();
		self.container.sortableChildren = true;
        self.stage.addChild(self.container);
		
		self.width  = self.app.screen.width;
		self.height = self.app.screen.height;
		
		//Ticker
		self.ticker = new PIXI.Ticker();
		self.ticker.add(animate)
		self.ticker.start();
		
        function animate(delta){
			self.app.render(self.stage);
		}
		
		self.showMessage('Roll the Dice !')
		
		//Start Game
		self.start();
    }
	
	self.start = function(){
		
		//Add Lands
		self.objects[ 'land_container'] = new PIXI.Container();
		
        let id = 0;
		let c = 2;
        for (var i = 0; i < 10; i++) {
            for (var j = 0; j < 9; j++) {
                let x = 55 + j * 60;
                let y = i * 60;
				
				let name = 'land' + id
				
				let bg;
				
				if(c === 2){
					bg = 'background2.png'
					c = 1;
				}
				else if(c === 1){
					bg = 'background.png'
					c = 2;
				}
				
				self.objects[ name ] = new Sprite(bg, {x, y, width: 60, height: 60, anchor: true})
				
				self.objects[ 'text' + id ] = new Text(id, {size: 32, family: 'Arial', color: '#777'});
				self.objects[ name ].object.addChild(self.objects[ 'text' + id ].object)
				
				//Trap
				if(id === 22 || id == 45 || id === 80 || id === 11 || id === 50) {
					self.objects[ 'trap' + id ] = new Sprite('trap.png', {width: 70, height: 70, anchor: true })
					self.objects[ 'trap' + id ].object.alpha = .7
					self.objects[ 'text' + id ].object.visible = false;
					self.objects[ name ].object.addChild(self.objects[ 'trap' + id ].object)
				}
				
				//Finish
				if(id === 9) {
					self.objects[ 'flag' + id ] = new Sprite('flag.png', {width: 70, height: 90, x: -5, y: -20, anchor: true})
					const displacementSprite = PIXI.Sprite.from('./images/displacement_map_repeat.jpg');
					displacementSprite.texture.baseTexture.wrapMode = PIXI.WRAP_MODES.REPEAT;
					const displacementFilter = new PIXI.filters.DisplacementFilter(displacementSprite);
					displacementFilter.padding = 10;
					self.objects[ 'text' + id ].object.visible = false;

					displacementSprite.position = self.objects[ 'flag' + id ].object.position;
					self.container.addChild(displacementSprite);
					self.objects[ 'flag' + id ].object.filters = [displacementFilter];
					self.objects[ 'flag' + id ].animate((item, delta) => {
						displacementSprite.x++;
						if (displacementSprite.x > displacementSprite.width) { displacementSprite.x = 0; }
					})
					self.objects[ name ].object.addChild(self.objects[ 'land' + id ].object, self.objects[ 'flag' + id ].object)
				}
				
				self.objects[ 'land_container'].addChild(self.objects[ name ].object);
				self.objects['land_container'].removeChild(self.objects['land' + j].object)
                id++;
            }
        }
		
		self.container.addChild(self.objects[ 'land_container']);
		
		//Add Snake's
		var options = {
			x: 170,
			y: self.height - 115,
			width: 150,
			height: 150,
			anchor: true
		}
		self.objects[ 'snake1' ] = new Sprite('snake.png', options)
		self.objects[ 'snake1'].object.alpha = .9
		
		//Land 62
		var options = {
			x: self.width - 160,
			y: self.height - 220,
			width: 150,
			height: 150,
			rotation: 0.8,
			anchor: true
		}
		
		self.objects[ 'snake2' ] = new Sprite('snake2.png', options)
		self.objects[ 'snake2'].object.alpha = .9
		
		// Land 44
		var options = {
			x: self.width - 150,
			y: 265,
			width: 150,
			height: 150,
			rotation: 1.1,
			anchor: true
		}
		
		self.objects[ 'snake3' ] = new Sprite('snake2.png', options)
		self.objects[ 'snake3'].object.alpha = .9
		
		var options = {
			x: self.width - 0,
			y: 50,
			width: 150,
			height: 150,
			rotation: 40.7,
			anchor: true
		}
		
		self.objects[ 'snake4' ] = new Sprite('snake.png', options)
		self.objects[ 'snake4'].object.alpha = .9
		

		//Land 28
		var options = {
			x: 25,
			y: self.height / 4.1,
			width: 150,
			height: 150,
			rotation: 1.5,
			anchor: true
		}
		
		self.objects[ 'snake5' ] = new Sprite('snake.png', options)
		self.objects[ 'snake5'].object.alpha = .9
		
		self.container.addChild(self.objects[ 'snake1'].object,
								self.objects[ 'snake2'].object,
								self.objects[ 'snake3'].object,
								self.objects[ 'snake4'].object,
								self.objects[ 'snake5'].object
		);
		
		//Add Ladder
		var options = {
			x: self.width / 2 + 35,
			y: self.height - 120,
			width: 25,
			height: 180,
			rotation: 14.95,
			anchor: true
		}
		self.objects[ 'ladder' ] = new Sprite('ladder.png', options)
		
		var options = {
			x: 115,
			y: self.height - 235,
			width: 25,
			height: 180,
			rotation: 0.8,
			anchor: true
		}
		self.objects[ 'ladder2' ] = new Sprite('ladder.png', options)
		
		
		var options = {
			x: self.width - 125,
			y: 130,
			width: 25,
			height: 165,
			rotation: 11.8,
			anchor: true
		}
		self.objects[ 'ladder3' ] = new Sprite('ladder.png', options)
		
		self.container.addChild(self.objects[ 'ladder'].object, self.objects[ 'ladder2'].object, self.objects[ 'ladder3'].object);
		
		//Add Gem's
		var options = {
			x: self.objects['land12'].object.x,
			y: self.objects['land12'].object.y,
			width: 50,
			height: 50,
			anchor: true
		}
		
		self.objects[ 'gem12' ] = new Sprite('gem.png', options)
		self.objects[ 'gem12' ].object.alpha = .8
		self.objects[ 'text12' ].object.visible = false;
		
		self.objects[ 'gem12' ].animate((item, delta) => {
			item.rotation += 0.01 * delta
		})

		var options = {
			x: self.objects['land16'].object.x,
			y: self.objects['land16'].object.y,
			width: 50,
			height: 50
		}
		
		self.objects[ 'gem16' ] = new Sprite('gem2.png', options)
		self.objects[ 'gem16' ].object.alpha = .8
		self.objects[ 'text16' ].object.visible = false;
		
		self.objects[ 'gem16' ].animate((item, delta) => {
			item.rotation += 0.01 * delta
		})
		
		var options = {
			x: self.objects['land31'].object.x,
			y: self.objects['land31'].object.y,
			width: 50,
			height: 50,
			anchor: true
		}
		
		self.objects[ 'gem31' ] = new Sprite('gem3.png', options)
		self.objects[ 'gem31' ].object.alpha = .8
		self.objects[ 'text31' ].object.visible = false;
		
		self.objects[ 'gem31' ].animate((item, delta) => {
			item.rotation += 0.01 * delta
		})
		
		var options = {
			x: self.objects['land64'].object.x,
			y: self.objects['land64'].object.y,
			width: 50,
			height: 50,
			anchor: true
		}
		
		self.objects[ 'gem64' ] = new Sprite('gem3.png', options)
		self.objects[ 'gem64' ].object.alpha = .8
		self.objects[ 'text64' ].object.visible = false;
		
		self.objects[ 'gem64' ].animate((item, delta) => {
			item.rotation += 0.01 * delta
		})
		
		self.container.addChild(self.objects[ 'gem12'].object,
								self.objects[ 'gem16'].object,
								self.objects[ 'gem31'].object,
								self.objects[ 'gem64'].object
		);

		//Add Player
		self.objects['player'] = new Sprite('player.png', {anchor: true});
		self.objects['player'].object.sortChildren(99)
		self.objects['player'].object.zIndex = 99
		self.objects['land89'].object.addChild(self.objects['player'].object);
	}
	
	self.showMessage = function(txt){
		const message = new Message(txt, {x: self.width / 2, y: self.height / 2});
		self.container.addChild(message.object);
		
		let done = false, count = 1
		message.animate(1500, (item, delta) => {
			if(!done){
				count -= 0.1
				item.alpha = count;
				if(item.alpha < 0){
					self.container.removeChild(message.object);
					done = true;
					message.stop();
				}
			}
		})
	}
	
	self.restart = function(){
		for(var i = 0; i < 89; i++){
			self.objects['land' + i].object.removeChild(self.objects['player'].object);
		}
		self.objects['player'] = new Sprite('player.png', {anchor: true});
		self.objects['player'].object.sortChildren(99)
		self.objects['player'].object.zIndex = 99
		self.objects['land89'].object.addChild(self.objects['player'].object);
		self.currentLand = 89;
		self.oldLand = 89
		self.lost = false
	}

	self.play = function(target){
		// if(self.started) return;
		
		self.started = true;
		
		self.dice = parseFloat(target)
		self.oldLand = self.currentLand
		self.currentLand -= self.dice
		
		const message = new Message('0', {x: self.width / 2, y: self.height / 2});
		self.container.addChild(message.object);

		let done = false
		
		wait(2000).then(() => {
			done = true;
			wait(1000).then(() => {
				self.container.removeChild(message.object);
				self.movePlayer();
			})
		})
		
		message.animate((item, delta) => {
			if(!done){
				item.text = rand(1, 6)
			}
			else {
				item.text = self.dice;
				message.stop();
			}
		})
	}
	

	self.movePlayer = function(){
		wait(1000).then(() => {
			
			let move = self.oldLand - 1;
			
			let moved = 0
			
			var timer = setInterval(function () {
				
				if(self.currentLand <= 9){
					self.showMessage('Game Was Ended!')
                    self.engine.init = true;
                    self.engine.cashout();
					clearInterval(timer);
				}

				else {
					if(moved === self.dice)
					{
						clearInterval(timer);
						self.landEvent()
						self.started = false;
						self.engine.land = self.currentLand;
						self.engine.lost = self.lost;
						self.engine.clicked();

						self.objects['player'].animate((item, delta) => {
							//Remove player from the land
							self.objects['land' + self.currentLand].object.removeChild(item);
						
							//Add player to the land
							self.objects['land' + self.currentLand].object.addChild(item);

							self.objects['player'].stop()
						})
					}
					else 
					{
						//Remove player from the land
						self.objects['land' + move].object.removeChild(self.objects['player'].object);
					
						//Add player to the land
						self.objects['land' + move].object.addChild(self.objects['player'].object);
					}
				}
				
				move--
				moved++
			}, 500);
			
			
			//Reset the position caused by ladder
			self.objects['player'].object.y = 0
			self.objects['player'].object.x = 0
			
			self.objects['score'] = new Text('1.01x', {color: 'yellow', size: 15, y: -10});
			self.objects['player'].object.addChild(self.objects['score'].object)
			
			let count = 5;
			self.objects[ 'score' ].animate((item, delta) => {
				count -= 0.1
				item.zIndex = 999999
				item.alpha = count;
				item.y -= 0.5 * delta;
				
				if(item.y < -50){
					self.objects['player'].object.removeChild(item);
					self.objects['score'].stop();
				}
			})
			
		})
	}
	
	self.landEvent = function(){
		
		// Gems
		if(self.currentLand === 64 || self.currentLand === 31 || self.currentLand === 12 || self.currentLand === 16){
			let moved = false;
			self.objects[ 'gem' + self.currentLand ].animate((item, delta) => {
				item.rotation = 0;
				
				if(!moved){
					item.scale.x += .1 * delta
					item.scale.y += .1 * delta
					
					if(item.scale.x > 1.5){
						self.objects[ 'score'] = new Text('1.50x', {size: 25})
						item.addChild(self.objects[ 'score'].object);
						wait(2200).then(() => {
							if(!__.isUndefined(self.objects[ 'gem' + self.currentLand ] && !__.isUndefined(self.objects[ 'score' ]))){
								self.objects[ 'gem' + self.currentLand ].object.removeChild(self.objects[ 'score'].object);
								self.container.removeChild(self.objects[ 'gem' + self.currentLand ].object);
							}
						})
						moved = true;
					}
				}
				else {
					item.scale.x -= 0.01 * delta
					item.scale.y -= 0.01 * delta
				}
			})
		}
		
		// Ladder
		if(self.currentLand === 87)
		{
			self.currentLand = 67
			let moved = false;
			self.objects[ 'player' ].animate((item, delta) => {
				if(!moved){
					item.x -= 8.2 * delta
					item.y -= 5.2 * delta
					
					if(item.y < -140){
						item.y = -140
						moved = true;
						self.objects[ 'player' ].stop();
					}
				}
			})
		}
		
		// Ladder
		if(self.currentLand === 63)
		{
			self.currentLand = 47
			let moved = false;
			self.objects[ 'player' ].animate((item, delta) => {
				if(!moved){
					item.x += 8.3 * delta
					item.y -= 3.6 * delta
					if(item.x > 320){
						moved = true;
						self.objects[ 'player' ].stop();
					}
				}
			})
		}
		
		// Ladder
		if(self.currentLand === 35)
		{
			self.currentLand = 15
			let moved = false;
			self.objects[ 'player' ].animate((item, delta) => {
				if(!moved){
					item.x -= 3.9 * delta
					item.y -= 2.6 * delta
					if(item.x < -200){
						moved = true;
						self.objects[ 'player' ].stop();
					}
				}
			})
		}
		
		// Trap
		if(self.currentLand === 80 || self.currentLand == 22 || self.currentLand === 11 || self.currentLand === 45){
			let moved = false;
			self.objects[ 'player' ].animate((item, delta) => {
				if(!moved){
					item.scale.x -= .1 * delta
					item.scale.y -= .1 * delta
					if(!__.isUndefined(self.objects[ 'trap' + self.currentLand ])){
						self.objects[ 'trap' + self.currentLand ].object.scale.x += 0.1 * delta
						self.objects[ 'trap' + self.currentLand ].object.scale.y += 0.1 * delta
					}
					if(item.scale.x < 0){
						moved = true;
						self.container.removeChild(item)
						wait(1000).then(() => {
							self.objects[ 'player' ].stop();
							if(!__.isUndefined(self.objects[ 'trap' + self.currentLand ])){
								self.objects[ 'trap' + self.currentLand ].object.scale.x = 1
								self.objects[ 'trap' + self.currentLand ].object.scale.y = 1
							}
							self.lost = true;
						})
					}
				}
			})
		}
		
		//Snake
		if(self.currentLand === 66 || self.currentLand === 62 || self.currentLand === 44 || self.currentLand === 28 || self.currentLand === 26 ){
			let moved = false;
			self.objects[ 'player' ].animate((item, delta) => {
				if(!moved){
					item.scale.x -= .1 * delta
					item.scale.y -= .1 * delta
					if(item.scale.x < 0){
						self.container.removeChild(item)
						self.objects[ 'player' ].stop();
						moved = true;
						self.restart();
					}
				}
			})
		}
	}
	
}

export default Game;