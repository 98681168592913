import MyEmitter from "events";
import Swal from "sweetalert2";
import storage from "../../../Storage";
import socket from "../../../Socket";
import {__, encode, decode, forceSatoshiFormat} from "../../../Helper";
import C from "../../../Constant";

/**
 * Game Engine
 */
function Engine(){

    let self = this;

    /**
     * Socket Connection
     */
    self.ws = socket;

    /**
     * Game Name
     */
    self.game = 'hilo';

    /**
     * Event Trigger
     * @type {module:events.internal}
     */
    self.trigger = new MyEmitter();

    /**
     * Intialize Game
     */
    self.init = false;

    /**
     * Game Status
     * 1 type: started
     */
    self.gameStatus = null;

    /**
     * Round Amount
     */
    self.amount = null;

    /**
     * Player Coin
     */
    self.coin = null;

    /*
     * Game Cards
    */
    self.cards = [];

    /*
     * Game selected high or low
    */
    self.selected = null;

    /**
     * Player profit
     */
    self.profit = 0.00000000;

    /**
     * Round Winner
     */
    self.roundWin = 0;

    /*
     * Start Engine
    */
    self.started = true;

    if(self.started)
    {
        self.ws.on(C.PLAY_HILO, (data) => {
            data = decode(data);

            switch(data.command)
            {
                case 'play':
                    return self.trigger.emit(C.PLAY_HILO, data);
                case 'busted':
                    return self.trigger.emit(C.BUSTED_HILO, data);
                case 'error':
                    return self.trigger.emit(C.ERROR_HILO, data);
            }
        });
    }
}

Engine.prototype.off = function (){
    let self = this;
    self.ws.off(C.PLAY_HILO);
    self.cards = [];
    self.selected = null;
}

Engine.prototype.on = function (name, callback){
    let self = this;
    if(!self.started) return;
    if(self.trigger === null) return;
        return self.trigger.on(name, (data) => callback(data));
}

Engine.prototype.emit = function (name, data = []){
    let self = this;
    if(!self.started) return;
    if(self.trigger === null) return;
        return self.trigger.emit(name, data);
}

/**
 * Add Player for playing game
 */
Engine.prototype.start = function () {
    let self = this;

    if(!self.init)
        return;

    self.gameStatus = 'started';
    
    if(self.amount < 0.00000010){
        self.amount = forceSatoshiFormat(0.00000010)
    }

    // if(parseFloat(storage.getKey('credit')) === parseFloat(self.amount)){
    //     return Swal.fire({
    //                 title: 'Are you sure to play with all your credits ?',
    //                 text: "",
    //                 icon: 'warning',
    //                 showCancelButton: true,
    //                 confirmButtonColor: '#3085d6',
    //                 cancelButtonColor: '#d33',
    //                 confirmButtonText: 'Yes!'
    //             }).then((result) => {
    //             if (result.value) {
    //                 self.ws.emit(C.PLAY_HILO, encode({
    //                     command: 'play',
    //                     amount: self.amount,
    //                     coin: self.coin
    //                 }));
    //             }
    //             else {
    //                 self.trigger.emit('stop_playing');
    //             }
    //     });
    // }

    self.ws.emit(C.PLAY_HILO, encode({
        command: 'play',
        amount: self.amount,
        coin: self.coin
    }));
};

/**
 * Cashout Player
 */
Engine.prototype.cashOut = function () {
    let self = this;

    if(!self.init) return;

    self.gameStatus = null;

	// Calc Profit
    let profit = __.toNumber(self.amount) * __.toNumber(self.roundWin) / 3;

    self.ws.emit(C.PLAY_HILO, encode({
        command: 'cashout',
        lost: false,
        profit: profit,
        amount: self.amount,
        coin: self.coin,
        cards: self.cards,
        selected: self.selected
    }));

    self.roundWin = 0;
    self.cards = [];
};

/**
 * Player has Lost the round
 */
Engine.prototype.lost = function () {
    let self = this;

    self.gameStatus = null;
    self.roundWin = 0;
    
    self.trigger.emit('lost');

    //Send Lost To server
    self.ws.emit(C.PLAY_HILO, encode({
        command: 'cashout',
        lost: true,
        profit: 0.00000000,
        amount: self.amount,
        coin: self.coin,
        cards: self.cards,
        selected: self.selected
    }));
    
    self.cards = [];
};

/**
 * Add Cards
 */
Engine.prototype.addCards = function (cards, next) {
    let self = this;
    self.cards.push(cards, next);
};

/**
 * Set Next Card
 */
Engine.prototype.next = function () {
    this.trigger.emit('next');
};

/**
 * Set Higher Card
 */
Engine.prototype.higher = function () {
    this.trigger.emit('higher');
};

/**
 * Set Lower Card
 */
Engine.prototype.lower = function () {
    this.trigger.emit('lower');
};

/**
 * Player has Win the round
 */
Engine.prototype.win = function () {
    let self = this;

    self.roundWin += 1;
    self.trigger.emit('win');
};

/** Start Game Engine **/
export default Engine;